// React
import React, { useEffect, useRef, useState } from 'react';
// External Libraries
import { Button, Flex, Icon, Text, useColorModeValue, Box, Modal, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
import html2canvas from 'html2canvas';
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function CashReceiptModal({ cashReceiptInfo, onClose, sendEmailRequest, storeInfo, nowDetailDate }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const [viewOn, setViewOn] = useState(true);
  const captureRef = useRef();
  useEffect(() => {
    if (!viewOn) {
      html2canvas(captureRef.current, {
        backgroundColor: null,
        x:5,
        y: 0,
        width: 500,
        height: 800,
        windowWidth: 500,
        windowHeight: 800
      }).then(canvas => {
        // console.log(canvas);
        sendEmailRequest(canvas.toDataURL('image/png'));
        setViewOn(true);
      });
    }
  }, [viewOn])
  function sendEmail() {
    setViewOn(false);
  }

  return (
    <div className='modal'>
      <div  >

        <Modal isOpen={true}  >
          <ModalContent
            ref={captureRef} style={{  backgroundColor: '#fff',  position: 'relative', overflow: 'hidden' }}
            // minW='500px' minH='800px'
            w="90vw"
            className='modalTxCashReceiptMainText'
            overflow='hidden'
            // mt='auto'
            // mb='auto'
            // h='100%'
            border='1px solid'
            borderColor='gray'
            borderRadius='0px'
            backgroundColor='white'
            p='2%'
          >
            <Flex position='absolute' top='1%' left='85%'>
              {viewOn ?
                <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='black' as={BsXLg} />
                :
                null
              }
            </Flex>
            <Box width='90%' mx='auto'>
              <Flex
                direction='column'
                align='center'
              >
                <Text
                  className='modalTxCashReceiptHeadText'
                >
                  [마감 정산서]
                </Text>
              </Flex>

              <Flex
                className='modalTxCashReceiptFirstMargin'
                direction='column'
                align='left'
              >
                <Flex>
                  <Text>
                    [영업일자]
                  </Text>
                  &nbsp;
                  {cashReceiptInfo.openDate.map((date, index) => (
                    <Flex key={index}>
                      <Text>
                        {(date).replace('T', ' ').substring(0, 16)}&nbsp;
                      </Text>
                    </Flex>
                  ))}
                </Flex>
                <Flex>
                  <Text>
                    [정 산 원 ]
                  </Text>
                  <Text>
                    &nbsp;{storeInfo.open_manager}
                  </Text>
                </Flex>
                <Flex>
                  <Text>
                    [개점일시]
                  </Text>
                  <Text>
                    &nbsp;{(storeInfo.open_date)}
                  </Text>
                </Flex>
                <Flex>
                  <Text>
                    [마감일시]
                  </Text>
                  &nbsp;
                  {cashReceiptInfo.closeDate.map((date, index) => (
                    <Flex key={index}>
                      <Text>
                        {(date).replace('T', ' ').substring(0, 16)}&nbsp;
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <Box width='90%' mx='auto' borderBottom='0.5px dashed  black' borderColor='rgba(193, 185, 185, 1)' />

              <Flex
                className='modalTxCashReceiptSecondMarginTop'
                direction='column'
                align='center'
              >
                <Text>
                  ** 매출 합계 **
                </Text>
              </Flex>
              <Flex
                direction='column'
              >
                <Flex
                  justifyContent='space-between'
                >
                  <Text>
                    총판매액
                  </Text>
                  <Text
                    fontWeight='700'
                  >
                    {(cashReceiptInfo.totalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>

                <Flex
                  justifyContent='space-between'
                >
                  <Text>
                    총매출액
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>

                <Flex
                  justifyContent='space-between'
                >
                  <Text>
                    총취소액
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.cancelAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>
                <Flex
                  justifyContent='space-between'
                >
                  <Text>
                    할인총액
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.discountAmount).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>
                <Flex
                  justifyContent='space-between'
                  className='modalTxCashReceiptMoreMarginBottom'
                >
                  <Text>
                    실매출액
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>

                <Flex
                  className='modalTxCashReceiptSecondMarginBottom'
                  justifyContent='space-between'
                >
                  <Text>
                    매출합계
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>
              </Flex>
              <Box width='90%' mx='auto' borderBottom='0.5px dashed  black' borderColor='rgba(193, 185, 185, 1)' />
              <Flex
                className='modalTxCashReceiptSecondMarginTop'
                direction='column'
                align='center'
              >
                <Text>
                  ** 결제수단별 매출내역 **
                </Text>
              </Flex>
              <Flex
                direction='column'
              >
                <Flex
                  justifyContent='space-between'
                >
                  <Text>
                    VETA
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>
                <Flex
                  className='modalTxCashReceiptSecondMarginBottom'
                  justifyContent='space-between'
                >
                  <Text>
                    결제 합계
                  </Text>
                  <Text
                    fontWeight='700'>
                    {(cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                  </Text>
                </Flex>
              </Flex>
              <Box width='90%' mx='auto' borderBottom='0.5px dashed  black' borderColor='rgba(193, 185, 185, 1)' />
              <Flex
                className='modalTxCashReceiptMoreMarginTop'
              >
                <Text>
                  [발행일시]
                </Text>
                <Text>
                  &nbsp;{nowDetailDate}
                </Text>

              </Flex>
              <Flex mt="2vh">
                {
                  viewOn ?
                    <Button
                      className='cashreceiptBtn main-btn-color'
                      mx='auto'
                      mt="3px"
                      width='50%'
                      shadow={shadow}
                      border='1px solid rgba(217, 217, 217, 1)'
                      // backgroundColor='rgba(22, 204, 234, 1)'
                      
                      textColor='white'
                      align='center'
                      onClick={() => { sendEmail() }}
                    >
                      < Text>
                        이메일 발송
                      </Text>
                    </Button>
                    :
                    null
                }
              </Flex>
            </Box>
          </ModalContent>
        </Modal>

      </div>
    </div>
  );
}