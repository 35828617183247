// React
import React, { useRef, useState, useEffect } from 'react';

import axios from 'axios';
// External Libraries
import { Flex, FormControl, Text, Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import CircleInput from '../../../components/secondPwd/secondPwd'

// 암호화 js 
import CryptoJS from 'crypto-js';

export default function SecondPwdModal({ titleText, onClose, openStore, managerList, openManager }) {
    // 2차 비밀번호
    const inputRefs = useRef([]);
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);


    function hashVisitorId(visitorId) {
        return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
    }


    const [alertAni, setAlertAni] = useState('');
    useEffect(() => {
        if (inputValues[5] !== '') {
            for (var i = 0; i < 6; i++) {
                if (inputValues[i] === '' || inputValues[i].length !== 1) {
                    setInputValues(['', '', '', '', '', '']);
                    inputRefs.current[0].focus();
                    setAlertAni('alertText');
                    return;
                }
            }
        }
        if (inputValues[0] !== '' && inputValues[1] !== '' &&
            inputValues[2] !== '' && inputValues[3] !== '' &&
            inputValues[4] !== '' && inputValues[5] !== '') {

            var email = '';
            managerList.forEach(e => {
                if (e.name === openManager) {
                    email = e.email
                }
            })
            const check = inputValues[0] + inputValues[1] + inputValues[2] + inputValues[3] + inputValues[4] + inputValues[5]
            axios.post("/api/user/second", {
                password: hashVisitorId(check),
                email: email
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        openStore();
                        onClose();
                    } else {
                        inputRefs.current[0].focus();
                        setAlertAni('alertText');
                    }
                })
                .catch((error) => {
                    inputRefs.current[0].focus();
                    setAlertAni('alertText');
                })
            setInputValues(['', '', '', '', '', '']);
        }
    }, [inputValues])
    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;

        // 입력이 있으면 다음 input으로 포커스 이동
        if (value && index < inputRefs.current.length - 1) {
            if (inputValues[index + 1] !== '') {
                newInputValues[index + 1] = '';
            }
            inputRefs.current[index + 1].focus();
        }
        setInputValues(newInputValues);
    };


    //  backspace 처리
    // 입력 포커스 이동 핸들링
    const handleKeyUp = (index, event) => {
        if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
            var passwordValues = [...inputValues];
            passwordValues[--index] = '';
            setInputValues(passwordValues);
            inputRefs.current[index].focus();
        }
    };
    function clearFirstDigit(index) {
        let passwordValues = [...inputValues];
        passwordValues[index] = '';
        setInputValues(passwordValues);
        inputRefs.current[index].focus();
    }

    return (
        <div className='modal'>
            <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
                <ModalOverlay />
                <ModalContent
                    maxH={{ base: 'min-content' }}
                    maxW={{ base: '75vw', md: '50vw', xl: '30vw' }}
                    justifyContent='center'
                    my='auto'
                    border='1px solid'
                    borderColor='white'
                    borderRadius='10px'
                    backgroundColor='white'
                    backgroundRepeat='no-repeat'
                    bgSize='cover'
                    bgPosition='10%'
                    p={{ base: '5%', md: '3%', xl: '2%' }}>
                    <Flex mb='10%' mt='5%' fontSize='130%' align='center' ml='auto' mr='auto'>
                        <Text fontWeight='700' fontSize={{ base: '20px', md: '40px', xl: '30px' }}>
                            {titleText}
                        </Text>
                    </Flex>
                    <Flex mb='10%' justifyContent='space-between'
                        mx='auto' fontSize={{ base: '12px', md: '24px', xl: '18px' }}>
                        <FormControl>
                            {inputValues.map((value, index) => (
                                <CircleInput
                                    className={`${alertAni}`}
                                    key={index}
                                    type="number"
                                    maxLength="1"
                                    value={value}
                                    hasValue={value !== ''}
                                    onKeyUp={(e) => handleKeyUp(index, e)}
                                    inputMode="numeric"
                                    pattern="\d*"
                                    onClick={() => { clearFirstDigit(index) }}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                />
                            ))}
                        </FormControl>

                    </Flex>
                    <Text
                        color={"gray.400"}
                        textAlign={{
                            base: "center",
                            xl: "center",
                        }}
                        mt='auto'
                        fontWeight='500' ms='4px'>
                        VENETA Reserve, Inc.
                    </Text>
                </ModalContent>
            </Modal>
        </div>
    );
}