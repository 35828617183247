// React
import React, { useState } from 'react';
import axios from 'axios';
// External Libraries
import { Flex, Icon, Text, Box, useColorModeValue, Input, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function PwdUpdateModal({ onClose, userInfo, setUploadUser }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  // input data variables
  const [inputs, setInputs] = useState({
    password: "",
    passwordCheck: "",
    pastPwd: ""
  });
  const { password, passwordCheck, pastPwd } = inputs;



  const regex = /^\d+$/;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }
  const isValidPassword = password.length === 6
  function changePwd(e) {
    e.preventDefault();
    if (!regex.test(password)) {
      alert('비밀번호는 숫자 6자리로 입력해주세요.');
      return;
    }
    if (!isValidPassword || !password || !passwordCheck || password !== passwordCheck || pastPwd === password) {
    } else if (isValidPassword && password && passwordCheck && password === passwordCheck) {
      axios.post('/api/user/updatePwd', {
        pwd: password,
        email: userInfo.email,
        pastPwd: pastPwd
      })
        .then((response) => {
          if (response.data.status === '1') {
            alert("수정이 완료되었습니다.");
            setUploadUser(true);
            onClose();
          } else {
            if (response.data.status === '0') {
              alert(response.data.error);
            }
          }
        })
        .catch((error) => {
          // console.log(error.data)

        })
    }
  }

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalUpdatePwd'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          backgroundColor='rgba(39, 134, 178, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='white'
          p='3%'
        >
          <Flex position='absolute' top='1vh' left='90%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='white' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            // mt='10%'
            align='center'
            direction='column'
          >
            <Text>
              [비밀번호 변경]
            </Text>
          </Flex>
          <Box
            mt='5%'
            align='center'
            direction='column'
          >
            <Flex
              className='modalAddManagerColumnText'
              justifyContent='space-evenly'
              align='center'
            >
              <Text
                className='modalStoreAddManagerTextHeight '
              >
                기존 패스워드
              </Text>
              <Input
                className=' modalStoreAddManagerInput modalStoreAddManagerTextHeight modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='password'
                backgroundColor='rgba(255,255,255,0.9)'
                borderRadius='0px'
                maxLength={6}
                placeholder='기존 비밀번호를 입력하세요.'
                id="pastPwd"
                name="pastPwd"
                value={pastPwd}
                onChange={onChange} />

            </Flex>

            <Flex justifyContent='space-evenly'
              className='modalAddManagerColumnText'>

              <Text
                className='modalStoreAddManagerTextHeight '
              >
                신규 패스워드
              </Text>
              <Input
                className=' modalStoreAddManagerInput modalStoreAddManagerTextHeight modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='password'
                backgroundColor='rgba(255,255,255,0.9)'
                borderRadius='0px'
                maxLength={6}
                placeholder='6자리 숫자를 입력하세요.'
                id="password"
                name="password"
                value={password}
                onChange={onChange} />
            </Flex>
            <Flex justifyContent='space-evenly'
              className='modalAddManagerColumnText'>

              <Text className='modalStoreAddManagerTextHeight '>
                패스워드 확인
              </Text>
              <Input
                className=' modalStoreAddManagerInput modalStoreAddManagerTextHeight modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='password'
                maxLength={6}
                backgroundColor='rgba(255,255,255,0.9)'
                borderRadius='0px'
                placeholder='다시 입력하세요.'
                id="passwordCheck"
                name="passwordCheck"
                value={passwordCheck}
                onChange={onChange} />
            </Flex>
          </Box>

          <Button
            className='modalMainSmallBtn main-btn-color'
            mx='auto'
            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={(e) => { changePwd(e) }}
          >
            < Text
              className='modalMainSmallBtnText'
              mx='auto'
            >
              변경
            </Text>
          </Button>

        </ModalContent>
      </Modal >
    </div >
  );
}