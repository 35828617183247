// React
import React from "react";
// External Libraries
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Text, ButtonGroup, Box, IconButton, useColorModeValue } from "@chakra-ui/react";
// Components

export default function TransactionController({ pageSize, previousPage, nextPage, canPreviousPage, canNextPage, txPage }) {

  // A Variable for CSS Styling
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );

  return (
    <Box
      height='100%'
    >
      <ButtonGroup size='sm' isAttached variant='outline' boxShadow={bgShadow}>
        <IconButton className="PageBtn" aria-label='Go to previous page' icon={<HiChevronLeft />} onClick={() => previousPage(0)} disabled={!canPreviousPage} />
        <Text className="pageInsideFont">
          {txPage}PAGE / {pageSize}
        </Text>
        <IconButton className="PageBtn" aria-label='Go to next page' icon={<HiChevronRight />} onClick={() => nextPage(0)} disabled={!canNextPage} />
      </ButtonGroup>
    </Box>
  );
}