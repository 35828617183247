// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Image, Text, Button, IconButton, useColorModeValue } from "@chakra-ui/react";
// Components

import main_store from "../../../../../assets/img/service/main_store.png";
import home from "../../../../../assets/img/service/home.png";
import ManagerTable from "./table/ManagerTable";
import ManagerAddModal from "./modal/ManagerAddModal";
import ManagerUpdateModal from "./modal/ManagerUpdateModal";
import PwdUpdateModal from "./modal/PwdUpdateModal";
import PwdUpdateOKModal from "./modal/PwdUpdateOKModal";
import ItemAddModal from "./modal/ItemAddModal";
import ItemUpdateModal from "./modal/ItemUpdateModal";
import CategoryAddModal from "./modal/CategoryAddModal";
import ItemCard from "./component/ItemCard";
import CategoryBigUpdateModal from "./modal/CategoryBigUpdateModal";

import { BsArrowCounterclockwise, BsGear } from "react-icons/bs";
import DiscountModal from "./modal/DiscountModal";
import OptionModal from "./modal/OptionModal";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function StoreCard({ userInfo, setNowComponent, managerList, managerInfo, setManagerInfo, setUploadUser, sirenOrder }) {

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    //modal status
    const [managerAddModalOpen, setManagerAddModalOpen] = useState(false);
    const [managerUpdateModalOpen, setManagerUpdateModalOpen] = useState(false);
    const [discountModal, setDiscountModal] = useState(false);
    const [categoryAddModalOpen, setCategoryAddModalOpen] = useState(false);
    const [categoryBigUpdateModalOpen, setCategoryBigUpdateModalOpen] = useState(false);
    const [pwdUpdateModalOpen, setPwdUpdateModalOpen] = useState(false);
    const [pwdUpdateOkModalOpen, setPwdUpdateOkModalOpen] = useState(false);
    const [optionModalOpen, setOptionModalOpen] = useState(false);
    const [itemAddModalOpen, setItemAddModalOpen] = useState(false);
    const [itemUpdateModalOpen, setItemUpdateModalOpen] = useState(false);

    const [component, setComponent] = useState('');

    const [bigCategoryList, setBigCategoryList] = useState([]);
    const [bigCategoryInfo, setBigCategoryInfo] = useState([]);

    const [itemList, setItemList] = useState([]);
    const [itemInfo, setItemInfo] = useState();
    const [uploadItem, setUploadItem] = useState(false);

    const [updateShop, setUpdateShop] = useState(false);
    const [selectList, setSelectList] = useState([]);

    const [discountList, setDiscountList] = useState([]);
    const [discountInfo, setDiscountInfo] = useState();
    const [reloadDiscount, setReloadDiscount] = useState(false);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
    const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);

    useEffect(() => {
        var list = [];
        var totalAmount = 0;
        var totalPercent = 0;
        axios.post('/api/pos/product/discount')
            .then((response) => {
                for (var i = 0; i < response.data.discount.length; i++) {
                    list.push({
                        "discount_number": response.data.discount[i].discount_number,
                        "discount_amount": response.data.discount[i].discount_amount,
                        "discount_percent": response.data.discount[i].discount_percent,
                        "comment": response.data.discount[i].comment
                    })
                    if (response.data.discount[i].discount_amount) {
                        totalAmount += Number(response.data.discount[i].discount_amount);
                    } else if (response.data.discount[i].discount_percent) {
                        totalPercent += Number(response.data.discount[i].discount_percent);
                    }
                }
                setDiscountList(list);
                setTotalDiscountAmount(totalAmount);
                setTotalDiscountPercent(totalPercent);
            })
            .catch((error) => {
                // console.log(error);
            })
        if (reloadDiscount) {
            setReloadDiscount(false);
        }
    }, [reloadDiscount, uploadItem])

    const [optionGroupList, setOptionGroupList] = useState([]);
    const [optionList, setOptionList] = useState([]);

    const [optionUpdate, setOptionUpdate] = useState(false);
    // 옵션관리
    useEffect(() => {
        // console.log("optionUpdate ", optionUpdate );
        var optionGroupList = [];
        var optionList = [];
        var productOption = [];
        axios.post('/api/pos/product/totalOption')
            .then((response) => {
                for (var i = 0; i < response.data.optionGroupList.length; i++) {
                    //console.log(response.data.option[i]);
                    optionGroupList.push({
                        "option_group_id": response.data.optionGroupList[i].option_group_id,
                        "store_number": response.data.optionGroupList[i].store_number,
                        "option_group_name": response.data.optionGroupList[i].option_group_name,
                        "option_group_type": response.data.optionGroupList[i].option_group_type,
                        "option_list": response.data.optionGroupList[i].option_list
                    })
                    //console.log(response.data.optionGroupList[i])
                }
                setOptionGroupList(optionGroupList);

                for (var i = 0; i < response.data.optionList.length; i++) {
                    //console.log(response.data.optionDetail[i]);
                    optionList.push({
                        "option_id": response.data.optionList[i].option_id,
                        "store_number": response.data.optionList[i].store_number,
                        "option_group_id": response.data.optionList[i].option_group_id,
                        "option_name": response.data.optionList[i].option_name,
                        "additional_price": response.data.optionList[i].additional_price
                    })
                }
                setOptionList(optionList);

            })
            .catch((error) => {
                // console.log(error);
            })
        if (optionUpdate) {
            setOptionUpdate(false);
        }
    }, [optionUpdate])

    useEffect(() => {
        var items = [];
        var category = [];
        var bigCategory = [];
        axios.post('/api/pos/product/getTotal')
            .then((response) => {
                for (var i = 0; i < response.data.bigCategory.length; i++) {
                    bigCategory.push({
                        "category_big_id": response.data.bigCategory[i].category_big_id,
                        "category_big_name": response.data.bigCategory[i].category_big_name,
                        "category_primary": response.data.bigCategory[i].category_primary,
                        "image": response.data.bigCategory[i].image,
                    })
                }
                setBigCategoryList(bigCategory);
                for (var l = 0; l < response.data.totalProductInfo.length; l++) {
                    items.push({
                        "category_big_id": response.data.totalProductInfo[l].category_big_id,
                        "category_id": response.data.totalProductInfo[l].category_id,
                        "product_number": response.data.totalProductInfo[l].product_number,
                        "date": response.data.totalProductInfo[l].reg_date,
                        "name": response.data.totalProductInfo[l].product_name,
                        "price": response.data.totalProductInfo[l].price1,
                        "image": response.data.totalProductInfo[l].image,
                        "owner": response.data.totalProductInfo[l].store_number,
                        "comment": response.data.totalProductInfo[l].content,
                        "stock": response.data.totalProductInfo[l].product_stock,
                        "primary": Number(response.data.totalProductInfo[l].primary),
                    })
                }
                setItemList(items);
            })

        if (uploadItem) {
            setUploadItem(false);
        }

    }, [uploadItem, sirenOrder, updateShop])

    const [bigCategoryProductList, setBigCategoryProductList] = useState([]);
    const [bigCategoryOptionGroupList, setBigCategoryOptionGroupList] = useState([]);

    useEffect(() => {

        if (bigCategoryInfo.category_big_id) {
            // console.log("bigCategoryInfo -> " , bigCategoryInfo);
            axios.post('/api/pos/product/groupcat', {
                category_big_id: bigCategoryInfo.category_big_id,
            }).then(response => {
                // setBigCategoryProductList(response.data.getGroupByBigCategoryProductInfo);
                // console.log(response.data.getGroupByBigCategoryOptionGroupInfo)
                setBigCategoryOptionGroupList(response.data.getGroupByBigCategoryOptionGroupInfo);
            }).catch({

            })

        }
    }, [bigCategoryInfo,uploadItem])



    function updateBigCagetoryPrimary() {
        var error = false;

        axios.post('/api/pos/product/bigRank', {
            order: bigCategoryList,
        })
            .then(response => {
                setUpdateShop(false);
            })
            .catch(error => {
                error = true;
            });

        axios.post('/api/pos/product/productPrimary', {
            order: selectList
        })
            .then(response => {
                setUpdateShop(false);
            })
            .catch(error => {
                error = true;
            });
        if (error) {
            alert("다시 시도해주세요.");
        } else {
            alert("수정이 완료되었습니다.");
        }
    }

    return (
        <>
            {discountModal && <DiscountModal setReloadDiscount={setReloadDiscount} onClose={() => setDiscountModal(false)} discountList={discountList} setDiscountInfo={setDiscountInfo} discountInfo={discountInfo} totalDiscountAmount={totalDiscountAmount} totalDiscountPercent={totalDiscountPercent} />}
            {pwdUpdateModalOpen && <PwdUpdateModal userInfo={userInfo} setUploadUser={setUploadUser} onClose={() => setPwdUpdateModalOpen(false)} />}
            {pwdUpdateOkModalOpen && <PwdUpdateOKModal onClose={() => setPwdUpdateOkModalOpen(false)} />}
            {managerAddModalOpen && <ManagerAddModal onClose={() => setManagerAddModalOpen(false)}
                setUploadUser={setUploadUser} />}
            {managerUpdateModalOpen && <ManagerUpdateModal setPwdUpdateModalOpen={setPwdUpdateModalOpen}
                setUploadUser={setUploadUser} managerInfo={managerInfo} onClose={() => setManagerUpdateModalOpen(false)} userInfo={userInfo} />}


            {itemAddModalOpen &&
                <ItemAddModal
                    itemAddModalOpen={itemAddModalOpen}
                    cardRelod={() => setUploadItem(true)}
                    onClose={() => setItemAddModalOpen(false)}
                    bigCategoryList={bigCategoryList}
                    optionGroupList={optionGroupList}
                />
            }


            {itemUpdateModalOpen
                &&
                <ItemUpdateModal
                    onClose={() => setItemUpdateModalOpen(false)}
                    bigCategoryList={bigCategoryList}
                    userInfo={userInfo}
                    itemInfo={itemInfo}
                    setUploadItem={setUploadItem}
                    totalDiscountAmount={totalDiscountAmount}
                    totalDiscountPercent={totalDiscountPercent}
                    bigCategoryOptionGroupList={bigCategoryOptionGroupList}
                    optionGroupList={optionGroupList}
                    cardRelod={() => setUploadItem(true)}
                />}



            {categoryAddModalOpen && <CategoryAddModal setUploadItem={setUploadItem} bigCategoryList={bigCategoryList} onClose={() => { setCategoryAddModalOpen(false) }} />}
            {categoryBigUpdateModalOpen && <CategoryBigUpdateModal setUploadItem={setUploadItem} bigCategoryInfo={bigCategoryInfo} onClose={() => { setCategoryBigUpdateModalOpen(false) }} />}


            {optionModalOpen &&
                <OptionModal
                    optionGroupList={optionGroupList} setOptionGroupList={setOptionGroupList}
                    optionList={optionList} setOptionList={setOptionList}

                    optionUpdate={optionUpdate} setOptionUpdate={setOptionUpdate}
                    onClose={() => setOptionModalOpen(false)}
                />
            }

            <Flex
                className="txHomeImageAndText">
                <Image src={main_store} className="mainmenuImage" />
                {
                    component === '' ?
                        <Button
                            className="mainmenuText storeText main-text-color"
                            borderRadius='0'
                            backgroundColor='rgba(70, 209, 232,0)'
                            // textColor='rgba(2, 58, 142, 1)'
                            px='4px'
                            ml='12px'
                            _hover={{
                                textColor: 'rgba(2, 58, 142, 1)',
                                backgroundColor: 'rgba(70, 209, 232,0)'
                            }}
                            _active={{
                                textColor: 'rgba(2, 58, 142, 1)',
                                backgroundColor: 'rgba(70, 209, 232,0)'
                            }}
                        >
                            직원관리
                        </Button>
                        :
                        <Button
                            className="mainmenuText storeText"
                            borderRadius='0'
                            backgroundColor='rgba(70, 209, 232,0)'
                            textColor='white'
                            px='4px'
                            ml='12px'
                            _focus={{ textColor: 'rgba(2, 58, 142, 1)', backgroundColor: 'rgba(70, 209, 232,0)' }}
                            _hover={{ textColor: 'rgba(2, 58, 142, 1)', backgroundColor: 'rgba(70, 209, 232,0)' }}
                            _active={{ textColor: 'rgba(2, 58, 142, 1)', backgroundColor: 'rgba(70, 209, 232,0)' }}
                            onClick={() => { setComponent('') }}
                        >
                            직원관리
                        </Button>

                }

                {
                    component === 'friend' ?
                        <Button
                            className="mainmenuText storeText main-text-color"
                            borderRadius='0'
                            backgroundColor='rgba(70, 209, 232,0)'
                            // textColor='rgba(2, 58, 142, 1)'
                            px='4px'
                            ml='12px'
                            _hover={{ textColor: 'rgba(2, 58, 142, 1)', backgroundColor: 'rgba(70, 209, 232,0)' }}
                            _active={{ textColor: 'rgba(2, 58, 142, 1)', backgroundColor: 'rgba(70, 209, 232,0)' }}
                        >
                            상품관리
                        </Button>
                        :
                        <Button
                            className="mainmenuText storeText"
                            borderRadius='0'
                            backgroundColor='rgba(70, 209, 232,0)'
                            textColor='white'
                            px='4px'
                            ml='12px'
                            _hover={{ textColor: 'white', backgroundColor: 'rgba(70, 209, 232,0)' }}
                            onClick={() => { setComponent('friend') }}
                        >
                            상품관리
                        </Button>
                }
                <Image src={home} className="mainmenuHome" ml='auto' onClick={() => { setNowComponent('') }} />
            </Flex>

            <Box
                borderRadius='20px 20px 0px 0px'
                borderTop='1px solid white'
                zIndex='2'
                className="sub-background-color menuBox"
                
                height='90vh'
                width='100vw'
                // backgroundColor='rgba(100,200,220,1)'

            >
                {
                    component === '' ?
                        <>
                            <Flex
                                className="itemBoxComponent"
                                justify='right'
                            >
                                <Button
                                    className="storeAddDetailBtn main-btn-color"
                                    shadow={shadow}
                                    // backgroundColor='#007799'
                                    _hover={{ backgroundColor: '#00688B' }}
                                    _focus={{ backgroundColor: '#00688B' }}
                                    _active={{ backgroundColor: '#00688B' }}
                                    textColor='white'
                                    align='center'
                                    mr='10vh'
                                    onClick={() => { setManagerAddModalOpen(true) }}
                                >

                                    <Text
                                        align='center'
                                        mx='auto'
                                    >
                                        직원추가
                                    </Text>
                                </Button>
                            </Flex>
                            <Box
                                mx='auto'
                                className="storeTableBox"
                                backgroundColor='white'
                            >
                                <ManagerTable setManagerUpdateModalOpen={setManagerUpdateModalOpen} managerList={managerList} userInfo={userInfo} setManagerInfo={setManagerInfo} />
                            </Box>
                            <Box
                                className="saleTableBottomBox table-backgroud-header-footer"
                                mx='auto'
                                // backgroundColor='#007799'
                            >
                                <Flex
                                    className="saleTableBottomText"
                                    justifyContent='space-around'
                                    textColor='white'
                                    align='center'
                                >
                                    <Text>
                                        총계
                                    </Text>
                                    <Text
                                        fontWeight='700'
                                    >
                                        {(managerList.length)}
                                    </Text>
                                </Flex>
                            </Box>
                        </>
                        :
                        <>

                            <Flex
                                justify='right'
                                className="itemBoxComponent"
                            >
                                {
                                    updateShop ?
                                        <>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                // backgroundColor='rgba(0, 131, 153, 1)'
                                                textColor='white'
                                                align='center'
                                                mr='5vh'
                                                onClick={() => { updateBigCagetoryPrimary() }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                >
                                                    저장
                                                </Text>
                                            </Button>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                // backgroundColor='rgba(0, 131, 153, 1)'
                                                textColor='white'
                                                align='center'
                                                mr='5vh'
                                                onClick={() => { setUpdateShop(false) }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                >
                                                    취소
                                                </Text>
                                            </Button>
                                        </>

                                        :
                                        <>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                // backgroundColor='#007799'
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                textColor='white'
                                                align='center'
                                                mr='5vw'
                                                onClick={() => { setCategoryAddModalOpen(true) }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                    className="tableBodyBigText"
                                                >
                                                    카테고리
                                                </Text>
                                            </Button>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                // backgroundColor='#007799'
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                textColor='white'
                                                align='center'
                                                mr='5vw'
                                                onClick={() => { setDiscountModal(true) }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                     className="tableBodyBigText"
                                                >
                                                    할인정보
                                                </Text>
                                            </Button>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                // backgroundColor='#007799'
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                textColor='white'
                                                align='center'
                                                mr='5vw'
                                                onClick={() => { setOptionModalOpen(true) }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                    className="tableBodyBigText"
                                                >
                                                    옵션관리
                                                </Text>
                                            </Button>
                                            <Button
                                                className="storeAddDetailBtn main-btn-color"
                                                shadow={shadow}
                                                _hover={{ backgroundColor: '#00688B' }}
                                                _focus={{ backgroundColor: '#00688B' }}
                                                _active={{ backgroundColor: '#00688B' }}
                                                // backgroundColor='#007799'
                                                textColor='white'
                                                align='center'
                                                mr='5vw'
                                                onClick={() => { setItemAddModalOpen(true) }}
                                            >

                                                <Text
                                                    align='center'
                                                    mx='auto'
                                                     className="tableBodyBigText"
                                                >
                                                    상품등록
                                                </Text>
                                            </Button>
                                            <IconButton
                                                className="storeRollBtn sub-background-color"
                                                // shadow={shadow}
                                                _hover={{ backgroundColor: '#00688B', color: 'white' }}
                                                _focus={{ backgroundColor: '#00688B', color: 'white' }}
                                                _active={{ backgroundColor: '#00688B', color: 'white' }}
                                                // backgroundColor='rgba(0,0,0,0)'
                                                textColor='#007799'
                                                align='center'
                                                // mr='auto'
                                                mr='5%'
                                                as={BsArrowCounterclockwise}
                                                onClick={() => {
                                                    setUploadItem(true);
                                                }}
                                            />

                                            <IconButton
                                                className="storeRollBtn sub-background-color"
                                                // shadow={shadow}
                                                _hover={{ backgroundColor: '#00688B', color: 'white' }}
                                                _focus={{ backgroundColor: '#00688B', color: 'white' }}
                                                _active={{ backgroundColor: '#00688B', color: 'white' }}
                                                // backgroundColor='rgba(0,0,0,0)'
                                                textColor='#007799'
                                                align='center'
                                                // mr='auto'
                                                mr='5%'
                                                as={BsGear}
                                                onClick={() => {
                                                    setUpdateShop(true);
                                                }}
                                            />
                                        </>
                                }
                            </Flex>
                            <Box
                                mx='auto'
                                className="storeItemBox"
                                
                            >
                                <ItemCard
                                    setItemList={setItemList}
                                    setItemUpdateModalOpen={setItemUpdateModalOpen}
                                    setCategoryBigUpdateModalOpen={setCategoryBigUpdateModalOpen}
                                    setItemInfo={setItemInfo}
                                    itemList={itemList}
                                    bigCategoryList={bigCategoryList}
                                    bigCategoryInfo={bigCategoryInfo}
                                    setBigCategoryInfo={setBigCategoryInfo}
                                    uploadItem={uploadItem}
                                    setBigCategoryList={setBigCategoryList}
                                    updateShop={updateShop}
                                    selectList={selectList}
                                    setSelectList={setSelectList}
                                    totalDiscountAmount={totalDiscountAmount}
                                    totalDiscountPercent={totalDiscountPercent}
                                    bigCategoryProductList={bigCategoryProductList}
                                    bigCategoryOptionGroupList={bigCategoryOptionGroupList}
                                    
                                />
                            </Box>
                        </>
                }
            </Box >


        </>

    );
}
