import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { render, Printer, Text, Br, Line, Row, Cut,Image } from 'react-thermal-printer';
import QRCode from 'qrcode';

import './css/App.css';

import LoginPage from './views/login';
import ServiceView from './views/service';
import SignUp from './views/signUp';
import Open from './views/open';
import BackOffice from './views/backOffice';
import LodingFirst from './views/LodingFirst';
import FindPwd from './views/findPwd';
import veta from './assets/img/service/veta_banana.png';


function App() {
  const [viewComponent, setViewComponent] = useState('');
  const [token, setToken] = useState(localStorage.getItem('jwtToken'));
  const [sirenOrder, setSirenOrder] = useState();
  const [sirenPopUp, setSirenPopUp] = useState(false);
  const [sseConnect, setSseConnect] = useState(false);
  const [port, setPort] = useState(null);

  // TEST
  //const [sampleReceipt, setSampleReceipt] = useState(null);

  useEffect(() => {
    if (typeof Notification !== 'undefined') {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js');
    }
  }, []);

  useEffect(() => {
    const fetchSerialInfo = async () => {
      try {
        const storedPortInfo = localStorage.getItem("selectedPortInfo");

        if (storedPortInfo) {
          const portInfo = JSON.parse(storedPortInfo);
          await connectToPort('COM2', portInfo.usbVendorId, portInfo.usbProductId);
        } else {
          const response = await axios.get("/api/auth/getSerialInfo", {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
            }
          });

          if (response.data && response.data.usbVendorId && response.data.usbProductId) {
            localStorage.setItem('selectedPortInfo', JSON.stringify(response.data));
            await connectToPort('COM2', response.data.usbVendorId, response.data.usbProductId);
          } else {
            promptUserForPort();
          }
        }
      } catch (error) {
        console.error("Failed to fetch serial info:", error);
      }
    };

    if (viewComponent === 'Service') {
      fetchSerialInfo();
    }
  }, [viewComponent]);

  const promptUserForPort = async () => {
    if ("serial" in navigator) {
      try {
        const selectedPort = await navigator.serial.requestPort();

        await selectedPort.open({ baudRate: 9600 });

        const portInfo = selectedPort.getInfo();
        const selectedPortInfo = {
          usbVendorId: portInfo.usbVendorId,
          usbProductId: portInfo.usbProductId
        };

        localStorage.setItem('selectedPortInfo', JSON.stringify(selectedPortInfo));
        setPort(selectedPort);

        await selectedPort.close();

        await axios.post("/api/auth/isSerial", selectedPortInfo, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
          },
        });

        await connectToPort('COM2', selectedPortInfo.usbVendorId, selectedPortInfo.usbProductId);
      } catch (error) {
        console.log("promptUserForPort log");
        console.error("Error selecting port:", error);
        //promptUserForPort();
      }
    } else {
      console.log("Web Serial API not supported in this browser.");
    }
  };

  const connectToPort = async (portName, usbVendorId, usbProductId) => {
    if ("serial" in navigator) {
      try {

        console.log("portName -> " , portName , " usbVendorId -> " , usbVendorId, " usbProductId -> " , usbProductId);

        const ports = await navigator.serial.getPorts();

        console.log("ports -> ",ports);
        const targetPort = ports.find(p => {
          const info = p.getInfo();
          console.log("targetPort info -> ", info);
         
          return info.usbVendorId === usbVendorId && info.usbProductId === usbProductId;
        });

        console.log("targetPort -> ",targetPort)
        if (!targetPort) {
          console.error(`Port ${portName} not found.`);

          return null;
        }

        setPort(targetPort);
        return targetPort;
      } catch (error) {
        console.error(`Error connecting to port ${portName}: `, error);
        promptUserForPort();
        return null;
      }
    } else {
      console.log("Web Serial API not supported in this browser.");
      return null;
    }
  };
  const [renderedReceipt, setRenderedReceipt] = useState(null); // New state for storing rendered receipt

  const handlePrintOrder = async (message) => {

    const receiptOrderNumber = message.order_number;
    // debugger;
    const receipt = (
      <Printer type="epson" width={42} characterSet="korea">
        <Text size={{ width: 2, height: 2 }} align="center">{receiptOrderNumber.slice(-4)}</Text>
        <Br />
        <Row left="주문자" right={message.sender} />
        <Line />
        <Row left="주문시간" right={new Date().toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' })} />
        <Line />
        <Row left="상품명" right="총 금액 [수량]" />
        <Line />
  
        {message.productDataList.map((item) => {
          // console.log("item -> ", item);
  
          // 필수 옵션 (HOT/ICE) 찾기
          const requiredOption = item.options
            .filter(option => option.option_group_type === "required")
            .map(option => option.option_name)
            .find(option => option === "HOT" || option === "ICE") || '';
  
           
          // 선택 옵션을 모두 나열
          // const choicedOptions = item.options
          //   .filter(option => option.option_group_type === "choiced")
          //   .map(option => option.option_name)
          //   .join(", ");
            const choicedOptions = item.options
    .filter(option => option.option_group_type === "choiced")
    .map(option => `${option.option_name} [${option.additional_price}]`)
    .join(", ");

          // 모든 옵션의 추가 금액 합산
      const totalAdditionalPrice = item.options
      .reduce((sum, option) => sum + parseInt(option.additional_price, 10), 0);

    // 총 금액 계산 (기본 가격 - 700 + 옵션 추가 금액) * 수량
    const totalAmount = ((parseInt(item.price, 10) - 700 + totalAdditionalPrice) * parseInt(item.product_stock)).toString();

          // const totalAmount = ((parseInt(item.price,10) - 700) * parseInt(item.product_stock)).toString();
          console.log(totalAmount)
          return (
            <>
              <Row
                key={item.product_number}
                left={requiredOption ? `[${requiredOption}] ${item.product_name}` : `${item.product_name}`}
                right={`${totalAmount} [${item.product_stock}]`}
              />
              {choicedOptions && (
                <Text> ㄴ {choicedOptions}</Text>
              )}
            </>
          );
        })}
        <Br />
        <Cut />
      </Printer>
    );
    
   
    try {
      setRenderedReceipt(receipt);
      const data = await render(receipt);
      let connectedPortNew = port;

      if (!port) {
        const storedPortInfo = localStorage.getItem('selectedPortInfo');

        if (storedPortInfo) {
          const portInfo = JSON.parse(storedPortInfo);
          const usbVendorId = portInfo.usbVendorId;
          const usbProductId = portInfo.usbProductId;
          const connectedPort = await connectToPort('COM2', usbVendorId, usbProductId);
          if (!connectedPort) {
            console.error("Failed to connect to port.");
            return;
          }
          connectedPortNew = connectedPort;
          setPort(connectedPort);
        } else {
          console.error("No port selected. Please connect the printer first.");
          return;
        }
      }



      if (connectedPortNew) {
        if (!connectedPortNew.readable || !connectedPortNew.writable) {
          await connectedPortNew.open({ baudRate: 9600 });
        } else {
          console.log("port is open");
        }

        const writer = connectedPortNew.writable.getWriter();
        await writer.write(data);
        writer.releaseLock();// 해제 

        // 잠시 대기?? 
        await new Promise(resolve => setTimeout(resolve, 500));

        await connectedPortNew.close();
      }

    } catch (error) {
      console.error("Error during printing:", error);
    } finally {

      if (port) {
        try {
          await port.close();
        } catch (closeError) {
          console.error("Failed to close port:", closeError);
        }
      }

      const axiosTransactionHash = message.transaction_hash;
      const axiosOrderNumber = message.order_number;
      const axiosOrderStatus = message.order_status;

      axios.post("/api/pos/order/ongoing", {
        transaction_hash: axiosTransactionHash,
        order_number: axiosOrderNumber,
        order_status: axiosOrderStatus
      })
        .then((response) => {
          console.log("success Ongoing");

        })
        .catch((error) => {
          console.error("/api/pos/order/ongoing Error -> ", error)

        })

    } // try catch End
  };


  useEffect(() => {
    const text = window.location.href;
    const url = text.includes('300') ? text.replace('300', '705') + "api/pos/order/reg/" + token : text + "api/pos/order/reg/" + token;
    const eventSource = new EventSource(url);

    eventSource.addEventListener('order', async event => {
      // console.log("event.data -> " , event.data);
      const message = JSON.parse(event.data);
      // console.log("message -> " , message);
      await handlePrintOrder(message);
      setSirenOrder(message);
      setSirenPopUp(true);

      const body = '주문이 왔습니다.';
      const notifTitle = 'eternals pos';
      const options = {
        body,
        badge: veta,
        vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
        icon: veta,
      };

      if (Notification.permission === 'granted') {
        navigator.serviceWorker.getRegistration().then(reg => {
          if (reg) {
            reg.showNotification(notifTitle, options);
          }
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            navigator.serviceWorker.getRegistration().then(reg => {
              if (reg) {
                reg.showNotification(notifTitle, options);
              }
            });
          }
        });
      }
    });

    eventSource.addEventListener('connect', event => {
      let message = event.data;
      console.log("Welcome! " + message);
    });

    if (eventSource.readyState === EventSource.CLOSED) {
      setTimeout(() => {
        eventSource = new EventSource(url);
      }, 3000);
    }

    return () => {
      eventSource.close();
    };
  }, [sseConnect]);

  window.history.pushState(null, null, null);


  const backgroundColor = "#E8EDED";
/*
          {renderedReceipt && 
        <div className='sample-receipt'>
          <h3>Receipt Preview</h3>
          {renderedReceipt}
        </div>
      }
*/
  return (
    <>

      {viewComponent === 'Login' ? (
        <LoginPage
          setViewComponent={setViewComponent}
          token={token}
          setToken={setToken}
          setSseConnect={setSseConnect}
        />
      ) : viewComponent === 'Setting' ? (
        <SignUp
          setViewComponent={setViewComponent}
          token={token}
          setToken={setToken}
          sirenOrder={sirenOrder}
        />
      ) : viewComponent === 'Open' ? (
        <Open
          setViewComponent={setViewComponent}
          token={token}
          setToken={setToken}
          promptUserForPort={promptUserForPort} />
      ) : viewComponent === 'Service' ? (
        <ServiceView
          setViewComponent={setViewComponent}
          viewComponent={viewComponent}
          token={token}
          setToken={setToken}
          sirenOrder={sirenOrder}
          sirenPopUp={sirenPopUp}
          setSirenPopUp={setSirenPopUp}
        connectPrinter={promptUserForPort}
        />
      ) : viewComponent === 'Find' ? (
        <FindPwd setViewComponent={setViewComponent}
          viewComponent={viewComponent} />
      ) : viewComponent === 'BackOffice' ? (
        <BackOffice setViewComponent={setViewComponent} />
      ) : (
        <LodingFirst
          setViewComponent={setViewComponent}
          viewComponent={viewComponent}
        />
      )}
    </>
  );
}

export default App;
