// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function CalculationModal({ onClose, totalAmount, settleAmount, formattedMonth, submitLoading, settlement }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalCalculation sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='3%'
        >

          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalSaleDetailMainText'
            mb='3%'
            align='center'
            direction='column'
          >
            <Text>
              [정산 요청]
            </Text>
          </Flex>


          <Flex
            className='modalCalculationDefaultText'
            justifyContent='space-around'
            height='40%'
          >

            <Flex
              direction='column'
              width='90%'
              // align='start'
              fontWeight='700'
            >
              <Flex justify='space-between' align='baseline'>
                <Text>
                  정산연월
                </Text>

                <Text className = 'main-text-color'>
                  {formattedMonth}
                </Text>
              </Flex>
              <Flex justify='space-between' align='baseline'>
                <Text>
                  가능 금액
                </Text>
                <Flex>

                <Text className = 'main-text-color' fontWeight='1000'>
                  {(totalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}&nbsp;
                </Text>
                <Text className = 'main-text-color'>
                  VETA
                </Text>
                </Flex>
              </Flex>
              <Flex justify='space-between' align='baseline'>
                <Text>
                  정산 요청 금액
                </Text>
                <Flex>

                <Text className = 'main-text-color' fontWeight='1000'>
                  {(settleAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                </Text>

                <Text className = 'main-text-color'>
                  &nbsp;VETA
                </Text>
                </Flex>
              </Flex>
              <Flex justify='space-between' align='baseline'>
                <Text>
                  정산일
                </Text>
                <Text className = 'main-text-color' >
                  5 영업일 이내 처리
                </Text>
              </Flex>
            </Flex>
          </Flex>

          {
            submitLoading ?
              <Button
                mt='5%'
                className='modalCalculationBtn main-btn-color'
                _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                mx='auto'
                shadow={shadow}
                // backgroundColor='rgba(22, 204, 234, 1)'
                border='1px solid rgba(217, 217, 217, 1)'
                textColor='white'
                align='center'
                onClick={(e) => { settlement(e) }}
              >
                < Text
                  className='modalMainSmallBtnText'
                  mx='auto'
                >
                  정산 요청
                </Text>
              </Button>
              :
              <Button
                mt='5%'
                className='modalCalculationBtn main-btn-color'
                _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                mx='auto'
                shadow={shadow}
                // backgroundColor='rgba(22, 204, 234, 1)'
                border='1px solid rgba(217, 217, 217, 1)'
                textColor='white'
                align='center'
                isLoading loadingText='진행중..' />
          }
        </ModalContent>
      </Modal>
    </div>
  );
}