// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../../../css/Page.css';
import { BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function MainLogoutModal({ logout, onClose }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='modalMainClose sub-modal-background-color'
          border='1px solid'
          borderColor='white'
          borderRadius='0px'
          // backgroundColor='rgba(192, 223, 244, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='
rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            my='auto'
            align='center'
            direction='column'
          >
            <Text
              className='modalMainCloseText'
            >
              종료 하시겠습니까?
            </Text>
          </Flex>

          <Button
            mx='auto'
            mb='2.5vh'
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
           className='modalMainSmallBtn main-btn-color'
            textColor='white'
            align='center'
            onClick={() => { logout() }}
          >
            <Text
              mx='auto'
             className='modalMainSmallBtnText'
            >
              종료
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}