// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../../../../css/Page.css';
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function SirenOrderModal({ onClose, nowDetailDate, storeInfo, sirenOrder, setNowComponent, setUploadOrder, orderOngoing }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );



  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='sirenOrderModal sub-modal-background-color'
          border='1px solid'
          borderColor='white'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalMainCloseText'
            width='100%'
            mb='3%'
            align='center'
            direction='column'
          >
            <Text
            >
              [ 메뉴 주문 ]
            </Text>
          </Flex>

          <Flex
            mt='5%'
            className='modalMainInsideDefaultText'
            justifyContent='space-evenly'
          >
            <Flex
              direction='column'
              width='80%'
            >
              <Flex justifyContent='space-between'>
                <Flex align='center'>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text>
                    고객:
                  </Text>
                </Flex>
                <Text
                  fontWeight='700'
                  textColor='rgba(2, 58, 142, 1)'
                >
                  {sirenOrder.sender ? sirenOrder.sender : ".."}
                </Text>
              </Flex>
              <Flex justifyContent='space-between'>
                <Flex align='center'>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text
                  >
                    형태:
                  </Text>
                </Flex>
                <Text
                  fontWeight='700'
                  textColor='rgba(2, 58, 142, 1)'
                >
                  {sirenOrder.option_takeout === "0" ? "매장" : "포장"}
                </Text>
              </Flex>
              <Flex justifyContent='space-between'>
                <Flex align='center'>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text
                  >
                    요청:
                  </Text>
                </Flex>
                <Text
                  fontWeight='700'
                  textColor='rgba(2, 58, 142, 1)'
                >
                  {sirenOrder.order_comment ? sirenOrder.order_comment : "잘 부탁드립니다."}
                </Text>
              </Flex>
              <Flex justifyContent='space-between'>
                <Flex align='center'>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text>
                    메뉴:
                  </Text>
                </Flex>
                <Flex
                  direction='column'
                >
                  {
                    sirenOrder.orderList ?
                      sirenOrder.orderList.map((order, index) => (
                        <Flex
                          justify='space-between'
                          fontWeight='700'
                          textColor='rgba(2, 58, 142, 1)'
                        >
                          <Text
                            key={index}
                          >
                            {order.product_name}
                          </Text>
                          <Text
                            key={index}
                          >
                            {order.product_quantity}
                          </Text>
                        </Flex>
                      ))
                      :
                      "주문 탭에서 확인하세요."
                  }
                </Flex>
              </Flex>
            </Flex>


          </Flex>

          <Flex
            mt='auto'
            mb='3vh'
            justify='space-around'
          >
            <Button
              className='modalMainSmallBtn main-btn-color'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { onClose() }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                닫기
              </Text>
            </Button>
            <Button
              className='modalMainSmallBtn main-btn-color'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => {
                setUploadOrder(true);
                orderOngoing(sirenOrder);
                setNowComponent('sale');
                onClose();
              }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                확인
              </Text>
            </Button>

          </Flex>

        </ModalContent>
      </Modal>
    </div>
  );
}