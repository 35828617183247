// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, ModalOverlay, ModalContent, Image, Button, Input } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
import gradient2 from "../../../../../../assets/img/service/gradient2.png";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function CategoryBigUpdateModal({ onClose, bigCategoryInfo, setUploadItem }) {
  // Variables for Card CSS Styling
  const [change, setChange] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [preview, setPreview] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [productImage, setProductImage] = useState();
  const [btnText, setBtnText] = useState("확인");
  const formData = new FormData();
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  // input data variables
  const [inputs, setInputs] = useState({
    categoryChangeName: ""
  });
  const { categoryChangeName } = inputs;
  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }
  // 이미지
  const onChangeImage = (fileBlob) => {
    setProductImage(fileBlob);
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileBlob);
      reader.onload = () => {
        setPreview(reader.result);
        setProductImage(fileBlob); // 파일 읽기가 완료된 후에 상태를 업데이트합니다.
        resolve(); // Promise를 resolve하여 비동기 작업 완료를 알립니다.
      };
    });
  }
  const imageOnOff = () => {
    if (changeImage) {
      setChangeImage(false);
    } else {
      setChangeImage(true);
    }
  }
  function checkProfile() {
    if (!productImage) {
      setBtnText("이미지를 확인하세요.");
      return;
    }
    // 파일 유형 체크
    if (productImage && productImage.type && !productImage.type.match('image/png', 'image/jpg', 'image/jpeg')) {
      setBtnText("이미지를 확인하세요.");
      return;
    }
    // 파일 크기 체크 (10MB)
    if (productImage.size > 10 * 1024 * 1024) {
      setBtnText("10MB 이하만 가능합니다.");
      return;
    }
    setBtnText("완료");
    setImageCheck(true);
  }
  function updateBtn() {
    if (change) {
      if (window.confirm("수정하시겠습니까?")) {
        formData.append('category_big_id', bigCategoryInfo.category_big_id);

        if (categoryChangeName) {
          formData.append('category_big_update_name', categoryChangeName);
        }
        if (productImage) {
          formData.append('category_image', productImage);
        }

        axios.post('/api/pos/product/updBigCategory', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })
          .then((response) => {             // api call success
            if (response.data.status === '1') {
              alert("수정이 완료되었습니다.");
              setUploadItem(true);
              onClose();
            } else {
              if (response.data.status === '0') {
                alert(response.data.error);
              }
            }
          }).catch(error => {
            // console.log(error);
            // alert(error);
          })
      }
    } else {
      imageOnOff();
      setChange(true);
    }
  }
  function deleteBtn() {
    if (change) {
      setProductImage();
      setPreview('');
      setChange(false);
      setChangeImage(false);
      setImageCheck(false);
    } else {
      if (window.confirm("삭제하시겠습니까?")) {
        axios.post('/api/pos/product/delBigCategory', {
          category_big_id: bigCategoryInfo.category_big_id
        })
          .then((response) => {             // api call success
            if (response.data.status === '1') {
              alert("삭제가 완료되었습니다.");
              setUploadItem(true);
              onClose();
            } else {
              if (response.data.status === '0') {
                alert(response.data.error);
              }
            }
          }).catch(error => {
            // console.log(error);
            // alert(error);
          })

      }

    }
  }
  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalUpdateManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
        // p='2%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>
          <Flex
            className='modalUpdateManagerTitleText'
            mb={{ base: '5%', lg: '2%' }}
            align='center'
            direction='column'
          >
            <Text>
              [ 카테고리 ]
            </Text>
          </Flex>

          <Flex
            className='modalAddManagerColumnText'
            justifyContent='space-between'
          >
            <Text
              className='modalStoreAddManagerTextHeight '
            >
              이름
            </Text>
            {
              !change ?
                <>
                  <Text
                    color='rgba(2, 58, 142, 1)'
                    className='modalStoreAddManagerTextHeight '>
                    {(bigCategoryInfo.category_big_name)}
                  </Text>
                </>
                :
                <>
                  <Input
                    className='modalStoreAddManagerTextHeight modalCalculationMiddlePlusMarginBottom modalStoreAddManagerInput modalInputPlaceholder'
                    isRequired={true}
                    variant='auth'
                    type='text'
                    autoComplete="off"
                    backgroundColor='rgba(255,255,255,0.9)'
                    borderRadius='0px'
                    placeholder={bigCategoryInfo.category_big_name}
                    id="categoryChangeName"
                    name="categoryChangeName"
                    value={categoryChangeName}
                    onChange={onChange} />
                </>
            }
          </Flex>
          <Flex
            className='modalAddManagerColumnText'
            justifyContent='space-between'
          >
            <Text
              className='modalStoreAddManagerTextHeight '
            >
              이미지
            </Text>
            {
              changeImage ?
                <Flex align='center' justify='space-evenly' width='50%'>
                  {preview &&
                    <Image
                      src={preview} alt="preview-img" width='100px' height='100px' />
                  }
                  <Flex direction='column'>
                    {
                      imageCheck ?
                        null
                        :
                        <Input
                          width='80%'
                          fontSize='9px'
                          type='file'
                          mr='5px'
                          fontWeight='500'
                          autoComplete='off'
                          id="productImage"
                          name="productImage"
                          accept="image/*"
                          onChange={(e) => {
                            onChangeImage(e.target.files[0]);
                          }}>
                        </Input>
                    }
                    {
                      imageCheck ?
                        <Button
                          className="main-btn-color"
                          textColor='white'
                          disabled={true}
                        >
                          완료
                        </Button>
                        :
                        <Button
   
                          className="main-btn-color"
                          fontSize='12px'
                          isTruncated
                          textColor='white'
                          onClick={() => {
                            checkProfile()
                          }}>
                          {btnText}
                        </Button>
                    }
                  </Flex>
                </Flex>
                :
                <Image src={`data:image/*;base64,${bigCategoryInfo.image}`} alt='이미지가 없습니다.' w='100px' h='100px' mr='10%' />
            }
          </Flex>


          <Flex justify='space-around' mb='10px'>
            <Button
              className='modalMainSmallBtn'
              // position='absolute'
              _focus={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              _active={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              _hover={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              // top='90%'
              // left='10%'
              shadow={shadow}
              backgroundColor='rgba(251, 176, 65, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { deleteBtn(true) }}
            >
              < Text
                mx='auto'
                className='modalMainSmallBtnText'
              >
                {
                  change ?
                    "취소"
                    :
                    "삭제"
                }
              </Text>
            </Button>
            <Button
              className='modalMainSmallBtn main-btn-color'
              // position='absolute'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              // top='90%'
              // left='55%'
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { updateBtn() }}
            >
              < Text
                mx='auto'
                className='modalMainSmallBtnText'
              >
                {
                  change ?
                    "저장"
                    :
                    "수정"
                }
              </Text>
            </Button>
          </Flex>

        </ModalContent>
      </Modal>
    </div>
  );
}