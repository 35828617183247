// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Button, Flex, Input, Text, Icon, useColorModeValue, Modal, ModalContent
  , RadioGroup, Radio
} from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";

// Components
// Assets
import '../../../../../../css/Switch.css';
import Card from "../../../../../../components/card/Card";

/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function OptionCreateModal({
  title, optionGroupTypeProps,
  optionUpdate, setOptionUpdate, onClose
}) {

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  // create option group


  const [optionGroupInputs, setOptionGroupInputs] = useState({
    optionGroupName: "",
    optionGroupType: ""
  });


  const { optionGroupName, optionGroupType } = optionGroupInputs;

  function onChangeForOptionGroup(e) {
    setaddOptionStatusComment("");
    // focus input value
    const { value, name } = e.target;
    // inputs value change
    setOptionGroupInputs({
      ...optionGroupInputs,
      [name]: value,
    });
  } // fn onChange end

  const [addOptionStatusComment, setaddOptionStatusComment] = useState("");
  const [selectedOption, setSelectedOption] = useState('required');
  function createOptionGroup() {

    if (optionGroupName) {
      axios.post('/api/pos/product/createOptionGroup', {
        optionGroupName: optionGroupName,
        optionGroupType: selectedOption
      })
        .then((response) => {
          if (response.data.status == 1) {
            alert("신규 옵션그룹 [" + optionGroupName + "]을 추가 했습니다.");
            onClose();
            setOptionUpdate(!optionUpdate);
          } else {
            setaddOptionStatusComment(response.data.status)
          }
        })
        .catch((error) => {

        })
    } else {
      alert("옵션의 이름을 입력하세요.");
    }
  }

  // create option 
  const [optionInputs, setOptionInputs] = useState({
    optionName: "",
    optionAmount: ""
  });

  const { optionName, optionAmount } = optionInputs;

  function onChangeForOption(e) {
    setaddOptionStatusComment("");

    const { value, name } = e.target;

    let newValue = value;

    // option_amount 필드일 경우 처리
    if (name === "optionAmount") {
      // 1. 숫자에서 콤마를 제거
      newValue = newValue.replace(/,/g, '');

      // 2. 숫자 앞의 0 제거
      newValue = newValue.replace(/^0+/, '');

      // 3. 세 자리마다 콤마 추가
      newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // inputs value 변경
    setOptionInputs({
      ...optionInputs,
      [name]: newValue,
    });
  }

  function createOption() {

    if (optionName) {
      axios.post('/api/pos/product/createOption', {
        optionName: optionName,
        optionAmount: optionAmount.replaceAll(",","") ||"0",
        optionGroupId:optionGroupTypeProps.option_group_id
      })
        .then((response) => {
          if (response.data.status == 1) {
            alert("신규 옵션 [" + optionName + "]을 추가 했습니다.");
            onClose();
            setOptionUpdate(!optionUpdate);
          } else {
            setaddOptionStatusComment(response.data.status)
          }
        })
        .catch((error) => {

        })
    } else {
      alert("옵션의 이름을 입력하세요.");
    }


  }


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalUpdateManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
        // p='2%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>
          <Flex
            className='modalUpdateManagerTitleText'
            align='center'
            direction='column'
          >
            <Text>
              [ {title} ]
            </Text>
          </Flex>
          {
            optionGroupTypeProps === "group"
              ?
              <>
                <Flex
                  mt='10%'
                  mx='auto'
                  width='90%'
                  direction='column'
                  // backgroundColor='#E8EDED'
                  height='100%'
                >


                  <Text>
                    사용자에게 표시될 옵션 그룹의 이름을 작성해주세요.
                  </Text>
                  <Input
                    className="loginText loginStoreIdInput"
                    isRequired={true}
                    variant='auth'
                    type='text'
                    backgroundColor='rgba(255,255,255,0.9)'
                    width='100%'
                    borderRadius='0'
                    placeholder='HOT/ICE or 선택 옵션'
                    fontWeight='400'
                    height='50px !important'
                    id="optionGroupName"
                    name="optionGroupName"
                    value={optionGroupName}
                    onChange={onChangeForOptionGroup}

                  />
                  <Text>
                    생성할 옵션그룹의 종류을 선택해주세요.
                  </Text>
                  <Flex direction="column">
                    <RadioGroup onChange={setSelectedOption} value={selectedOption} >
                      <Flex>
                        <Card shadow="md" mr="2%">
                          <Flex direction="column">
                            <Radio value="required">
                              <Text>필수 옵션</Text>
                              <Text>( 필수로 하나는 선택해야하는 옵션 )</Text>
                            </Radio>
                          </Flex>
                        </Card>
                        <Card shadow="md" ml="2%">
                          <Flex direction="column">
                            <Radio value="choiced">
                              <Text>선택 옵션</Text>
                              <Text>( 미 선택 과 다중 선택 가능한 옵션 )</Text>
                            </Radio>
                          </Flex>
                        </Card>
                      </Flex>
                    </RadioGroup>
                  </Flex>
                </Flex>
                <Flex mt='24px' direction='column'>
                  <Flex>
                    <Text m='auto' color='red'>{addOptionStatusComment}</Text>
                  </Flex>
                  <Button width='50%' mx='auto'
                  //  backgroundColor='#9933FF'
                    className="main-btn-color"
                    color='white' onClick={() => createOptionGroup()} m='1% auto 1% auto '>
                    <Text>
                      추가하기
                    </Text>

                  </Button>
                </Flex>
              </>

              :
              <>
                <Flex className="modalUpdateManagerTitleText" align='center' direction='column'>
                  <Text fontWeight='900' fontSize='xlg'>
                    {optionGroupTypeProps.option_group_name}
                  </Text>
                </Flex>
                <Flex
                  mt='10%'
                  mx='auto'
                  width='90%'
                  direction='column'
                  // backgroundColor='#E8EDED'
                  height='100%'
                >
                  <Text>
                    사용자에게 표시될 옵션의 이름을 작성해주세요.
                  </Text>
                  <Input
                    className="loginText loginStoreIdInput"
                    isRequired={true}
                    variant='auth'
                    type='text'
                    backgroundColor='rgba(255,255,255,0.9)'
                    width='100%'
                    borderRadius='0'
                    placeholder='HOT'
                    fontWeight='400'
                    height='50px !important'
                    id="optionName"
                    name="optionName"
                    value={optionName}
                    onChange={onChangeForOption}

                  />
                </Flex>
                <Flex
                  mt='1%'
                  mx='auto'
                  width='90%'
                  direction='column'
                  // backgroundColor='#E8EDED'
                  height='100%'
                >
                  <Text>
                    옵션의 금액을 선택해 주세요.
                  </Text>
                  <Input
                    className="loginText loginStoreIdInput"
                    isRequired={true}
                    variant='auth'
                    type='text'
                    backgroundColor='rgba(255,255,255,0.9)'
                    width='100%'
                    borderRadius='0'
                    placeholder='0원 이면 작성 안하셔도 됩니다.'
                    fontWeight='400'
                    height='50px !important'
                    id="optionAmount"
                    name="optionAmount"
                    value={optionAmount}
                    onChange={onChangeForOption}

                  />
                </Flex>
                <Flex mt='24px' direction='column'>
                  <Flex>
                    <Text m='auto' color='red'>{addOptionStatusComment}</Text>
                  </Flex>
                  <Button width='50%' mx='auto' 
                  // backgroundColor='#9933FF' 
                  className="main-btn-color"
                  
                  color='white' onClick={() => createOption()} m='1% auto 1% auto '>
                    <Text>
                      추가하기
                    </Text>

                  </Button>
                </Flex>
              </>
          }
        </ModalContent>
      </Modal >
    </div >
  );
}
