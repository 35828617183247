// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Flex,  Text, Icon, useColorModeValue,  Modal, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
// Components
// Assets
import '../../../../../../css/Switch.css';
import Card from "../../../../../../components/card/Card";
import OptionCreateModal from "./OptionCreateModal";
import OptionDetailModal from "./OptionDetailModal";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function OptionModal({ 
  optionGroupList, setOptionGroupList, 
  optionList, setOptionList, 

  optionUpdate, setOptionUpdate, 
  onClose
}) {

  const bgHover1 = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus1 = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const bgHover2 = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.40" }
  );
  const bgFocus2 = useColorModeValue(
    { bg: "secondaryGray.200" },
    { bg: "whiteAlpha.100" }
  );
  const bgHover3 = useColorModeValue(
    { bg: "secondaryGray.200" },
    { bg: "whiteAlpha.30" }
  );
  const bgFocus3 = useColorModeValue(
    { bg: "secondaryGray.100" },
    { bg: "whiteAlpha.50" }
  );

  const bgHover4 = useColorModeValue(
    { bg: "secondaryGray.500" },
    { bg: "whiteAlpha.60" }
  );
  const bgFocus4 = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.30" }
  );

  const [optionCreateModal, setOptionCreateModal] = useState(false);
  const [optionDetailModal, setOptionDetailModal] = useState(false);
  const [optionInfo, setOptionInfo] = useState();
  const formData = new FormData();
  const [inputs, setInputs] = useState({
    item_name: "",
    price: "",
    item_information: "",
    item_image: "",
    stock: "",
    category: "",
    big_category: ""
  });
  const { item_name, price, item_information, item_image, stock, category, big_category } = inputs;
  function onChange(e) {
    // focus input value
    const { value, name } = e.target;
    // inputs value change
    setInputs({
      ...inputs,
      [name]: value,
    });
  } // fn onChange end


  function deleteOptionGroup(e) {

    /* eslint-disable no-restricted-globals */
    if (confirm("포함된 하위 옵션 들도 삭제 됩니다. \n 삭제하시겠습니까?")) {
      /* eslint-enable no-restricted-globals */


      axios.post('/api/pos/product/deleteOptionGroup', {
        optionGroupId: e
      })
        .then((response) => {

          if (response.data.status == 1) {
            setOptionUpdate(!optionUpdate)
            //alert("신규 옵션그룹 [" +option_name + "]을 추가 했습니다.")
          } else {

            alert(response.data)
          }
        })
        .catch((error) => {

        })
    } else {
      alert("삭제할 옵션그룹을 선택해 주세요 ");
    }


  }

  function deleteOption(e) {

    /* eslint-disable no-restricted-globals */
    if (confirm("삭제하시겠습니까?")) {
      /* eslint-enable no-restricted-globals */
      axios.post('/api/pos/product/deleteOption', {
        optionId: e
      })
        .then((response) => {
 
          if (response.data.status == 1) {
            setOptionUpdate(!optionUpdate)
            //alert("신규 옵션그룹 [" +option_name + "]을 추가 했습니다.")
          } else {

            alert(response.data)
          }
        })
        .catch((error) => {

        })
    } else {
      alert("삭제할 옵션그룹을 선택해 주세요 ");
    }


  }




  function openCreateModal(e) {

    if (e === "group") {
      setTitle("옵션 그룹 생성");
      setOptionGroupType("group");
    } else {
      setTitle("옵션 생성");
      setOptionGroupType(e)
    }

    setOptionCreateModal(true);
  }

  const [title, setTitle] = useState("");
  const [optionGroupType, setOptionGroupType] = useState("");
  return (
    <>
      {optionCreateModal &&
        <OptionCreateModal
          title={title}
          optionGroupTypeProps={optionGroupType}
          optionUpdate={optionUpdate}
          setOptionUpdate={setOptionUpdate}
          onClose={() => setOptionCreateModal(false)}
        />}


      {optionDetailModal &&
       <OptionDetailModal 
       onClose={() => setOptionDetailModal(false)} 
       optionInfo={optionInfo} 
       openCreateModal={openCreateModal}
       bgHover3={bgHover3} bgFocus3={bgFocus3} 
       />
       }
     
      <div className='modal'>
        <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true} >
          <ModalContent
            className='modalUpdateManager'
            my='auto'
            border='1px solid'
            borderColor='black'
            borderRadius='0px'
            backgroundColor='#E8EDED'
            textColor='black'

          // p='2%'
          >
            <Flex >
              <Icon _hover={bgHover1} _focus={bgFocus1} _active={bgFocus1} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
            </Flex>
            <Flex
              className='modalUpdateManagerTitleText'
              align='center'
              direction='column'
            >
              <Text>
                [ 옵션 관리 ]
              </Text>
              <Button ml='auto' mr='20px' mb='10px' width='100px' className="main-btn-color " color='white' onClick={() => { openCreateModal("group") }}>
                그룹 생성
              </Button>
            </Flex>

            <Flex
              width='90%'
              mx='auto'
              direction='column'
              // height='100%'
              maxH='50vh'
              overflowY='auto'
            >


              {
                optionGroupList &&
                optionGroupList.map((list, index) => (
                  <Card mb='20px'
                  _hover={bgHover2} _focus={bgFocus2} 
                  onClick={(e) => {
                    if (!e.target.closest("[data-add-option]") && !e.target.closest("#deleteOptionGroupId")) {
                      setOptionInfo(list);
                      setOptionDetailModal(true);
                    }
                  }}
                  >
                    <Flex >
                      <Icon
                        _hover={bgHover1}
                        _focus={bgFocus1}
                        _active={bgFocus1}
                        id="deleteOptionGroupId"
                        onClick={() => deleteOptionGroup(list.option_group_id)}
                        color='rgba(130, 126, 126, 1)'
                        clas
                        as={MdDelete}
                        style={
                          {
                            position: "relative",
                            top: "1vh",
                            marginLeft: "auto",
                            width: "5%",
                            height: "5%"
                          }

                        }

                      />
                    </Flex>
                    <Flex
                      direction='column'
                      key={index}
                    >
                      <Flex m="1%">
                        <Text>옵션 그룹 이름 : </Text>
                        <Text fontWeight="1000" ml="2%">
                          {list.option_group_name}
                        </Text>
                      </Flex >
                      <Flex m="1%">
                        <Text>옵션 그룹 종류 : </Text>
                        <Text fontWeight="1000" ml="2%">
                          {list.option_group_type === 'required' ? "필수 선택" : "선택 옵션"}
                        </Text>
                      </Flex>

                      <Flex justify="space-between" overflowX="auto" maxW="100%">
                        <Flex
                          direction="row"
                          w="100%"
                        flexWrap="nowrap"
                        >
                          {list.option_list &&
                            list.option_list.map((listOptions,indexOption ) => (
                                <Card key={indexOption} shadow="md" m="2%" p="10px" borderRadius="12px"
                                minW="20%" data-add-option="true"
                                >
                                  <Flex direction="row" align="center" justify="space-between" h="100%">
                                    <Flex direction="column">
                                      <Text>{listOptions.option_name}</Text>
                                      {listOptions.additional_price ? (
                                        <Text align="center">+{listOptions.additional_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</Text>
                                      ) : null}
                                    </Flex>
                                    <Icon as={TiDelete} w="20%" h="100%" className="mainBtnColor"
                                     _active={bgFocus4}
                                    data-add-option="true"
                                    color="#FF8339"
                                    onClick={() => deleteOption(listOptions.option_id)}
                                    />
                                  </Flex>
                                </Card>
                              ))
                          }
                          <Card shadow="md" m="2%" p="10px" borderRadius="12px"
                          _hover={bgHover3} _focus={bgFocus3} _active={bgFocus3}
                          minW="20%"
                          onClick={(e) => {
                            openCreateModal(list);
                          }}
                          data-add-option="true"
                          >
                            <Flex
                              justify="center"
                              align="center"
                              h="100%"
                              // id="addOption"  
                            >
                              <Text>+ 추가하기</Text>
                            </Flex>
                          </Card>
                        </Flex>
                      </Flex>

                    </Flex>
                  </Card>
                ))
              }

            </Flex>

          </ModalContent>
        </Modal >
      </div >
    </>
  );
}
