// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function TransactionDetailModal({ transactionInfo, onClose, txCancelRequest, orderDetail, orderOngoing,
  orderComplete, orderClose
}) {


  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='txDetailModal sub-modal-background-color'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='3%'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='
rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalSaleDetailMainText'
            align='center'
            direction='column'
          >
            <Text>
              [주문 내역]
            </Text>
          </Flex>

          <Flex
            className='modalMainInsideDefaultText'
            direction='column'
            mt='10%'
            height='70%'
          >
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text
                align='start'
                fontWeight='700'
              >
                주문번호
              </Text>
              <Text >
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.order_number}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                결제 일시
              </Text>
              <Text align='start'>
                :
              </Text>
              <Text
                ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.order_reg? (transactionInfo.order_reg).toString().substring(0, 16) : ""}
              </Text>

            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text
                align='start'
                fontWeight='700'
              >
                결 제 자
              </Text>
              <Text >
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.sender}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                주문 상태
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {
                  transactionInfo.order_status === 'order' ?
                    "주문 접수"
                    :
                    transactionInfo.order_status === 'ongoing' ?
                      "상품 제작중"
                      :
                      transactionInfo.order_status === 'complete' ?
                        "픽업"
                        :
                        transactionInfo.order_status === 'reject' ?
                          "주문 취소"
                          :
                          "완료"
                }

              </Text>
            </Flex>
            <Flex>
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                주문 내역
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                width='60%'
                textColor='rgba(2, 58, 142, 1)'>
                {
                  orderDetail.map((order, index) => (
                    <Flex
                      justify='space-between'
                      fontWeight='700'
                      textColor='rgba(2, 58, 142, 1)'
                    >
                      <Text
                        key={index}
                      >
                        {order.product_name}&nbsp;
                      </Text>
                      <Text
                        key={index}
                      >
                        {order.product_quantity}
                      </Text>
                    </Flex>
                  ))
                }
              </Text>
            </Flex>
          </Flex>

          <Flex>
            {
              transactionInfo.order_status === 'order' ?

                <>
                  <Button
                    className='modalMainSmallBtn'
                    mx='auto'
                    my='2.5vh'
                    shadow={shadow}
                    backgroundColor='rgba(34, 149, 22, 1)'
                    border='1px solid rgba(217, 217, 217, 1)'
                    textColor='white'
                    align='center'
                    onClick={() => { orderOngoing(transactionInfo) }}
                  >
                    <Text
                      className='modalMainSmallBtnText'
                      mx='auto'
                    >
                      수락
                    </Text>
                  </Button>
                  <Button
                    className='modalMainSmallBtn'
                    mx='auto'
                    my='2.5vh'
                    shadow={shadow}
                    backgroundColor='rgba(234, 149, 22, 1)'
                    border='1px solid rgba(217, 217, 217, 1)'
                    textColor='white'
                    align='center'
                    onClick={() => { txCancelRequest() }}
                  >
                    <Text
                      className='modalMainSmallBtnText'
                      mx='auto'
                    >
                      취소
                    </Text>
                  </Button>
                </>
                :
                transactionInfo.order_status === 'ongoing' ?
                  <>
                    <Button
                      className='modalMainSmallBtn'
                      mx='auto'
                      my='2.5vh'
                      shadow={shadow}
                      backgroundColor='rgba(34, 149, 22, 1)'
                      border='1px solid rgba(217, 217, 217, 1)'
                      textColor='white'
                      align='center'
                      onClick={() => { orderComplete(transactionInfo) }}
                    >
                      <Text
                        className='modalMainSmallBtnText'
                        mx='auto'
                      >
                        완료
                      </Text>
                    </Button>
                    <Button
                      className='modalMainSmallBtn'
                      mx='auto'
                      my='2.5vh'
                      shadow={shadow}
                      backgroundColor='rgba(234, 149, 22, 1)'
                      border='1px solid rgba(217, 217, 217, 1)'
                      textColor='white'
                      align='center'
                      onClick={() => { txCancelRequest() }}
                    >
                      <Text
                        className='modalMainSmallBtnText'
                        mx='auto'
                      >
                        취소
                      </Text>
                    </Button>
                  </>
                  :
                  transactionInfo.order_status === 'complete' ?
                    <>
                      <Button
                        className='modalMainSmallBtn'
                        mx='auto'
                        my='2.5vh'
                        shadow={shadow}
                        backgroundColor='rgba(34, 149, 22, 1)'
                        border='1px solid rgba(217, 217, 217, 1)'
                        textColor='white'
                        align='center'
                        onClick={() => {
                          orderClose(transactionInfo);
                          onClose();
                        }}
                      >
                        <Text
                          className='modalMainSmallBtnText'
                          mx='auto'
                        >
                          픽업 완료
                        </Text>
                      </Button>

                    </>
                    :
                    transactionInfo.order_status === 'reject' ?
                      <>
                        <Button
                          className='modalMainSmallBtn'
                          mx='auto'
                          my='2.5vh'
                          shadow={shadow}
                          backgroundColor='rgba(34, 149, 22, 1)'
                          border='1px solid rgba(217, 217, 217, 1)'
                          textColor='white'
                          align='center'
                          onClick={() => {
                            orderClose(transactionInfo);
                            onClose();
                          }}
                        >
                          <Text
                            className='modalMainSmallBtnText'
                            mx='auto'
                          >
                            닫기
                          </Text>
                        </Button>

                      </>
                      :
                      null
            }


          </Flex>

        </ModalContent>
      </Modal>
    </div>
  );
}