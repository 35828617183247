// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue,  Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function SendMailModal({  onClose, userInfo }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalTxSendMail sub-head-btn-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(39, 134, 178, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='gray'
          p='3%'
        >
          <Flex position='absolute' top='1vh' left='80%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='black' as={BsXLg} />
          </Flex>

          <Flex
            className='modalTxSendMailMainText'
            mt='10%'
            align='center'
            direction='column'
          >
            <Flex direction='column'>

              <Text
                // color='rgba(183, 242, 252, 1)'
              >
                
                {userInfo.email}
              </Text>
              
              <Text>이메일로</Text>

            <Text>
              마감 정산서가 발송되었습니다.
            </Text>
            </Flex>
          </Flex>



          <Button
            className='modalMainSmallBtn main-btn-color'
            mt='auto'
            mx='auto'
            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={() => { onClose() }}
          >
            < Text
              className='modalMainSmallBtnText'
              mx='auto'
            >
              확인
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}