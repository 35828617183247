// React
import React, { useState } from "react";
// External Libraries
import axios from 'axios';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Input, Text, useColorModeValue, Flex, FormControl, Button, Box, InputGroup, InputRightElement, Icon } from "@chakra-ui/react";
import Underbar from "../../components/underbar/Underbar";


//sbchoi
// 암호화 js 
import CryptoJS from 'crypto-js';


/**
 * 
 * Props list - none
 */
export default function SignUp({ setViewComponent, token, setToken }) {
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [showA, setShowA] = React.useState(false);
  const [showB, setShowB] = React.useState(false);

  const handleAClick = () => setShowA(!showA);
  const handleBClick = () => setShowB(!showB);

  const textColorSecondary = "gray.400";
  // Get initial values from localStorage
  const store_number = localStorage.getItem("store_number") || "";
  const store_name = localStorage.getItem("store_name") || "";

  const [alertText, setAlertText] = useState('');
  // input data variables
  const [inputs, setInputs] = useState({
    storeName: store_name,
    storeNumber: store_number,
    storePwd: "",
    storeSecondPwd: "",
    presidentName: "",
    presidentEmail: localStorage.getItem("store_number") + "-",
    presidentNumber: "",
    presidentBirth: ""
  });
  const { storeName, storeNumber, storePwd, storeSecondPwd, presidentName, presidentNumber, presidentBirth, presidentEmail } = inputs;




  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;

    if (name === "presidentNumber") {
      const onlyNumbers = value.replace(/[^0-9]/g, '');
      const emailPrefix = localStorage.getItem("store_number") + "-";
      const phoneSuffix = onlyNumbers.slice(3);
      setInputs((prevInputs) => ({
        ...prevInputs,
        presidentNumber: onlyNumbers,
        presidentEmail: emailPrefix + phoneSuffix
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value
      }));
    }
  }




  //SBCHOI ADD
  // 외부 JS 
  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
  }




  // async api call
  async function handleSubmit(e) {

    e.preventDefault();
    if (storeNumber && storePwd && storeSecondPwd && presidentName && presidentNumber && presidentEmail && presidentBirth && storeName) {
      // server api call
      axios.post('/api/store/reg', {
        store_name: storeName,
        store_number: storeNumber,
        store_pwd: hashVisitorId(storePwd),
        store_second_pwd: storeSecondPwd,
        email: presidentEmail,
        name: presidentName,
        phone_number: presidentNumber,
        birth_day: presidentBirth
      },
      ).then((response) => {                       // api return
        if (response.data.error) {
          setAlertText("올바른 정보를 입력하세요.");
          alert("올바른 번호를 입력하세요." + response.data.error);
        } else {


          setViewComponent(response.data.page);
        }
      })
        .catch(error => {
          setAlertText("올바른 정보를 입력하세요.");
          alert("올바른 번호를 입력하세요." + error);                      // api call failed
        })
    } else {
      setAlertText("올바른 정보를 입력하세요.");
    }
  }// fn handleSubmit end
  function logout() {
    setToken(null);
    localStorage.removeItem('jwtToken');
    setViewComponent('');
  }

  return (
    <Flex
      // position='relative'
      className="screen-box"
      h="100vh"
      overflowY='auto'
      w='100vw'
      backgroundColor='rgba(183, 242, 252, 1)'
    >
      <Flex
        className="signUp"
        justifyContent='start'
        direction='column'
      >
        <Box
          className="settingBox">
          <Box
            className="settingStoreBox"
            mx='auto'
          >
            <Text
              className="settingBoxText"
            >
              매장 정보를 입력해주세요.
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Text
                  className="settingInputMainText"
                >
                  가맹점 번호
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  autoComplete="off"
                  variant='auth'
                  type='text'
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='가맹점 번호'
                  fontWeight='400'
                  id="storeNumber"
                  name="storeNumber"
                  value={storeNumber}
                  disabled
                // onChange={onChange} 
                />
                <Text
                  className="settingInputMainText"
                >
                  가맹점 이름
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  variant='auth'
                  autoComplete="off"
                  type='text'
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='가맹점 이름'
                  fontWeight='400'
                  id="storeName"
                  name="storeName"
                  value={storeName}
                  disabled
                // onChange={onChange} 
                />
                <Text
                  className="settingInputMainText"
                >
                  가맹점 비밀번호 (사장님비밀번호)
                </Text>
                <InputGroup>
                  <Input
                    className="settingInputText settingInput"
                    isRequired={true}
                    variant='auth'
                    autoComplete="off"
                    type={showA ? "text" : "password"}
                    backgroundColor='rgba(255,255,255,0.9)'
                    width='100%'
                    borderRadius='5'
                    maxLength='6'
                    placeholder='가맹점 비밀번호'
                    fontWeight='400'
                    id="storePwd"
                    name="storePwd"
                    value={storePwd}
                    onChange={onChange} />
                  <InputRightElement display='flex' className="loginPwdInputEye" alignItems='center'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={showA ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleAClick} />
                  </InputRightElement>
                </InputGroup>
                <Text
                  className="settingInputMainText"
                >
                  가맹점 2차 비밀번호
                </Text>
                <InputGroup>
                  <Input
                    className="settingInputText settingInput"
                    isRequired={true}
                    variant='auth'
                    autoComplete="off"
                    type={showB ? "text" : "password"}
                    backgroundColor='rgba(255,255,255,0.9)'
                    width='100%'
                    borderRadius='5'
                    maxLength='6'
                    placeholder='가맹점 2차 비밀번호'
                    fontWeight='400'
                    id="storeSecondPwd"
                    name="storeSecondPwd"
                    value={storeSecondPwd}
                    onChange={onChange} />
                  <InputRightElement display='flex' className="loginPwdInputEye" alignItems='center'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={showB ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleBClick} />
                  </InputRightElement>
                </InputGroup>
                <Text
                  className="alertText"
                  ms='4px'
                  fontWeight='700'
                  color='red.500'>
                  {alertText}
                </Text>
              </FormControl>
            </form>
          </Box>

          <Box
            className="settingPresidentBox"
            mx='auto'
          >
            <Text
              className="settingBoxText"
            >
              사장님 로그인 정보를 입력해주세요.
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Text
                  className="settingInputMainText"
                >
                  이름
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  variant='auth'
                  autoComplete="off"
                  type='text'
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='이름'
                  fontWeight='400'
                  id="presidentName"
                  name="presidentName"
                  value={presidentName}
                  onChange={onChange} />
                <Text
                  className="settingInputMainText"
                >
                  아이디
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  variant='auth'
                  autoComplete="off"
                  type='text'
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='사장님 아이디'
                  fontWeight='400'
                  id="presidentEmail"
                  name="presidentEmail"
                  value={presidentEmail}
                  onChange={onChange}
                  disabled
                />
                <Text
                  className="settingInputMainText"
                >
                  전화번호
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  autoComplete="off"
                  variant='auth'
                  type='tel'
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='사장님 전화번호'
                  fontWeight='400'
                  id="presidentNumber"
                  name="presidentNumber"
                  value={presidentNumber}
                  onChange={onChange}
                  maxLength='12'
                />
                <Text
                  className="settingInputMainText"
                >
                  생년월일
                </Text>
                <Input
                  className="settingInputText settingInput"
                  isRequired={true}
                  variant='auth'
                  type='text'
                  autoComplete="off"
                  backgroundColor='rgba(255,255,255,0.9)'
                  width='100%'
                  borderRadius='5'
                  placeholder='사장님 생년월일'
                  fontWeight='400'
                  id="presidentBirth"
                  name="presidentBirth"
                  value={presidentBirth}
                  onChange={onChange} />
                <Text
                  className="alertText"
                  ms='4px'
                  fontWeight='700'
                  color='red.500'>
                  {alertText}
                </Text>
                <Flex
                  justify='space-around'>
                  <Button
                    className='settingBtn'
                    backgroundColor='rgba(233, 51, 21, 0.9)'
                    boxShadow={shadow}
                    fontWeight='700'
                    width='40%'
                    onClick={() => { logout() }}>
                    <Text className="settingInputText" color='white'>
                      로그아웃
                    </Text>
                  </Button>
                  <Button
                    className='settingBtn'
                    backgroundColor='rgba(22, 204, 234, 0.9)'
                    boxShadow={shadow}
                    fontWeight='700'
                    width='40%'
                    type="submit">
                    <Text className="settingInputText" color='white'>
                      등록
                    </Text>
                  </Button>
                </Flex>
              </FormControl>
            </form>
          </Box>

        </Box>


        <Underbar color='rgba(0, 0, 0, 0.5)' />
      </Flex>
    </Flex >
  );
}