// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Flex, Icon, FormLabel, Box, Input, Text, useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent } from "@chakra-ui/react";
// Components
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function DiscountUpdateModal({ onClose, discountInfo,setReloadDiscount }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [update, setUpdate] = useState(false);
  // input data variables
  const [inputs, setInputs] = useState({
    discount_amount: "",
    discount_percent: "",
    comment: ""
  });
  const { discount_amount, discount_percent, comment } = inputs;
  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }
  const regex = /^\d+$/;
  const regexComment = /[^a-zA-Z0-9가-힣\s]/;
  function updateDiscount(e) {
    e.preventDefault();
    if (discount_amount && !regex.test(discount_amount)) {
      alert("올바른 정보를 입력하세요.");
      return;
    }
    if (discount_percent && !regex.test(discount_percent)) {
      alert("올바른 정보를 입력하세요.");
      return;
    }
    if (comment && regexComment.test(comment)) {
      alert("올바른 정보를 입력하세요.");
      return;
    }
    axios.post("/api/pos/product/updateDiscount", {
      discount_number: discountInfo.discount_number,
      discount_amount: discount_amount,
      discount_percent: discount_percent,
      comment: comment
    })
      .then((response) => {
        alert("수정 완료되었습니다.");
        setReloadDiscount(true);
        onClose();
      })
      .catch((error) => {
        alert(error);
      })

  }
  function deleteDiscount(e) {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      axios.post("/api/pos/product/deleteDiscount", {
        discount_number: discountInfo.discount_number
      })
        .then((response) => {
          alert("삭제 완료되었습니다.");
          setReloadDiscount(true);
          onClose();
        })
        .catch((error) => {
          alert(error);
        })
    }
  }
  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalAddManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='1%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            align='center'
            direction='column'
          >
            <Text>
              [할인정보]
            </Text>
          </Flex>
          {
            update ?
              <Flex
                className='modalAddManagerColumnText'
                direction='column'
              >
                <Flex align='center'>
                  <Text>
                    할인 정보 :
                  </Text>
                  <Input
                    width='50%'
                    className=''
                    isRequired={true}
                    variant='auth'
                    type='text'
                    autoComplete="off"
                    backgroundColor='rgba(255,255,255,0)'
                    borderBottom='1px solid black'
                    borderRadius='0px'
                    placeholder='ex) 직원할인'
                    id="comment"
                    name="comment"
                    value={comment}
                    onChange={onChange} />
                </Flex>
                {
                  discountInfo.discount_amount ?
                    <Flex align='center'>
                      <Text>
                        할인 금액 :
                      </Text>
                      <Input
                        width='50%'
                        className=''
                        isRequired={true}
                        variant='auth'
                        type='text'
                        autoComplete="off"
                        backgroundColor='rgba(255,255,255,0)'
                        borderBottom='1px solid black'
                        borderRadius='0px'
                        placeholder='할인 금액'
                        id="discount_amount"
                        name="discount_amount"
                        value={discount_amount}
                        onChange={onChange} />
                    </Flex>
                    :
                    <Flex align='center'>
                      <Text>
                        할인율 :
                      </Text>
                      <Input
                        width='50%'
                        className=''
                        isRequired={true}
                        variant='auth'
                        type='text'
                        autoComplete="off"
                        backgroundColor='rgba(255,255,255,0)'
                        borderBottom='1px solid black'
                        borderRadius='0px'
                        placeholder='할인율'
                        id="discount_percent"
                        name="discount_percent"
                        value={discount_percent}
                        onChange={onChange} />
                    </Flex>
                }
              </Flex>
              :
              <Flex
                className='modalAddManagerColumnText'
                direction='column'
              >
                <Flex>
                  할인 정보 : {discountInfo.comment}
                </Flex>
                {
                  discountInfo.discount_amount ?
                    <Flex>
                      할인 금액 : {discountInfo.discount_amount}
                    </Flex>
                    :
                    <Flex>
                      할인율 : {discountInfo.discount_percent}
                    </Flex>
                }
              </Flex>
          }

          {
            update ?
              <Flex width='100%' justify='space-evenly' mt='5%'>
                <Button width='40%' onClick={(e) => updateDiscount(e)}>
                  저장
                </Button>
                <Button width='40%' onClick={(e) => setUpdate(false)}>
                  취소
                </Button>
              </Flex>
              :
              <Flex width='100%' justify='space-evenly' mt='5%'>
                <Button width='40%' onClick={() => setUpdate(true)}>
                  수정
                </Button>
                <Button width='40%' onClick={(e) => deleteDiscount(e)}>
                  삭제
                </Button>
              </Flex>
          }
        </ModalContent>
      </Modal>
    </div >
  );
}