import React, { useState, useEffect } from "react";


import axios from 'axios';

// Chakra imports
import {
  ChakraProvider,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  background,
  useColorModeValue,
  Icon,
  Box

} from "@chakra-ui/react";
// assets
import peopleImage from "../../assets/img/people-image.png";
import logoChakra from "../../assets/svg/logo-white.svg";
import BarChart from "../../components/Charts/BarChart";
import LineChart from "../../components/Charts/LineChart";
import BackOfficeFullCalendar from "../../components/calandar/BackOfficeFullCalendar.js"

// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon, //../../components/Icons/Icons.js
} from "../../components/Icons/Icons.js";

import { dashboardTableData, timelineData } from "../../variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";



import theme from '../../theme/theme.js'
import MainPanel from '../../components/Layout/MainPanel.js'//'../components/Layout/MainPanel';
import PanelContainer from '../../components/Layout/PanelContainer';
import PanelContent from '../../components/Layout/PanelContent';


import POS_TEXT from "../../assets/img/service/POS_MAIN_LOGO_TEXT.png";


import CloseModal from "../open/modal/CloseModal.js"//"./modal/CloseModal";

import { FaCommentDollar, FaSignOutAlt } from "react-icons/fa";
import { RiBillLine } from "react-icons/ri";
import { FaWonSign } from "react-icons/fa6";
import { MdOutlineLocalDrink, MdOutlineCalculate } from "react-icons/md";


import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';

export default function Dashboard({ setViewComponent }) {
  const iconBoxInside = useColorModeValue("white", "white");


  const [closeModalOpen, setCloseModalOpen] = useState(false);

  const nowDetailDate = format(new Date(), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })

  const [reRender, setReRender] = useState(false);

  // server call code
  // 매출 건수
  const [ydCount, setYdCount] = useState("");
  // 매출 건수 증감 
  const [ydCountIndeCrease, setYdCountIndeCrease] = useState("");

  // 매출 
  const [ydSales, setYdSales] = useState("");
  // 매출 증감 퍼센트 
  const [ydSalesPer, setYdSalesPer] = useState("");

  // 모스트 상품 
  const [ydProduct, setYdProduct] = useState("");
  // 모스트 상품 판매 매출 
  const [ydProductSales, setYdProductSales] = useState("");

  // 정산 가능 금액
  const [ydSettleAmount, setYdSettleAmount] = useState("");
  // 정산 금액 증가율 
  const [ydSettlePer, setYdSettleAmountPer] = useState("");

  useEffect(() => {
    //var orders = [];
    axios.post('/api/pos/back/yesterDayInfo', null)
      .then((response) => {
        setYdCount(response.data.TOTAL_ORDERS ? response.data.TOTAL_ORDERS : 0);
        setYdCountIndeCrease(response.data.ORDER_D ? response.data.ORDER_D : 0);

        setYdSales(response.data.DAY_TOTAL_SALES ? response.data.DAY_TOTAL_SALES : 0);
        setYdSalesPer(response.data.SALES_P ? response.data.SALES_P : 0);

        setYdProduct(response.data.PRODUCT_NAME ? response.data.PRODUCT_NAME : '');
        setYdProductSales(response.data.TOTAL_SALES ? response.data.TOTAL_SALES : 0);

        setYdSettleAmount(response.data.TOTAL_SATTEL ? response.data.TOTAL_SATTEL : '');
        setYdSettleAmountPer(response.data.SATTLE_P ? response.data.SATTLE_P : 0);

        setReRender(false);

      })
      .catch((error) => {
        // logout();
      })

  }, [reRender])



  // 최신 주문 내역 

  /* 
  
  export const timelineData = [
    {
      logo: FaCommentDollar,
      title: "$2,400 , 아이스 아메리카노",
      date: "05/28 3:20 PM",
      color: "teal.300",
    },
    {
      logo: FaCommentDollar,
      title: "$2,800 , 아이스 카페라떼",
      date: "05/28 3:20 PM",
      color: "orange",
    },
    {
      logo: FaCommentDollar,
      title: "$5,200 , 아이스 아메리카노(외1)",
      date: "05/28 3:20 PM",
    },
  ];
  
  */

  const [timelineData, setTimelineData] = useState([]);
  const colors = ['teal.300', 'orange', 'blue.300', 'purple.300', 'red.300'];
  useEffect(() => {
    //var orders = [];
    axios.post('/api/pos/back/latestOrder')
      .then((response) => {

        const formattedData = response.data.map((item, index) => {
          const productNamesArray = item.product_names.split(',');
          const mainProductName = productNamesArray[0];
          const additionalProductsCount = productNamesArray.length - 1;
          const productName = additionalProductsCount > 0
            ? `${mainProductName} 외 ${additionalProductsCount}개`
            : mainProductName;

          return {
            logo: FaCommentDollar,
            title: `${parseInt(item.total_price).toLocaleString()} , ${productName}`,
            date: new Date(item.order_reg.replace('_', ' ')).toLocaleString('en-US', {
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            }),
            color: colors[index % colors.length], // 순환하여 색상을 선택합니다.
          };
        });
        setTimelineData(formattedData);


        setReRender(false);

      })
      .catch((error) => {
        // logout();
      })

  }, [reRender])





  //Weekly Analytics
  const [weeklyInfo, setWeeklyInfo] = useState({});
  useEffect(() => {
    //var orders = [];
    axios.post('/api/pos/back/weeklyInfo')
      .then((response) => {
        setWeeklyInfo(response.data)

        setReRender(false);

      })
      .catch((error) => {
        // logout();
      })

  }, [reRender])

  //Monthly Analytics
  const [monthlyInfo, setMonthlyInfo] = useState({});
  const [monthlyPer, setMonthlyPer] = useState(0);
  useEffect(() => {
    //var orders = [];
    axios.post('/api/pos/back/monthlyInfo')
      .then((response) => {

        const monthlyInfo = response.data.monthlyInfo;
        setMonthlyInfo(monthlyInfo);

        // 현재 달과 지난달의 매출 비교해서 퍼센트 차이 계산
        const currentMonth = new Date().toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' }).getMonth(); // 0 (Jan) to 11 (Dec)
        const lastMonth = currentMonth - 1;

        const currentMonthData = response.data.monthlyInfoPer.find(item => parseInt(item.month.split('-')[1], 10) - 1 === currentMonth);
        const lastMonthData = response.data.monthlyInfoPer.find(item => parseInt(item.month.split('-')[1], 10) - 1 === lastMonth);
     

        const currentSales = currentMonthData ? currentMonthData.total_sales : 0;
        const lastSales = lastMonthData ? lastMonthData.total_sales : 0;

        const percentageChange = lastSales === 0 ? 0 : ((currentSales - lastSales) / lastSales) * 100;

        setMonthlyPer(percentageChange.toFixed(2));

        setReRender(false);

      })
      .catch((error) => {
        // logout();
      })

  }, [reRender])






  return (
    <ChakraProvider theme={theme} >
      <MainPanel
        w={{
          base: '100%',
          xl: '100%'
        }}
        className='sub-background-color'
        // bg="#f7fafc"
        // bg="rgb(100, 200, 220)"
      >

        <PanelContent >
          <PanelContainer>

            {/* {closeModalOpen && <CloseModal nowDetailDate={nowDetailDate} logout={logout} onClose={() => setCloseModalOpen(false)} />} */}
            <Flex>

              <Image src={POS_TEXT}
                h={{ base: "40px", sm: "50px", md: "60px", lg: "70px" }}
                // w={{ base: "80px", sm: "90px", md: "120px", lg: "170px" }}
                onClick={() => { setReRender(true) }}
              />

              <Icon as={FaSignOutAlt}
                h={{ base: "40px", sm: "35px", md: "40px", lg: "45px" }}
                w={{ base: "35px", sm: "35px", md: "45px", lg: "55px" }}
                pe="6px"
                zIndex="1"
                color='white'
                position="relative"
                ml="auto"
                mt="auto"
                mb="auto"
                onClick={() => { setViewComponent('Service') }}
              >
              </Icon>
            </Flex>

            <Flex flexDirection='column' pt={{ base: "30px", md: "45px" }}>
              <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb={{ base: "20px", lg: "40px" }}>
                <MiniStatistics
                  title={"전날 매출 건수 (2일전 대비)"}
                  amount={ydCount}
                  count={`${ydCountIndeCrease < 0 ? ydCountIndeCrease : "+" + ydCountIndeCrease}`}
                  icon={<Icon as={RiBillLine} h={"24px"} w={"24px"} color={iconBoxInside} />}
                />
                <MiniStatistics
                  title={"전날 매출 (2일전 대비)"}
                  amount={ydSales}
                  percentage={ydSalesPer}
                  icon={<Icon as={FaWonSign} h={"24px"} w={"24px"} color={iconBoxInside} />}
                />
                <MiniStatistics
                  title={"전날 가장 많이 팔린 메뉴"}
                  amount={ydProduct}
                  count={"+" + ydProductSales}
                  icon={<Icon as={MdOutlineLocalDrink} h={"24px"} w={"24px"} color={iconBoxInside} />}
                />
                <MiniStatistics
                  title={"정산 가능 금액 (2일전 대비)"}
                  amount={ydSettleAmount}
                  percentage={ydSettlePer}
                  icon={<Icon as={MdOutlineCalculate} h={"24px"} w={"24px"} color={iconBoxInside} />}
                />
              </SimpleGrid>
              <Grid
                templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
                templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                gap='24px'
                mb={{ base: "20px", lg: "40px" }}
                >

                <ActiveUsers
                  title={"한주 별 통계"}
                  percentage={23}
                  chart={<BarChart weeklyInfo={weeklyInfo} />}
                  weeklyInfo={weeklyInfo}
                />
                <OrdersOverview
                  title={"최근 주문 요청 내역"}
                  // amount={30}
                  data={timelineData}
                  setViewComponent={setViewComponent}
                />

              </Grid>
              <Grid
                templateColumns={{ sm: "1fr", md: "3fr", lg: "3fr" }}
                templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
                gap='24px'
                mb={{ base: "20px", lg: "40px" }}
                >
                <SalesOverview
                  title={"월간 통계"}
                  percentage={monthlyPer}
                  chart={<BackOfficeFullCalendar monthlyInfo={monthlyInfo} />}
                  />
              </Grid>

            </Flex>
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </ChakraProvider>
  );
}
