// React
import React from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { Flex, Box, Text,  useColorModeValue, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
// Assets
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTableContent({ columns, data, cFlag, dFlag, userInfo, settleMode, handleCheckboxChange, settleList, isAllChecked, handleSelectAllChange, checkedItems, settleFinishAmount, setCalculationInfo, setCalculationDetailModalOpen }) {
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy
    );
    var noDataText = (dFlag === 0) ? 'No Data Available' : '';
    var noDataMargin = (dFlag === 0) ? '70px' : '0px';

    return (

        <Box
            borderRadius='0'
            h='100%'
            // overflowX="hidden"
            // w='93vw'
            overflowY="auto"
            className='table-box viewCard'>
            <Table whiteSpace='nowrap' position='relative'>
                <Thead position='sticky' top='0' backgroundColor='white' zIndex='1' align='center' >
                    {headerGroups.map((headerGroup, index) => (
                        <Tr key={index}
                            // backgroundColor='#007799'
                            className="tableHead table-backgroud-header-footer"
                        >
                            {headerGroup.headers.map((column, index) => (
                                // Exclude the column with the name "cFlag"
                                column.render("Header") === '작업시간'
                                    ? <Th
                                        pl='1px' pr='1px'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Text
                                            className="tableHeadText"
                                            align='center'
                                            color='white'>

                                            {
                                                settleMode ?
                                                    <input
                                                        className='modalCalCheckBox'
                                                        type="checkbox"
                                                        checked={settleList.length === settleFinishAmount}
                                                        onChange={() => handleSelectAllChange()}
                                                    />
                                                    :
                                                    column.render("Header")
                                            }
                                        </Text>
                                    </Th>
                                    :
                                    column.render("Header") === '오픈시간'
                                        ? <Th
                                            pl='1px' pr='1px'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHeadText"
                                                align='center'
                                                color='white'>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                        :
                                        column.render("Header") === '마감시간'
                                            ? <Th
                                                pl='1px' pr='1px'
                                                key={index}
                                                borderColor={borderColor}>
                                                <Text
                                                    className="tableHeadText"
                                                    align='center'
                                                    color='white'>
                                                    {column.render("Header")}
                                                </Text>
                                            </Th>
                                            :
                                            <Th
                                                pl='1px' pr='1px'
                                                key={index}
                                                borderColor={borderColor}>
                                                <Text
                                                    className="tableHeadText"
                                                    align='center'
                                                    color='white'>
                                                    {column.render("Header")}
                                                </Text>
                                            </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()} >
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr className="tableBody tableBodyText" p='1px' {...row.getRowProps()} key={index} borderBottom='2px solid' borderColor='gray.200'
                                onClick={() => {
                                    if (!settleMode) {
                                        setCalculationInfo(row.original);
                                        setCalculationDetailModalOpen(true);
                                    } else if(row.original.settle !== '1') {
                                        settleList.includes(row.original);
                                        handleCheckboxChange(row.original.date);
                                    }
                                }}
                            >
                                {row.cells.splice(0, dFlag).map((cell, index) => {

                                    // Variable for each <Td> in ItemTable
                                    let data = "";

                                    if (cell.column.Header === "작업시간") {

                                        data = (
                                            settleMode ?
                                                <Flex>

                                                    <input
                                                        className='modalCalCheckBox'
                                                        type="checkbox"
                                                        checked={settleList.includes(row.original)}
                                                        // onChange={() => handleCheckboxChange(row.original.date)}
                                                        disabled={row.original.settle === '1' ? true : false}
                                                    />
                                                </Flex>
                                                :
                                                <Text align='center' color={textColor} >
                                                    {(cell.value).substring(0, 16)}
                                                </Text>
                                        );
                                    } else if (cell.column.Header === "오픈시간") {
                                        data = (
                                            <Text align='center' color={textColor}>
                                                {(cell.value).substring(0, 16)}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "마감시간") {
                                        data = (
                                            <Text align='center' color={textColor}>
                                                {(cell.value).substring(0, 16)}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "판매액") {
                                        data = (
                                            <Text align='center' color={textColor} >
                                                {(cell.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                            </Text>
                                        );
                                    }
                                    return (
                                        <Td
                                            p='2%'
                                            pl='1px' pr='1px'
                                            {...cell.getCellProps()}
                                            key={index}
                                            className="tableBody"
                                            maxH='30px !important'
                                            // minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        )
                    }
                    )}
                </Tbody>
            </Table>
            <Text color={textColor} fontSize='xl' fontWeight='500' align='center' justify='center' my={noDataMargin}>
                {noDataText}
            </Text>
        </Box >
    );
}
