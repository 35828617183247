export const columnsDataManager = [
  {
    Header: "아이디",
    accessor: "email",
  },
  {
    Header: "이름",
    accessor: "name",
  },
  {
    Header: "직급",
    accessor: "authority",
  }
];
