// React
import React, { useState, useEffect, useRef } from "react";
// External Libraries
import axios from 'axios';
import { Box, Flex, Text, Image, Button } from "@chakra-ui/react";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
// Components
import MainCard from "./components/MainCard";
import "../../css/main.css"
import "../../css/datePicker.css"
import SaleCard from "./components/main/sale/SaleCard";
import TransactionCard from "./components/main/transaction/TransactionCard";
import StoreCard from "./components/main/store/StoreCard";
import CalculationCard from "./components/main/calculation/CalculationCard";
import veta_pos from "../../assets/img/service/veta_pos.png";
import POS_TEXT from "../../assets/img/service/POS_MAIN_LOGO_TEXT.png"


import SecondPwdModal from "./components/main/modal/SecondPwdModal";
import SirenOrderModal from "./components/main/sale/modal/SirenOrderModal";
export default function MainDashboard({ setViewComponent, token, setToken, sirenOrder, sirenPopUp, setSirenPopUp, connectPrinter }) {
  // Variables to check the current window size (mobile/pc)
  const [nowComponent, setNowComponent] = useState('');

  const scrollRef = useRef(null);
  const scrollToRef = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  };
  const [loginUserName, setLoginUserName] = useState('');
  useEffect(() => {
    if (token) {
      axios.post('/api/auth/isToken', null, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
        },
      })
        .then(response => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          if (response.data.error) {
            delete axios.defaults.headers.common['Authorization'];
            setViewComponent("Login");
          } else {
            setLoginUserName(response.data.name);
            setViewComponent(response.data.page);
          }
        })
        .catch(() => {
          delete axios.defaults.headers.common['Authorization'];
        })
    } else {
      setViewComponent("Login");
    }
  }, []);
  // useEffect 새로고침

  const [uploadOrder, setUploadOrder] = useState(false);
  const [uploadUser, setUploadUser] = useState(false);

  // init Value
  const [userInfo, setUserInfo] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [managerInfo, setManagerInfo] = useState();
  const [transactionDaliyList, setTransactionDaliyList] = useState([]);
  const [totalResultSalesAmount, setTotalResultSalesAmount] = useState(0);
  const [todayOrderAmount, setTodayOrderAmount] = useState(0);// 판매처리 주문갯수
  const [storeInfo, setStoreInfo] = useState();
  //date picker
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formattedDate = selectedDate
    ? format(selectedDate, 'yyyy-MM-dd (eee)', { locale: ko })
    : '';
  const formattedMonth = selectedDate
    ? format(selectedDate, 'yyyy-MM', { locale: ko })
    : '';
  const [mainCall, setMainCall] = useState(false);
  const nowDetailDate = format(new Date(), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })
  //2차 비밀번호 변수
  const inputRefs = useRef([]);
  const [secondPwdModalOpen, setSecondPwdModalOpen] = useState(false);
  const [nextFunction, setNextFunction] = useState();
  const [secondCheck, setSecondCheck] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState();
  // 뒤로가기 stack code

  // 안드로이드 취소버튼 감지 및 처리
  const [pageStack, setPageStack] = useState([]);
  // 새로운 페이지를 스택에 추가하고, 스택 업데이트

  function nowComponentStack(page) {
    setPageStack([...pageStack, page]);
    setNowComponent(page);
  };

  // 취소 버튼을 누르면 스택에서 이전 페이지를 팝하여 현재 페이지 변경
  const backComponent = () => {
    setPageStack((prevStack) => prevStack.slice(0, -1));
    setNowComponent(pageStack.length > 0 ? pageStack[pageStack.length - 1] : '');
  };
  window.onpopstate = () => {
    backComponent()
  }
  window.history.pushState(null, null, null);

  useEffect(() => {
    var orders = [];
    axios.post('/api/user/mainOrder')
      .then((response) => {
        for (var i = 0; i < response.data.order.length; i++) {
          orders.push({
            "order_number": response.data.order[i].order_number,
            "transaction_hash": response.data.order[i].transaction_hash,
            "sender": response.data.order[i].sender,
            "total_price": response.data.order[i].total_price,
            "order_comment": response.data.order[i].order_comment,
            "option_takeout": response.data.order[i].option_takeout,
            "order_reg": response.data.order[i].order_reg,
            "order_status": response.data.order[i].order_status,
            "order_detail": response.data.order[i].detail
          })
        }
        setTodayOrderAmount(response.data.order.length);
        setOrderList(orders);
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
    if (uploadOrder) {
      setUploadOrder(false);
    }
  }, [uploadOrder, sirenOrder, nowComponent, transactionInfo]
  );

  useEffect(() => {
    var salesAmount = 0;
    axios.post('/api/store/todayAmount')
      .then((response) => {
        for (var j = 0; j < response.data.amount.length; j++) {
          salesAmount += Number(response.data.amount[j].ca);
        }
        setTotalResultSalesAmount(salesAmount);
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
  }, [uploadOrder]
  );


  const [userList, setUserList] = useState([]);

  useEffect(() => {
    var managers = [];
    axios.post('/api/user/mainUser')
      .then((response) => {
        var formattDate = "영업전"
        if (response.data.store) {
          formattDate = format(new Date(response.data.store.open_date), 'yyyy-MM-dd (eee) HH:mm', { locale: ko });
          setStoreInfo({
            "store_number": response.data.store.store_number,
            "open_date": formattDate ? formattDate : "영업전",
            "open_manager": response.data.store.open_manager,
            "close_date": response.data.store.close_date,
            "close_open_date": response.data.store.close_open_date,
            "close_manager": response.data.store.close_manager,
            "open_status": response.data.open
          })
        }
        var userInfos = [];
        for (var i = 0; i < response.data.user.length; i++) {
          if (response.data.user[i].name === loginUserName) {
            setUserInfo({
              "name": response.data.user[i].name,
              "authority": response.data.user[i].authority,
              "email": response.data.user[i].email,
              "phone_number": response.data.user[i].phone_number,
              "birth_day": response.data.user[i].birth_day,
              "authority_sales": response.data.user[i].authority_sales,
              "authority_store": response.data.user[i].authority_store,
              "authority_transaction": response.data.user[i].authority_transaction,
              "authority_calculation": response.data.user[i].authority_calculation
            })
          } else {
            userInfos.push({
              "email": response.data.user[i].email,
              "name": response.data.user[i].name,
              "phone_number": response.data.user[i].phone_number,
              "birth_day": response.data.user[i].birth_day,
              "authority": response.data.user[i].authority,
              "reg_date": response.data.user[i].reg_date,
              "authority_sales": response.data.user[i].authority_sales,
              "authority_store": response.data.user[i].authority_store,
              "authority_transaction": response.data.user[i].authority_transaction,
              "authority_calculation": response.data.user[i].authority_calculation
            })
          }
          managers.push({
            "email": response.data.user[i].email,
            "name": response.data.user[i].name,
            "phone_number": response.data.user[i].phone_number,
            "birth_day": response.data.user[i].birth_day,
            "authority": response.data.user[i].authority,
            "reg_date": response.data.user[i].reg_date,
            "authority_sales": response.data.user[i].authority_sales,
            "authority_store": response.data.user[i].authority_store,
            "authority_transaction": response.data.user[i].authority_transaction,
            "authority_calculation": response.data.user[i].authority_calculation
          })
        }
        setManagerList(managers);
        setUserList(userInfos)
      })
      .catch((error) => {
        alert(error);
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
    if (uploadUser) {
      setUploadUser(false);
    }
    if (mainCall) {
      setMainCall(false);
    }
  }, [uploadUser, mainCall, loginUserName]
  );



  const [showSelect, setShowSelect] = useState(false); // select 표시 여부를 관리하는 상태
  const [selectedUser, setSelectedUser] = useState(''); // 선택된 유저를 관리하는 상태

  const handleSelectChange = (e) => {

    const selectedId = e.target.value;
    const selectedName = userList.find(user => user.email === selectedId)?.name;

    if (selectedName && selectedName !== loginUserName) {
      //setSecondPwdModalOpen(true);
      localStorage.setItem('id', selectedId);
      localStorage.setItem('loginUser', selectedId);
      logout();
    }
  };

  // A function variable for the logout functionality
  function logout() {
    setToken(null);
    localStorage.removeItem('jwtToken');
    setViewComponent('');
    window.location.reload();
  }
  const [txLoading, setTxLoading] = useState(false);

  const [txCancelOk, setTxCancelOk] = useState(false);

  // siren order
  function sirenOngoing(order) {
    axios.post("/api/pos/order/ongoing", {
      transaction_hash: order.transaction_hash,
      order_number: order.order_number,
      order_status: order.order_status
    })
      .then((response) => {
        setUploadOrder(true);
        setTransactionInfo({
          "order_number": response.data.order_number,
          "transaction_hash": response.data.transaction_hash,
          "sender": response.data.sender,
          "total_price": response.data.total_price,
          "order_comment": response.data.order_comment,
          "option_takeout": response.data.option_takeout,
          "order_reg": response.data.order_reg,
          "order_status": response.data.order_status,
          "cost_of_goods_sold_amount": response.data.ca,
          "refund_amount": response.data.ra,
          "fee_amount": response.data.fa,
          "settlement": response.data.settlement,
          "transaction_status": response.data.transaction_status,
          "email": response.data.email
        });
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
  }


  function orderOngoing(order) {
    axios.post("/api/pos/order/ongoing", {
      transaction_hash: order.transaction_hash,
      order_number: order.order_number,
      order_status: order.order_status
    })
      .then((response) => {
        setUploadOrder(true);
        setTransactionInfo({
          "order_number": response.data.order_number,
          "transaction_hash": response.data.transaction_hash,
          "sender": response.data.sender,
          "total_price": response.data.total_price,
          "order_comment": response.data.order_comment,
          "option_takeout": response.data.option_takeout,
          "order_reg": response.data.order_reg,
          "order_status": response.data.order_status,
          "cost_of_goods_sold_amount": response.data.ca,
          "refund_amount": response.data.ra,
          "fee_amount": response.data.fa,
          "settlement": response.data.settlement,
          "transaction_status": response.data.transaction_status,
          "email": response.data.email
        });
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
  }
  function orderReject(order) {
    setTxLoading(true);

    axios.post("/api/pos/order/reject", {
      transaction_hash: order.transaction_hash,
      order_number: order.order_number,
      reason:order.reason
    })
      .then((response) => {
        setUploadOrder(true);
        setTransactionInfo({
          "order_number": response.data.order_number,
          "transaction_hash": response.data.transaction_hash,
          "sender": response.data.sender,
          "total_price": response.data.total_price,
          "order_comment": response.data.order_comment,
          "option_takeout": response.data.option_takeout,
          "order_reg": response.data.order_reg,
          "order_status": response.data.order_status,
          "cost_of_goods_sold_amount": response.data.ca,
          "refund_amount": response.data.ra,
          "fee_amount": response.data.fa,
          "settlement": response.data.settlement,
          "transaction_status": response.data.transaction_status,
          "email": response.data.email
        })
        setTxCancelOk(true);
        setTxLoading(false);
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
  }
  function orderComplete(order) {
    axios.post("/api/pos/order/complete", {
      transaction_hash: order.transaction_hash,
      order_number: order.order_number,
      order_status: order.order_status
    })
      .then((response) => {
        setUploadOrder(true);
        setTransactionInfo({
          "order_number": response.data.order_number,
          "transaction_hash": response.data.transaction_hash,
          "sender": response.data.sender,
          "total_price": response.data.total_price,
          "order_comment": response.data.order_comment,
          "option_takeout": response.data.option_takeout,
          "order_reg": response.data.order_reg,
          "order_status": response.data.order_status,
          "cost_of_goods_sold_amount": response.data.ca,
          "refund_amount": response.data.ra,
          "fee_amount": response.data.fa,
          "settlement": response.data.settlement,
          "transaction_status": response.data.transaction_status,
          "email": response.data.email
        })
      })
      .catch((error) => {
        window.location.reload();
        setNowComponent('sale');
        // alert("세션 만료로 새로고침 후 이용해주세요.");
      })
  }
  function orderClose(order) {
    axios.post("/api/pos/order/close", {
      transaction_hash: order.transaction_hash,
      order_number: order.order_number,
      order_status: order.order_status
    })
      .then((response) => {
        setUploadOrder(true);
      })
      .catch((error) => {
      })
    // setTransactionDetailModalOpen(false);
  }
  useEffect(() => {
    if (showSelect) {
      setShowSelect(false);
    }
  }, [nowComponent])


  return (
    <>
      <Flex
        overflowY='hidden'
        // backgroundColor='#46D1E8'
        className='main-background-color'
        direction='column'
        width='100vw'
        height='100vh'
        zIndex='1'>

        <Flex
          mt='2vh'
          width='90%'
          mx='auto'
          textColor='black'
          className="navbarText"
          justifyContent='space-between'
        >
          <Image src={POS_TEXT} className='mainNavberImage' mt="5px"/>
          <Button onClick={()=>connectPrinter()} mr="auto" ml="3px" mt="auto" mb="auto" className="main-btn-color">프린터연결</Button>
          <Flex
            align='end'
            className='mainNavberSmallText'
            direction='column' >

            {sirenPopUp && <SirenOrderModal orderOngoing={sirenOngoing} setNowComponent={setNowComponent} nowDetailDate={nowDetailDate} sirenOrder={sirenOrder} onClose={() => setSirenPopUp(false)} setUploadOrder={setUploadOrder} />}
            {secondPwdModalOpen && <SecondPwdModal titleText='비밀번호' userInfo={userInfo} setSecondCheck={setSecondCheck} inputRefs={inputRefs} onClose={() => setSecondPwdModalOpen(false)} />}

            <Flex
               mt='auto' direction='column' 
              justifyContent='space-between' 

                            >
              {/* <Button
                style={{ color: "black", marginRight: "10px" }}
                onClick={() => connectPrinter()}
              >
                pos print 연결</Button> */}
                <Flex justifyContent='space-between'>

              <Text isTruncated   mr="5px" >
                담당자
              </Text>
              {
                showSelect ?
                <select
                className='userBtn modalStoreAddManagerTextHeight modalStoreAddManagerInput modalInputPlaceholder'
                id="changeUser"
                name="changeUser"
                value={selectedUser}
                style={{ paddingRight: '0px'}}
                onChange={handleSelectChange}
                onClick={(e) => e.stopPropagation()} // 클릭 이벤트 전파 중지
                >
                    <option value="" disabled>변경.</option>
                    {userList.map((user) => (
                      <option value={user.email} key={user.id}>{user.name}</option>
                    ))}
                  </select>
                  :
                  <Button
                  className='mainNavberBigText userBtn'
                  // color='rgba(2, 109, 126, 1)'
                  align='center'
                  // w='60% !important'
                  onClick={() => setShowSelect(!showSelect)} // 만약 이걸 클릭 한다면 밑에 select 가 보여지게 
                  >
                    {storeInfo && storeInfo.open_status === 1 ? loginUserName : "영업전"}
                  </Button>
              }
                </Flex>
                <Flex>

              <Text
                isTruncated
                mt='auto'
                >
                영업일자&nbsp;
              </Text>
              {
                storeInfo && storeInfo.open_date && storeInfo.open_status === 1 && nowComponent === 'transaction' ?
                <DatePicker
                className="txDatePicker"
                dateFormat='yyyy-MM-dd(eee)' // 날짜 형태
                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                locale={ko}
                withPortal
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
                />
                :
                storeInfo && storeInfo.open_date && storeInfo.open_status === 1 && nowComponent === 'calculation' ?
                <DatePicker
                className="txDatePicker"
                dateFormat='yyyy년 MM월' // 날짜 형태
                showMonthYearPicker // 월 단위 선택 가능
                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                locale={ko}
                withPortal
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
                />
                :
                <Text
                mt='auto'
                className="dateText main-text-color"
                isTruncated
                >
                      {
                        storeInfo && storeInfo.open_date && storeInfo.open_status === 1 ?
                        (storeInfo.open_date).substring(0, 14)
                        :
                        "영업전"
                      }
                    </Text>
              }
        </Flex>
            </Flex>
          </Flex>
        </Flex>

        {
          nowComponent === ''
            ?
            <Box
              borderRadius='20px 20px 0px 0px'
              zIndex='2'
              height='95vh'
              width='100vw'
              backgroundColor='white'
            >

              <MainCard
                userInfo={userInfo}
                storeInfo={storeInfo}
                scrollToRef={scrollToRef}
                setNowComponent={nowComponentStack}
                setViewComponent={setViewComponent}
                setMainCall={setMainCall}
                sirenOrder={sirenOrder}
                sirenPopUp={sirenPopUp}
                setSirenPopUp={setSirenPopUp}
                setSecondPwdModalOpen={setSecondPwdModalOpen}
                secondCheck={secondCheck}
                setSecondCheck={setSecondCheck}
                setNextFunction={setNextFunction}
                nextFunction={nextFunction}
                totalResultSalesAmount={totalResultSalesAmount}
                orderList={orderList}
              />
            </Box>

            :
            nowComponent === 'sale' ?

              <SaleCard orderList={orderList} userInfo={userInfo} setNowComponent={setNowComponent} todayOrderAmount={todayOrderAmount} setUploadOrder={setUploadOrder}
                transactionInfo={transactionInfo} setTransactionInfo={setTransactionInfo}
                txCancelOk={txCancelOk} setTxCancelOk={setTxCancelOk}
                orderOngoing={orderOngoing} orderClose={orderClose}
                orderReject={orderReject} orderComplete={orderComplete}
                txLoading={txLoading}
              />
              :
              nowComponent === 'transaction' ?

                <TransactionCard userInfo={userInfo} selectedDate={formattedDate} nowDetailDate={nowDetailDate} orderReject={orderReject} setTransactionInfo={setTransactionInfo} transactionInfo={transactionInfo}
                  setNowComponent={setNowComponent} storeInfo={storeInfo} txLoading={txLoading} />
                :
                nowComponent === 'store' ?

                  <StoreCard userInfo={userInfo} setNowComponent={setNowComponent} managerList={managerList} managerInfo={managerInfo} setManagerInfo={setManagerInfo}
                    sirenOrder={sirenOrder}
                    setUploadUser={setUploadUser}
                  />
                  :
                  nowComponent === 'calculation' ?

                    <CalculationCard userInfo={userInfo} setNowComponent={setNowComponent} transactionDaliyList={transactionDaliyList} totalResultSalesAmount={totalResultSalesAmount}
                      formattedMonth={formattedMonth} 
                      setViewComponent={setViewComponent}
                      />
                    :
                    null
        }
      </Flex >
    </>
  );
}
