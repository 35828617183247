// React
import React, { useMemo } from "react";
// Components
// Assets
import TransactionTableContent from "./TransactionTableContent.js";
import { columnsDataTransaction } from "../../../../../../variables/transaction/columnsDataTransaction";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTable({ transactionList, setTransactionInfo, userInfo,setTransactionDetailModalOpen}) {
    const columns = useMemo(() => columnsDataTransaction, [columnsDataTransaction]);
    const tableData = useMemo(() => transactionList, [transactionList]);
    return (
        <>
            <TransactionTableContent columns={columns} dFlag={4}  data={tableData} setTransactionInfo={setTransactionInfo} userInfo={userInfo} setTransactionDetailModalOpen={setTransactionDetailModalOpen}/>
        </>
    );
}
