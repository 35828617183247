import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Button, Flex, FormControl, FormLabel, Box, Input, Text, Icon,
  useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent, Select, Grid, GridItem
} from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
import '../../../../../../css/Switch.css';

export default function ItemAddModal({ onClose, cardRelod, bigCategoryList, optionGroupList }) {

  const regexPrice = /^[0-9]+$/;
  const textColor = useColorModeValue("navy.700", "white");
  const [readOnly, setReadOnly] = useState(false);
  const [preview, setPreview] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [inputColor, setInputColor] = useState("none");
  const [alertText, setAlertText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(true);
  const [inputCatList, setInputCatList] = useState([]);
  const [inputOptionGroupList, setOptionGroupList] = useState([]);
  const [stockOpen, setStockOpen] = useState(false);

  const [inputs, setInputs] = useState({
    item_name: "",
    price: "",
    item_information: "",
    item_image: "",
    stock: "",
    big_category: "",
    option_group: ""
  });

  const { item_name, price, item_information, item_image, stock, big_category, option_group } = inputs;

  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  function addCategoryPlus() {
    if (big_category && !inputCatList.some(item => item.big_category === big_category)) {
      setInputCatList(prevCatList => [...prevCatList, { big_category }]);
      setInputs({ ...inputs, big_category: "" });
    }
  }

  function minusCategory(e) {
    inputCatList.splice(e.target.value, 1);
    setInputCatList([...inputCatList]);
  }


  function addOptionGroupPlus() {
    if (option_group && !inputOptionGroupList.some(item => item.option_group === option_group)) {
      setOptionGroupList(prevOptionGroupList => [...prevOptionGroupList, { option_group }]);
      setInputs({ ...inputs, option_group: "" });
    }
  }

  function minusOptionGroup(e) {
    inputOptionGroupList.splice(e.target.value, 1);
    setOptionGroupList([...inputOptionGroupList]);
  }

  function onChangeImage(fileBlob) {
    if (fileBlob) {
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(fileBlob);
      setProfileImage(fileBlob);
    } else {
      setPreview("");
      setProfileImage(null);
    }
  }

  function confirm(e) {
    e.preventDefault();
    if (!item_name) {
      setAlertText("Check Item Name");
      return;
    } else if (!regexPrice.test(price) || !price) {
      setAlertText("Check Item Price");
      return;
    } else if (stockOpen && !stock) {
      setAlertText("Check Item Stock");
      return;
    } else if (!inputCatList.length) {
      setAlertText("Check Item Category");
      return;
    }
    handleSubmit();
  }

  async function handleSubmit() {
    setSubmitLoading(false);
    const formData = new FormData();

    // 선택 사항
    if (profileImage) {
      formData.append('image1', profileImage);
    }

    // 선택 사항 
    if(inputOptionGroupList.length !== 0){
      formData.append('option_group',JSON.stringify(inputOptionGroupList));
    }


    const category = inputCatList.map(cat => {
      const match = bigCategoryList.find(element => element.category_big_name === cat.big_category);
      return { big_category: match?.category_big_id };
    });

    formData.append('category', JSON.stringify(category));
    formData.append('product_name', item_name);
    formData.append('price1', price);
    formData.append('content', item_information || item_name);
    formData.append('product_stock', stockOpen ? stock : "infinity");


    try {
      const response = await axios.post('/api/pos/product/reg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      if (response.data === 1 || response.data === true) {
        alert("등록이 완료되었습니다.");
        cardRelod();
       // setUploadItem(true);
        onClose();
      }
    } catch (error) {
      setAlertText("다시 시도해주세요.");
    } finally {
      setSubmitLoading(true);
    }
  }

  const inputBgColor = useColorModeValue("white", "gray.700"); // 입력 필드 배경색 설정
  const inputBorderColor = useColorModeValue("gray.300", "gray.600"); // 입력 필드 테두리 색상
  const inputTextColor = useColorModeValue("black", "white"); // 입력 필드 텍스트 색상
  const labelTextColor = useColorModeValue("gray.700", "gray.300"); // 레이블 텍스트 색상

  return (
    <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
      <ModalContent
        className='modalUpdateManager sub-modal-background-color'
        backgroundRepeat='no-repeat'
        bgSize='cover'
        bgPosition='10%'
        my='auto'
        border='1px solid'
        borderColor='black'
        borderRadius='0px'
        // backgroundColor='rgba(229, 244, 255, 1)'
        textColor='black'
        maxW="90%"
        overflowY="auto"
        maxH="80%"
        minW="300px"
      >
        <Flex justify="flex-end">
          <Icon className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
        </Flex>
        <Box p="4" >
          <Text textAlign="center" fontSize="2xl" mb="4">[ 상품 추가 ]</Text>
          <Text textAlign="right" fontSize="sm" mb="4">* 표시는 필수로 입력 해야하는 값 입니다.</Text>
          <form onSubmit={handleSubmit}>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>상품명 *</FormLabel>
                  <Input

                    isRequired
                    fontSize='15px'
                    value={item_name}
                    id="item_name"
                    name="item_name"
                    maxLength='20'
                    onChange={onChange}
                    readOnly={readOnly}
                    isInvalid
                    errorBorderColor={inputColor}
                    bg={inputBgColor} // 입력 필드 배경색
                    borderColor={inputBorderColor} // 입력 필드 테두리 색상
                    color={inputTextColor} // 입력 필드 텍스트 색상
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>판매가 *</FormLabel>
                  <Input
                    isRequired
                    fontSize='15px'
                    value={price}
                    id="price"
                    name="price"
                    onChange={onChange}
                    readOnly={readOnly}
                    isInvalid
                    errorBorderColor={inputColor}
                    bg={inputBgColor} // 입력 필드 배경색
                    borderColor={inputBorderColor} // 입력 필드 테두리 색상
                    color={inputTextColor} // 입력 필드 텍스트 색상
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>사진</FormLabel>
                  <Input
                    fontSize='15px'
                    type='file'
                    id="item_image"
                    name="item_image"
                    accept="image/*"
                    onChange={(e) => onChangeImage(e.target.files[0])}
                    readOnly={readOnly}
                    isInvalid
                    errorBorderColor={inputColor}
                    bg={inputBgColor} // 입력 필드 배경색
                    borderColor={inputBorderColor} // 입력 필드 테두리 색상
                    color={inputTextColor} // 입력 필드 텍스트 색상
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }} rowSpan={2}>
                <FormControl>
                  <FormLabel>미리보기</FormLabel>
                  {preview && (
                    <Box mt="2" border="1px solid #E7EAF3" maxW="100%" display="flex" justifyContent="center" alignItems="center">
                      <Image
                        boxSize='17vh'
                        objectFit="contain"
                        src={preview}
                        alt="preview-img"
                        maxH="100%" // 부모 Box의 최대 높이에 맞게 조정
                        maxW="100%" // 부모 Box의 최대 너비에 맞게 조정
                      />
                    </Box>
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>상품 재고</FormLabel>
                  <Flex>
                    <Button
                      backgroundColor='#ccc'
                      className={`switch ${stockOpen ? 'on' : 'off'}`}
                      onClick={() => setStockOpen(!stockOpen)}
                      my='auto'
                    >
                      <div className="slider"></div>
                    </Button>
                    {stockOpen ? (
                      <Input
                        fontSize='15px'
                        value={stock}
                        id="stock"
                        name="stock"
                        onChange={onChange}
                        readOnly={readOnly}
                        isInvalid
                        errorBorderColor={inputColor}
                        ml={2}
                        bg={inputBgColor} // 입력 필드 배경색
                        borderColor={inputBorderColor} // 입력 필드 테두리 색상
                        color={inputTextColor} // 입력 필드 텍스트 색상
                      />
                    ) : (
                      <Input
                        fontSize='15px'
                        placeholder="재고수량 무한대"
                        value="재고수량 무한대"
                        textAlign='right'
                        disabled
                        ml={2}
                        bg={inputBgColor} // 입력 필드 배경색
                        borderColor={inputBorderColor} // 입력 필드 테두리 색상
                        color={inputTextColor} // 입력 필드 텍스트 색상
                      />
                    )}
                  </Flex>
                </FormControl>
              </GridItem>




              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>상품 분류  *</FormLabel>
                  <Flex>
                    <Select
                      fontSize='15px'
                      value={big_category}
                      id="big_category"
                      name="big_category"
                      onChange={onChange}
                      bg={inputBgColor} // 입력 필드 배경색
                      borderColor={inputBorderColor} // 입력 필드 테두리 색상
                      color={inputTextColor} // 입력 필드 텍스트 색상
                    >
                      <option value="" disabled>카테고리 선택</option>
                      {bigCategoryList.map((option) => (
                        <option
                          value={option.category_big_name}
                          key={option.category_big_name}>
                          {option.category_big_name}
                        </option>
                      ))}
                    </Select>
                    <Button
                      ml={2}
                      onClick={addCategoryPlus}
                    >
                      추가
                    </Button>
                  </Flex>
                  <Box mt={2}>
                    {inputCatList.map((category, index) => (
                      <Flex key={index} justify="space-between" border='1px solid' borderColor='gray.200' p={2} mb={2}>
                        <Text>{category.big_category}</Text>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => minusCategory({ target: { value: index } })}
                        >
                          제거
                        </Button>
                      </Flex>
                    ))}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel>옵션 선택</FormLabel>
                  <Flex>
                    <Select
                      fontSize='15px'
                      value={option_group}
                      id="option_group"
                      name="option_group"
                      onChange={onChange}
                      bg={inputBgColor} // 입력 필드 배경색
                      borderColor={inputBorderColor} // 입력 필드 테두리 색상
                      color={inputTextColor} // 입력 필드 텍스트 색상
                    >
                      <option value="" disabled>옵션 선택</option>
                      {optionGroupList.map((group) => (
                        <option key={group.option_group_id} value={group.option_group_id}>
                          {group.option_group_name} ({group.option_group_type === 'required' ? "필수 선택" : "선택 옵션"})
                        </option>
                      ))}
                    </Select>
                    <Button ml={2} onClick={addOptionGroupPlus}>
                      추가
                    </Button>
                  </Flex>
                  <Box mt={2}>
                    {inputOptionGroupList.map((optionGroup, index) => (
                      <Flex key={index} justify="space-between" border='1px solid' borderColor='gray.200' p={2} mb={2}>
                        <Text>
                          { // 선택된 옵션의 group id 와 일치하는 optionGroupList 에서 찾고 그 내용 화면에 뿌림 
                            optionGroupList
                              .filter(group => group.option_group_id === optionGroup.option_group)
                              .map(filteredGroup => (
                                <React.Fragment key={filteredGroup.option_group_id}>
                                  {filteredGroup.option_group_name} ({filteredGroup.option_group_type === 'required' ? "필수 선택" : "선택 옵션"})
                                </React.Fragment>
                              ))
                          }
                        </Text>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => minusOptionGroup({ target: { value: index } })}
                        >
                          제거
                        </Button>
                      </Flex>
                    ))}
                  </Box>
                </FormControl>
              </GridItem>

{/* 
              <GridItem colSpan={2} >
                <FormControl>
                  <FormLabel>상품 설명</FormLabel>
                  <Textarea
                    isRequired
                    h='10vh'
                    fontSize='sm'
                    value={item_information}
                    id="item_information"
                    name="item_information"
                    onChange={onChange}
                    readOnly={readOnly}
                    isInvalid
                    errorBorderColor={inputColor}
                    bg={inputBgColor} // 입력 필드 배경색
                    borderColor={inputBorderColor} // 입력 필드 테두리 색상
                    color={inputTextColor} // 입력 필드 텍스트 색상
                  />
                </FormControl>
              </GridItem>
              {alertText && (
                <GridItem colSpan={2}>
                  <Text fontSize='13px' color='red.400'>
                    {alertText}
                  </Text>
                </GridItem>
              )}
                    */}



            </Grid>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='5vh'
              mt={4}
              onClick={confirm}
              isLoading={!submitLoading}
              className="main-btn-color"

            >
              등록하기
            </Button>
          </form>
        </Box>

      </ModalContent>
    </Modal>
  );
}
