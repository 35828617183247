// React
import React, { useState, useEffect } from "react";
// External Libraries
import axios from 'axios';
import { Flex, Box, Text, Button, useColorModeValue, Icon, Input } from "@chakra-ui/react";
import '../../css/main.css';
import '../../css/datePicker.css';
import { BsCircleFill } from "react-icons/bs";
import OpenModal from "./modal/OpenModal";
import CloseModal from "./modal/CloseModal";
//external
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
import SecondPwdModal from "./modal/SecondPwdModal";
// Components



export default function MainDashboard({ setViewComponent, token, setToken,promptUserForPort }) {

  //modal status
  const [openModalOpen, setOpenModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const nowDate = format(new Date(), 'yyyy-MM-dd eeee', { locale: ko })
  const nowDetailDate = format(new Date(), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })

  const formattedDate = selectedDate
    ? format(selectedDate, 'yyyy-MM-dd eeee', { locale: ko })
    : '';


  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  const [openManager, setOpenManager] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  useEffect(() => {
    if (token) {
      axios.post('/api/auth/isToken', null, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
        },
      })
        .then(response => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          if (response.data.error) {
            delete axios.defaults.headers.common['Authorization'];
            setViewComponent("Login");
          } else {
            setLoginUserName(response.data.name);
            setOpenManager(response.data.name);
            setViewComponent(response.data.page);
          }
        })
        .catch(() => {
          delete axios.defaults.headers.common['Authorization'];
          setLoginUserName('');
        })
    } else {
      setViewComponent("Login");
    }
  }, []);



  function logout() {
    setToken(null);
    localStorage.removeItem('jwtToken');
    setViewComponent('');
  }
  const [closeOpenDate, setCloseOpenDate] = useState('');
  const [closeDate, setCloseDate] = useState('');
  const [closeManager, setCloseManager] = useState('');
  const [openDate, setOpenDate] = useState('');
  const [managerList, setManagerList] = useState([]);

  const [error, setError] = useState(false);
  function openModalCheck() {
    if (openManager) {
      setOpenModalOpen(true);
    } else {
      setError(!error);
    }
  }

  const [secondPwdModalOpen, setSecondPwdModalOpen] = useState(false);

  useEffect(() => {
    var managers = [];
    axios.post('/api/user/mainUser')
      .then((response) => {
        for (var i = 0; i < response.data.user.length; i++) {
          managers.push({
            "email": response.data.user[i].email,
            "name": response.data.user[i].name,
            "phone_number": response.data.user[i].phone_number,
            "birth_day": response.data.user[i].birth_day,
            "authority": response.data.user[i].authority,
            "reg_date": response.data.user[i].reg_date,
            "authority_sales": response.data.user[i].authority_sales,
            "authority_store": response.data.user[i].authority_store,
            "authority_transaction": response.data.user[i].authority_transaction,
            "authority_calculation": response.data.user[i].authority_calculation
          })
        }
        setManagerList(managers);
      })
      .catch((error) => {
      })
  }, []
  );



  useEffect(() => {
    axios.post('/api/store/setting')
      .then((response) => {
        const formattedDate = response.data.close_open_date
          ? format(new Date(response.data.close_open_date), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })
          : '';
        const formattedDate2 = response.data.close_date
          ? format(new Date(response.data.close_date), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })
          : '';
        setCloseOpenDate(formattedDate);
        setCloseDate(formattedDate2);
        setCloseManager(response.data.close_manager);
        setOpenDate(response.data.open_date);
      })
      .catch((error) => {
        // console.log(error)
      })
  }, [])

  function openStore() {
    promptUserForPort();
    
    axios.post('/api/store/open', {
      open_manager: openManager,
      open_date: formattedDate
    })
      .then((response) => {
        if (response.data.error) {
          alert("잠시 후 다시 시도해주세요.");
        } else {
          setViewComponent(response.data.page);
        }
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })

  }



  return (
    <>
      <Box
        width='100%'
        height='100vh'
        align='center'
        position='relative'
        className ='sub-background-color'
        // backgroundColor='rgba(70, 209, 232, 1)'
      >
        {secondPwdModalOpen && <SecondPwdModal openStore={openStore} titleText='비밀번호' onClose={() => setSecondPwdModalOpen(false)} openManager={openManager} managerList={managerList}/>}
        {openModalOpen && <OpenModal selectedDate={formattedDate} nowDate={nowDate} onClose={() => setOpenModalOpen(false)} openStore={openStore} />}
        {closeModalOpen && <CloseModal nowDetailDate={nowDetailDate} logout={logout} onClose={() => setCloseModalOpen(false)} />}
        <Box
          width='90%'
          textColor='white'
          position='relative'
          align='left'
          top='5vh'
        >
          <Text
            className='storeMainText'>
            개점처리
          </Text>
        </Box>
        <Flex
          className='storeCloseBox sub-head-btn-background-color'
          top='5vh'
          // backgroundColor='rgba(183, 242, 252, 1)'
          position='relative'
          borderRadius='0'
          shadow={shadow}
          direction='column'
          align='left'

        >
          <Text
            className='storeCloseBoxMainText'
            mr='auto'
          >
            마감현황
          </Text>
          <Flex
            className='storeCloseBoxInsideText'
            mt='2%'
            direction='column'
          >
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />
              <Text>
                직전 영업일자
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {closeOpenDate ? closeOpenDate : '자료가 없습니다.'}
              </Text>
            </Flex>

            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />
              <Text>
                마감 처리시간
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {closeDate ? closeDate : '자료가 없습니다.'}
              </Text>
            </Flex>

            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />
              <Text>
                마감 점원
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {closeManager ? closeManager : '자료가 없습니다.'}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Box
          className='storeOpenBox'
          top='10vh'
          backgroundColor='rgba(255, 255, 255, 1)'
          position='relative'
          borderRadius='0'
          shadow={shadow}
          direction='column'
          align='left'
        >
          <Text
            className='storeOpenBoxMainText'
          >
            개점처리
          </Text>

          <Flex
            className='storeCloseBoxInsideText'
            mt='2%'
            direction='column'
          >

            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />
              <Text isTruncated>
                시스템 일자
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {openDate ? openDate : '2024-01-01(일)'}
              </Text>
            </Flex>

            <Flex
              align='start'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />

              <Text isTruncated>
                개점 점원
              </Text>

              <Text>
                :
              </Text>
              <Input
                ml='auto'
                className={`storeOpenBoxInput ${error ? 'alertText' : ''}`}
                placeholder='개점 직원'
                list={'managerList'}
                id="openManager"
                name="openManager"
                value={openManager}
                //onChange={onChange}
                autoComplete="off"
              />

            </Flex>

            <Flex
              align='start'
            >
              <Icon as={BsCircleFill} className='storeCloseBoxDot' />
              <Text isTruncated>
                개점 영업일자
              </Text>
            </Flex>

          </Flex>
          <Box
            mx='auto'
            align='center'
          >
            <DatePicker
              className="datePicker"
              dateFormat='yyyy-MM-dd(eee)' // 날짜 형태
              shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
              minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
              maxDate={new Date()} // maxDate 이후 날짜 선택 불가
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              locale={ko}
              withPortal
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </Box>
        </Box>






        <Flex
          justifyContent='space-evenly'
          position='fixed'
          top='90vh'
          width='100%'
        >
          <Button
            border='1px solid'
            borderColor='#C1B9B9'
            borderRadius='0px'
            width='40%'
            className="mainBtnText"
            onClick={() => { setCloseModalOpen(true) }}
          >
            종료
          </Button>
          <Button
            border='1px solid'
            borderColor='#C1B9B9'
            borderRadius='0px'
            width='40%'
            className="mainBtnText"
            onClick={() => { openModalCheck() }}
          >
            개점처리
          </Button>
        </Flex>

      </Box>
    </>

  );
}
