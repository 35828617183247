// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Flex, Icon, FormLabel, Box, Input, Text, useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent } from "@chakra-ui/react";
// Components
import { BsXLg, BsCircleFill } from "react-icons/bs";
import DiscountAddModal from "./DiscountAddModal";
import DiscountUpdateModal from "./DiscountUpdateModal";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function DiscountModal({ setReloadDiscount, onClose, discountList, setDiscountInfo, discountInfo, totalDiscountAmount, totalDiscountPercent }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return (
    <>
      {addModalOpen && <DiscountAddModal setReloadDiscount={setReloadDiscount} onClose={() => { setAddModalOpen(false) }} />}
      {updateModalOpen && <DiscountUpdateModal setReloadDiscount={setReloadDiscount} onClose={() => { setUpdateModalOpen(false) }} discountInfo={discountInfo} />}
      <div className='modal'>
        <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
          <ModalContent
            className='sub-modal-background-color'
            my='auto'
            border='1px solid'
            borderColor='black'
            borderRadius='0px'
            // backgroundColor='rgba(229, 244, 255, 1)'
            backgroundRepeat='no-repeat'
            bgSize='cover'
            bgPosition='10%'
            textColor='black'
            p='0% 1% 3% 1%'
          >
            <Flex>
              <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
            </Flex>

            <Flex
              className='modalStoreConfirmMainText'
              align='center'
              direction='column'
            >
              <Text>
                [할인정보]
              </Text>
            </Flex>
            <Flex direction='column'>
              <Text>
                전체 할인 금액 : {totalDiscountAmount} 원
              </Text>
              <Text>
                전체 할인율 : {totalDiscountPercent} %
              </Text>
            </Flex>
            <Flex
              className='modalAddManagerColumnText'
              direction='column'
            >
              {
                discountList && discountList.length > 0 ?
                  discountList.map((item, index) => (
                    <Box
                      // backgroundColor='white'
                      backgroundColor="rgba(0, 140, 255, 0.2) !important"
                      my='1%'
                      borderRadius='5'
                      alignSelf='center'
                      mx='auto'
                      width='100%'
                      key={index}
                      isTruncated
                      shadow={shadow}
                      onClick={() => {
                        setUpdateModalOpen(true);
                        setDiscountInfo(item);
                      }}
                    >
                      <Flex justify='space-evenly'>
                        <Text>
                          {item.comment}
                        </Text>
                        {
                          item.discount_amount ?
                            <Text>
                              {item.discount_amount}원 할인
                            </Text>
                            :
                            <Text>
                              {item.discount_percent}% 할인
                            </Text>
                        }
                      </Flex>
                    </Box>
                  ))

                  :
                  <Text align='center'>
                    할인 정보가 없습니다.
                  </Text>
              }
            </Flex>

            <Button

              onClick={() => setAddModalOpen(true)}
              className="modalMainSmallBtn main-btn-color"
              textColor='white'
              align='center'
              mx='auto'
              mt='20px'
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                내역 추가

              </Text>
            </Button>
          </ModalContent>
        </Modal>
      </div >
    </>
  );
}