export const columnsDataTransaction = [
   {
    Header: "주문 번호",
    accessor: "order_number",
  },

  {
    Header: "거래자",
    accessor: "sender",
  },
  {
    Header: "금액(VETA)",
    accessor: "cost_of_goods_sold_amount",
  },
  {
    Header: "정산",
    accessor: "settlement",
  }
];
