// React
import React, { useMemo } from "react";
// Components
// Assets
import TransactionTableContent from "./TransactionTableContent.js";
import { columnsDataCalculation } from "../../../../../../variables/calculation/columnsDataCalculation";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTable({ calculationList,  userInfo,settleMode,checkedItems ,handleCheckboxChange,settleList,isAllChecked,handleSelectAllChange, settleFinishAmount,setCalculationInfo,setCalculationDetailModalOpen}) {
    const columns = useMemo(() => columnsDataCalculation, [columnsDataCalculation]);
    const tableData = useMemo(() => calculationList, [calculationList]);
    return (
        <>
            <TransactionTableContent columns={columns} dFlag={4}  data={tableData} userInfo={userInfo} settleMode={settleMode} 
                    handleCheckboxChange={handleCheckboxChange}settleList={settleList} checkedItems={checkedItems}
                    isAllChecked={isAllChecked} handleSelectAllChange={handleSelectAllChange}
                    settleFinishAmount={settleFinishAmount} setCalculationInfo={setCalculationInfo}
                    setCalculationDetailModalOpen={setCalculationDetailModalOpen}
                    />
        </>
    );
}
