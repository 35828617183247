// React
import React from 'react';
// External Libraries
import { Flex,  Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../../../css/Page.css';
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function ClosingFinishModal({ onClose, closeAndLogout, logout }) {
  // Variables for Card CSS Styling

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='modalMainClose sub-modal-background-color'
          border='1px solid'
          borderColor='white'
          borderRadius='0px'
          // backgroundColor='rgba(192, 223, 244, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          h="50vh !important"
          // w="50vh !important"
        >
          <Flex
            my='auto'
            align='center'
            direction='column'
          >
            <Text
              className='modalMainCloseText'
            >
              마감되었습니다.
            </Text>
          </Flex>
          {
            closeAndLogout ?
              <Button
                mx='auto'
                mb='2.5vh'
                shadow={shadow}
                // backgroundColor='rgba(22, 204, 234, 1)'
                border='1px solid rgba(217, 217, 217, 1)'
                className='modalMainSmallBtn main-btn-color'
                textColor='black'
                align='center'
                onClick={() => { logout() }}
              >
                <Text
                  mx='auto'
                  color='white'
                  className='modalMainSmallBtnText'
                >
                  확인
                </Text>
              </Button>
              :
              <Button
                mx='auto'
                mb='2.5vh'
                shadow={shadow}
                // backgroundColor='rgba(22, 204, 234, 1)'
                border='1px solid rgba(217, 217, 217, 1)'
                className='modalMainSmallBtn main-btn-color'
                textColor='black'
                align='center'
                onClick={() => { onClose() }}
              >
                <Text
                  mx='auto'
                  color='white'
                  className='modalMainSmallBtnText'
                >
                  확인
                </Text>
              </Button>
          }

        </ModalContent>
      </Modal>
    </div>
  );
}