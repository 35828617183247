// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, FormControl, Flex, Icon, FormLabel, Box, Input, Text, useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent } from "@chakra-ui/react";
// Components
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function CategoryAddModal({ onClose, setUploadItem, bigCategoryList }) {
  // Variables for Card CSS Styling
  const formData = new FormData();
  const [readOnly, setReadOnly] = useState(false);
  const [openProfile, setOpenProfile] = useState(true);
  const [profileOption] = useState(true);
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [profileImage, setProfileImage] = useState();
  const [inputColor, setInputColor] = useState("none");
  const [alertText, setAlertText] = useState("");
  const [preview, setPreview] = useState("");

  // input data variables
  const [inputs, setInputs] = useState({
    category_name: "",
    category_class: "",
    category_image: "",
  });
  const { category_name, category_class, category_image } = inputs;
  const [categoryBigId, setCategoryBigId] = useState('');
  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    if (e.target.name === 'category_big_class' && category_class === '소분류') {
      classCheck(e.target.value);
    }
  }
  function classCheck(value) {
    bigCategoryList.forEach(cat => {
      if (cat.category_big_name === value) {
        setCategoryBigId(cat.category_big_id);
      }
    })
  }


  const onChangeImage = (fileBlob) => {
    setAlertText("이미지를 업로드하세요. 10MB이하만 가능합니다.");
    setInputColor('none');

    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);

    setProfileImage(fileBlob);

    // 파일 유형 체크
    if (fileBlob && fileBlob.type && !fileBlob.type.match('image/png', 'image/jpg', 'image/jpeg')) {
      setAlertText('Only image files are allowed.');
      return;
    }

    // 파일 크기 체크 (10MB)
    if (fileBlob.size > 10 * 1024 * 1024) {
      setAlertText('File size should be 10MB or less.');
      return;
    }
    setAlertText("");

    return new Promise((resolve) => {
      reader.onload = () => {
        setPreview(reader.result);
        resolve();
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (profileImage) {
      debugger;
      formData.append('category_image', profileImage);
    }
    formData.append('category_name', category_name);
    formData.append('category_class', 'big');
    formData.append('category_big_id', 'No');

    axios.post('/api/pos/product/regCategory', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
      .then((response) => {             // api call success
        if (response.data.status === '1') {
          alert("등록이 완료되었습니다.");
          setUploadItem(true);
          onClose();
        } else if (response.data.status === '0') {
          alert(response.data.error);
        }
      }).catch(error => {

        alert("다시 시도해주세요.");
        console.log(error);
      })
  }// fn handleSubmit end

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalAddManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='0% 1% 3% 1%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            mb={{ base: '5%', lg: '3%' }}
            align='center'
            direction='column'
          >
            <Text>
              [카테고리 추가]
            </Text>
          </Flex>
          <FormControl>
            <Flex
              className='modalAddManagerColumnText'
              direction='row'
              // direction="column"
              justifyContent="space-between" // Added to space out the left and right sections
              alignItems="flex-start"
            >
     
              <Flex direction="column" width="65%" mr="3px">
                <Flex justify="space-around" mb="10px">
                  <Text
                    isTruncated
                    className='modalCalculationMiddlePlusMarginBottom modalStoreAddManagerTextHeight'
                  >
                    이름
                  </Text>
                  <Input
                    className='modalStoreAddManagerTextHeight modalStoreAddManagerInput modalCalculationMiddlePlusMarginBottom modalInputPlaceholder'
                    isRequired={true}
                    variant='auth'
                    type='text'
                    backgroundColor='rgba(255,255,255,0.9)'
                    borderRadius='0px'
                    autoComplete="off"
                    placeholder='항목명'
                    id="category_name"
                    name="category_name"
                    value={category_name}
                    onChange={onChange}
                  />
                </Flex>
                <Flex justify="space-around" mb="10px">
                  <Text
                    isTruncated
                    className='modalCalculationMiddlePlusMarginBottom modalStoreAddManagerTextHeight'
                  >
                    사진
                  </Text>
                  <Input
                    backgroundColor='white'
                    className='modalStoreAddManagerTextHeight modalStoreAddManagerInput modalCalculationMiddlePlusMarginBottom modalInputPlaceholder'
                    isRequired={true}
                    type='file'
                    fontWeight='400'
                    autoComplete='off'
                    id="category_image"
                    name="category_image"
                    accept="image/*"
                    placeholder="파일을 선택해주세요"
                    onChange={(e) => {
                      onChangeImage(e.target.files[0]);
                    }}
                    readOnly={readOnly}
                    isInvalid
                    errorBorderColor={inputColor}
                  />
                </Flex>
              </Flex>
              <Flex  width='50%' minH="15vw" maxH='50vw' justifyContent="flex-end">
                <Box  width="100%"  border="1px solid gray">
                  {openProfile ? (
                    <Box  border='1px solid #E7EAF3' p={0} overflow='hidden' align='center'>
                      <div className="preview">
                        {preview && <Image objectFit="contain" maxH='50vw' src={preview} alt="preview-img" />}
                      </div>
                    </Box>
                  ) : (
                    category_image && profileOption && (
                      <Box  border='1px solid #E7EAF3' p={0} overflow='hidden' align='center'>
                        <Image objectFit="contain" maxH='50vw' src={`data:image/*;base64,${category_image}`} alt='' />
                      </Box>
                    )
                  )}
                </Box>
              </Flex>
            </Flex>

          </FormControl>
          <Button
            className='modalMainSmallBtn main-btn-color'
            // position='absolute'
            mx='auto'
            mt='20px'
            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            // top='90%'
            // left='32.5%'
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={(e) => { handleSubmit(e) }}
          >
            < Text
              className='modalMainSmallBtnText'
              mx='auto'
            >
              확인
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div >
  );
}