// React
import React from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { Flex, Box, Text, useColorModeValue, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
// Assets
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTableContent({ columns, data, cFlag, dFlag, userInfo, setTransactionDetailModalOpen, setTransactionInfo }) {
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy
    );
    var noDataText = (dFlag === 0) ? 'No Data Available' : '';
    var noDataMargin = (dFlag === 0) ? '70px' : '0px';

    return (

        <Box
            borderRadius='0'
            h='100%'
            // overflowX="auto"
            w='100%'
            overflowY="auto"
            className='table-box viewCard'>
            <Table whiteSpace='nowrap' position='relative'>
                <Thead position='sticky' top='0' backgroundColor='white' zIndex='1' align='center' >
                    {headerGroups.map((headerGroup, index) => (
                        <Tr key={index}
                            // backgroundColor='#007799'
                            className="tableHead table-backgroud-header-footer"
                        >
                            {headerGroup.headers.map((column, index) => (
                                // Exclude the column with the name "cFlag"
                                // column.render("Header") === '상태'
                                //     ? <Th
                                //         p='2%'
                                //         key={index}
                                //         borderColor={borderColor}>
                                //         <Text
                                //             className="tableHeadText"
                                //             align='center'
                                //             color='white'>
                                //             {column.render("Header")}
                                //         </Text>
                                //     </Th>
                                //     :
                                column.render("Header") === '주문 번호'
                                    ? <Th
                                        p='2%'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Text
                                            className="tableHeadText"
                                            align='center'
                                            color='white'>
                                            {column.render("Header")}
                                        </Text>
                                    </Th>
                                    :
                                    column.render("Header") === '거래자'
                                        ? <Th
                                            p='2%'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHeadText"
                                                align='center'
                                                color='white'>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                        :
                                        column.render("Header") === '금액(VETA)'
                                            ? <Th
                                                p='2%'
                                                key={index}
                                                borderColor={borderColor}>
                                                <Text
                                                    className="tableHeadText"
                                                    align='center'
                                                    color='white'>
                                                    {column.render("Header")}
                                                </Text>
                                            </Th>
                                            :
                                            <Th
                                                p='2%'
                                                key={index}
                                                borderColor={borderColor}>
                                                <Text
                                                    className="tableHeadText"
                                                    align='center'
                                                    color='white'>
                                                    {column.render("Header")}
                                                </Text>
                                            </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()} >
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr className="tableBody tableBodyText" p='1px' {...row.getRowProps()} key={index} borderBottom='2px solid' borderColor='gray.200'
                                onClick={() => {
                                    setTransactionInfo(row.original);
                                    setTransactionDetailModalOpen(true);
                                }}
                            >
                                {row.cells.splice(0, dFlag).map((cell, index) => {

                                    // Variable for each <Td> in ItemTable
                                    let data = "";

                                    // if (cell.column.Header === "상태") {

                                    //     data = (
                                    //         <Text color={textColor} >
                                    //             {(cell.value)}
                                    //         </Text>
                                    //     );

                                    // } else 
                                    if (cell.column.Header === "주문 번호") {
                                        data = (
                                            <Text color={textColor} align='center' className="tableBodyBigText">
                                                {(cell.value)}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "거래자") {
                                        data = (
                                            <Text color={textColor} align='center' className="tableBodyBigText">
                                                {cell.value}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "금액(VETA)") {
                                        data = (
                                            <Text color={"black"} align='center' className="tableBodyBigBigText">
                                                {cell.value ? (cell.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0 : 0}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "정산") {
                                        data = (
                                            <Flex direction='column' pr='2%' >
                                                <Text color={"black"} align='center' className="tableBodyBigBigText">
                                                    {cell.value === 0 ?
                                                        "정산가능"
                                                        :
                                                        cell.value === 2 ?
                                                            "주문취소"
                                                            :
                                                            cell.value === 1 ?

                                                                "정산완료"
                                                                :
                                                                "정산가능"
                                                    }
                                                </Text>

                                            </Flex>
                                        );
                                    }
                                    return (
                                        <Td
                                            p='1%'
                                            {...cell.getCellProps()}
                                            key={index}
                                            className="tableBody "
                                            // maxH='30px !important'
                                            // minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        )
                    }
                    )}
                </Tbody>
            </Table>
            <Text color={textColor} fontSize='xl' fontWeight='500' align='center' justify='center' my={noDataMargin}>
                {noDataText}
            </Text>
        </Box>
    );
}
