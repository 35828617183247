import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import './BackOfficeFullCalendar.css';  // Add this line to import your CSS file

function BackOfficeFullCalendar({ monthlyInfo }) {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [totalSales, setTotalSales] = useState(0);  // 총 매출액
  const [mostFrequentMenus, setMostFrequentMenus] = useState([]);  // 공동 1등을 포함한 1st 메뉴 배열
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);  // 현재 달 저장

  useEffect(() => {
    if (Array.isArray(monthlyInfo) && monthlyInfo.length > 0) {
      const events = monthlyInfo.map(info => ({
        title: info.title,
        start: info.date
      }));
      setCurrentEvents(events);

      // 매출액 합산 및 1st 메뉴 중 가장 많은 항목 계산
      let salesSum = 0;
      const menuCount = {};

      monthlyInfo.forEach(info => {
        const date = new Date(info.date);
        if (date.getMonth() + 1 === currentMonth) {  // 현재 달에 해당하는 데이터만 처리
          if (info.title.includes('매출액')) {
            const sales = parseInt(info.title.replace(/[^0-9]/g, ''), 10);  // 숫자만 추출 후 합산
            salesSum += sales;
          }

          /*
          if (info.title.includes('1st 메뉴')) {
            const menuTitle = info.title.replace('1st 메뉴', '').trim();  // '1st 메뉴'라는 텍스트를 제거하고 메뉴 이름 추출
            if (menuTitle) {  // 빈 문자열이 아닌 경우만 처리
              menuCount[menuTitle] = (menuCount[menuTitle] || 0) + 1;  // 메뉴 카운트
            }
          }
*/
        }
      });

      // 가장 자주 나온 메뉴의 빈도 찾기
      const maxCount = Math.max(...Object.values(menuCount));

      // 해당 빈도를 가진 모든 메뉴를 배열로 저장
      const frequentMenus = Object.keys(menuCount).filter(menu => menuCount[menu] === maxCount);

      setTotalSales(salesSum);
      setMostFrequentMenus(frequentMenus);  // 공동 1등 메뉴들을 배열로 저장
    } else {
      setCurrentEvents([]);
    }
  }, [monthlyInfo, currentMonth]);  // currentMonth가 변경될 때마다 계산

  function handleWeekendsToggle() {
    setWeekendsVisible(!weekendsVisible);
  }

  return (
    <div className='demo-app'>

      <div className="summary" style={{marginBottom:"3px"}}>
        <text>
          <span style={{ fontWeight: '900' }}>{currentMonth}</span> <span style={{ fontWeight: '600' }}>월 총 매출액: </span>
          <span style={{ fontWeight: '900' ,color:"#FF8339"}}> {totalSales.toLocaleString()}</span> 원
        </text>
        {/*
        <br/>
         <text>
          메뉴: 
          <span style={{ fontWeight: '900' }}>
             {mostFrequentMenus.length > 0
              ? mostFrequentMenus.join(', ')  // 공동 1등 메뉴들을 쉼표로 구분해서 출력
              : '없다'}
          </span>
        </text> */}
      </div>

      <div className='demo-app-main'>
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            left: 'prev',
            center: 'title', // 중앙에 title을 설정
            right: 'next'
          }}
          initialView='dayGridMonth'
          titleFormat={{ year: 'numeric', month: 'numeric' }}  // titleFormat을 비활성화해도 됩니다.
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={false}  // 한 날짜에 표시할 수 있는 최대 이벤트 수를 해제
          dayMaxEventRows={false}  // 한 날짜에 표시할 수 있는 최대 줄 수를 해제
          weekends={weekendsVisible}
          events={currentEvents}
          datesSet={function (info) {
            // 타이틀에 한글 추가
            const calendarApi = info.view.calendar;
            const currentDate = calendarApi.getDate();
            // const koreanFormattedTitle = `${currentDate.getFullYear()}년 ${currentDate.getMonth() + 1}월` + " //";
            const koreanFormattedTitle = "";
            setCurrentMonth(currentDate.getMonth() + 1);  // 현재 달을 업데이트하여 매출액 및 메뉴 계산

            // 타이틀을 매번 덮어쓰기 위해 다시 설정
            const toolbarTitleElement = document.querySelector('.fc-toolbar-title');
            if (toolbarTitleElement) {
              toolbarTitleElement.innerText = koreanFormattedTitle;
            }
          }}
          eventContent={renderEventContent}
        />
      </div>
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default BackOfficeFullCalendar;
