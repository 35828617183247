// React
import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
// Components
import receipt from "../../../../../../assets/img/service/Sattle_Receipt.gif";


/* global BigInt */

export default function Loading() {

    return (

        <Box
            width='100vw'
            height='100vh'
            top='0vh'
            zIndex='9999'
            align='center'
            position='absolute'
            backgroundColor='rgba(39, 134, 178, 1)'
        >
            <Box
                mt='60%'
            >
                <Text
                    className="txLoadingText"
                    color='white'
                >
                    마감 정산서 발급 중...
                </Text>
                <Image src={receipt} className="txLoadingImage" />

            </Box>
        </Box>
    );
}
