// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Flex, FormControl, FormLabel, Box, Input, Text, Icon, useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
// Components
// Assets
import '../../../../../../css/Switch.css';
import Card from "../../../../../../components/card/Card";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function OptionDetailModal({ 
  onClose, optionInfo ,openCreateModal,
  bgHover3,bgFocus3

}) {

  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const formData = new FormData();
  const [inputs, setInputs] = useState({
    item_name: "",
    price: "",
    item_information: "",
    item_image: "",
    stock: "",
    category: "",
    big_category: ""
  });
  const { item_name, price, item_information, item_image, stock, category, big_category } = inputs;
  function onChange(e) {
    // focus input value
    const { value, name } = e.target;
    // inputs value change
    setInputs({
      ...inputs,
      [name]: value,
    });
  } // fn onChange end



  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalUpdateManager'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          backgroundColor='#E8EDED'
          textColor='black'
        // p='2%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>
          <Flex
            className='modalUpdateManagerTitleText'
            align='center'
            direction='column'
            mb='10px'
          >
            <Text>
              [ 옵션 그룹 관리 ]
            </Text>
          </Flex>
          <Flex
            width='90%'
            mx='auto'
            direction='column'
            height='100%'
          >
            <Card mb='20px'
            >
              <Flex
                direction='column'
                
              >
                <Flex m="1%">
                  <Text>옵션 그룹 이름 : </Text>
                  <Text fontWeight="1000" ml="2%">
                    {optionInfo.option_group_name}
                  </Text>
                </Flex >
                <Flex m="1%">
                  <Text>옵션 그룹 종류 : </Text>
                  <Text fontWeight="1000" ml="2%">
                    {optionInfo.option_group_type === 'required' ? "필수 선택" : "선택 옵션"}
                  </Text>
                </Flex>

                <Flex justify="space-between"
                maxH="30vh"
                
                p="2%"
                >
                  <Flex
                    direction="row"
                    w="100%"
                    flexWrap="wrap"
                    overflowY="auto"
                  >

                    {optionInfo.option_list &&
                      optionInfo.option_list.map((listOptions, indexOption) => (
                        <Card key={indexOption} shadow="md" m="2%" p="10px" borderRadius="12px"
                         data-add-option="true"
                        >
                          <Flex direction="row" align="center" justify="space-between" h="100%">
                            <Flex direction="column">
                              <Text>{listOptions.option_name}</Text>
                              {listOptions.additional_price ? (
                                <Text align="center">+{listOptions.additional_price} 원</Text>
                              ) : null}
                            </Flex>
                            <Text shadow="md" p="6px" className="main-btn-color" borderRadius="12px" color="white"
                              _hover={bgHover3} _focus={bgFocus3} _active={bgFocus3}
                              data-add-option="true"
                              onClick={()=>{alert("준비중입니다.")}}
                            >
                              변경하기
                            </Text>
                          </Flex>
                        </Card>
                      ))
                    }

<Card shadow="md" m="2%" p="10px" borderRadius="12px"
                          _hover={bgHover3} _focus={bgFocus3} _active={bgFocus3}
                          minW="20%"
                          onClick={() => {
                            openCreateModal(optionInfo);
                          }}
                          data-add-option="true"
                          >
                      <Flex
                        justify="center"
                        align="center"
                        h="100%"

                      // id="addOption"  
                      >
                        <Text>+ 추가하기</Text>
                      </Flex>
                    </Card>
                  </Flex>
                </Flex>




              </Flex>
            </Card>


          </Flex>

        </ModalContent>
      </Modal >
    </div >
  );
}
