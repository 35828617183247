// React
import React from "react";
import { Flex, Box, Button,Image } from "@chakra-ui/react";
// Assets
import gradient3 from "../../../../../../assets/img/service/gradient3.jpeg";
import { BsPencilSquare } from "react-icons/bs";
// drag and drop
import { useDrag, useDrop } from 'react-dnd';
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function DragComponent({ setBigCategoryInfo, bigCategoryList, setCategoryBigUpdateModalOpen, setBigCategoryList, selectedBigCategory, handleBigCategoryClick, index, category }) {

    // drag and drop 

    const ItemTypes = {
        CATEGORY: 'category',
    };
    const moveCategory = (fromIndex, toIndex) => {
        const updatedList = Array.from(bigCategoryList);
        const [movedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, movedItem);
        updatedList.forEach((category, index) => {
            category.category_primary = index + 1;
        });
        setBigCategoryList(updatedList);
    };


    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CATEGORY,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCategory(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CATEGORY,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (

        category.category_big_id === selectedBigCategory.category_big_id ?
            <>
                <Flex
                    ref={ref}
                    align='center'
                    backgroundColor='rgba(70, 209, 232, 1)'
                    bgSize='cover'
                    className="bigCategory"
                    onClick={() => handleBigCategoryClick(category)}
                    style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
                >
                    <Box
                        textColor='black'
                        direction='column'
                        width='100%'
                        height='100%'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        color='white'
                        textOverflow='ellipsis'
                    >
                        {
                            category.image ?

                                <Image style={{ padding: '20px' }} src={`data:image/*;base64,${category.image}`} alt='' className='storeItemImage' />
                                :
                                null
                        }
                        {category.category_big_name}
                    </Box>
                    <Button
                        as={BsPencilSquare}
                        className="bigCategoryUpdBtn"
                        color='red'
                        size="100%"
                        backgroundColor='rgba(0,0,0,0)'
                        _hover={{ backgroundColor: 'rgba(0,0,0,0)' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setCategoryBigUpdateModalOpen(true);
                            setBigCategoryInfo(category);
                        }} />
                </Flex>
            </>
            :
            <>
                <Flex
                    ref={ref}
                    align='center'
                    backgroundImage={gradient3}
                    bgSize='cover'
                    className="bigCategory"
                    onClick={() => handleBigCategoryClick(category)}
                    style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
                >
                    <Box
                        textColor='black'
                        direction='column'
                        width='100%'
                        height='100%'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow='ellipsis'
                    >
                        {
                            category.image ?

                                <Image style={{ padding: '20px' }} src={`data:image/*;base64,${category.image}`} alt='' className='storeItemImage' />
                                :
                                null
                        }
                        {category.category_big_name}
                    </Box>
                    <Button
                        as={BsPencilSquare}
                        className="bigCategoryUpdBtn"
                        color='red'
                        size="100%"
                        backgroundColor='rgba(0,0,0,0)'
                        _hover={{ backgroundColor: 'rgba(0,0,0,0)' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setCategoryBigUpdateModalOpen(true);
                            setBigCategoryInfo(category);
                        }} />
                </Flex>
            </>
    );
}
