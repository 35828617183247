// React
import React, { useState, useEffect } from "react";
import { Flex, Box, Image, Text, Button, useColorModeValue } from "@chakra-ui/react";
import axios from 'axios';
// Components

import main_result from "../../../../../assets/img/service/main_result.png";
import home from "../../../../../assets/img/service/home.png";
import TransactionTable from "./table/TransactionTable";
import CalculationModal from "./modal/CalculationModal";
import CalculationDetailModal from "./modal/CalculationDetailModal";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function CalculationCard({ userInfo, setNowComponent, transactionDaliyList, formattedMonth,setViewComponent}) {

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const [CalculationModalOpen, setCalculationModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [calculationDetailModal, setCalculationDetailModalOpen] = useState(false);

    const [calculationList, setCalculationList] = useState([]);
    const [calculationInfo, setCalculationInfo] = useState();
    const [calculationDetailInfo, setCalculationDetailInfo] = useState([]);

    const [totalAmount, setTotalAmount] = useState(0);
    const [settleInfo, setSettleInfo] = useState();

    const [settleMode, setSettleMode] = useState(false);
    // checkbox
    const [settleList, setSettleList] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [settleAmount, setSettleAmount] = useState(0);
    const [isAllChecked, setIsAllChecked] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(true);
    const [settleFinishAmount, setSettleFinishAmount] = useState(0);
    const [settleOk, setSettleOk] = useState(false);

    const calculateDay = ['요청일 + 영업 30일 지급', '요청일 + 영업 20일 지급', '요청일 + 영업 15일 지급', '요청일 + 영업 7일 지급', '요청일 + 영업 3일 지급', '요청일 + 영업 1일 지급', '요청일 + 요청 당일 지급 (4시 이전 신청)'];

    // input data variables
    const [inputs, setInputs] = useState({
        resultDay: "",
    });
    const { resultDay } = inputs;
    // When the user enters data, the value is changed to the entered value.      
    function onChange(e) {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    }

    function transactionRequest() {
        setConfirmModalOpen(false);
        setCalculationModalOpen(false);
    }
    function confirmModal() {
        setConfirmModalOpen(true);
    }

    useEffect(() => {
        var cal = [];
        setSettleFinishAmount(0);
        var amount = 0;
        var count = 0;
        setSettleMode(false);
        axios.post("/api/pos/product/calculation", {
            month: formattedMonth
        })
            .then((response) => {
                for (var i = 0; i < response.data.calculation.length; i++) {
                    cal.push({
                        "date": response.data.calculation[i].date,
                        "open_date": response.data.calculation[i].open_date,
                        "open_manager": response.data.calculation[i].open_manager,
                        "close_date": response.data.calculation[i].close_date,
                        "close_open_date": response.data.calculation[i].close_open_date,
                        "close_manager": response.data.calculation[i].close_manager,
                        "today_sales": response.data.calculation[i].today_sales,
                        "settle": response.data.calculation[i].settle,
                        "settle_time": response.data.calculation[i].settle_time,
                        "settle_comment": response.data.calculation[i].settle_comment
                    })
                    if (response.data.calculation[i].settle === '0') {
                        count++;
                        amount += Number(response.data.calculation[i].today_sales);
                    }
                }
                setSettleFinishAmount(count);
                setTotalAmount(amount);
                setCalculationList(cal);
                setSettleInfo({
                    "settle_number": response.data.settle.settle_number,
                    "settle_name": response.data.settle.settle_name,
                    "settle_owner": response.data.settle.settle_owner
                })
            })
            .catch((error) => {
                // alert(error);
            })
        if (settleOk) {
            setSettleOk(false);
        }

    }, [settleOk, formattedMonth])
    const handleSelectAllChange = () => {
        const newCheckedItems = {};
        let newSettleList = [];
        if (!isAllChecked) {
            calculationList.forEach((item) => {
                if (item.settle !== '1') {
                    newCheckedItems[item.date] = true;
                    newSettleList.push(item);
                }
            });
        }
        setCheckedItems(newCheckedItems);
        setSettleList(newSettleList);
        setIsAllChecked(!isAllChecked);
    };
    const handleCheckboxChange = (date) => {
        setCheckedItems((prevState) => {
            const newCheckedItems = { ...prevState, [date]: !prevState[date] };

            if (newCheckedItems[date]) {
                const item = calculationList.find(data => data.date === date);
                setSettleList((prevList) => [...prevList, item]);
            } else {
                setSettleList((prevList) => prevList.filter((item) => item.date !== date));
            }
            // 전체 선택 체크박스 상태 업데이트
            // const allChecked = calculationList.every(row => newCheckedItems[row.date]);
            const allChecked = calculationList.every(row => {
                if (row.settle !== '1') {
                    return newCheckedItems[row.date] === true;
                }
                return true;
            });
            setIsAllChecked(allChecked);
            return newCheckedItems;
        });
    };
    useEffect(() => {
        let totalAmount = 0;
        settleList.forEach(item => {
            totalAmount += Number(item.today_sales);
        });
        setSettleAmount(totalAmount);
    }, [settleList]);
    function settleCancle() {
        setSettleMode(false);
        setCheckedItems({});
        setIsAllChecked(false);
        setSettleList([]);
    }

    function settleReq() {
        if (settleMode && settleList.length > 0) {
            setCalculationModalOpen(true);
        } else {
            setSettleMode(true);
        }
    }
    function settlement(e) {
        e.preventDefault();
        setSubmitLoading(false);
        axios.post('/api/store/settle', {
            settleList: settleList,
            settleDay: "5 영업일 이내 지급됩니다.",
            settleAmount: settleAmount
        })
            .then((response) => {
                if (response.data.error) {
                    // console.log(response.data.error);
                    alert("다시 시도해주세요.");
                    setSubmitLoading(true);
                } else {
                    alert("접수 완료됐습니다.");
                    transactionRequest();
                    setSettleOk(true);
                    setSubmitLoading(true);
                }
            })
            .catch((error) => {
                // alert(error);
                setSubmitLoading(true);
            })
    }

    useEffect(() => {
        if (calculationInfo) {
            var detail = [];
            axios.post('/api/pos/product/settleInfo', {
                date: calculationInfo.date,
            })
                .then((response) => {
                    for (var i = 0; i < response.data.detail.length; i++) {
                        detail.push({
                            "order_number": response.data.detail[i].order_number,
                            "transaction_hash": response.data.detail[i].transaction_hash,
                            "email": response.data.detail[i].email,
                            "store_address": response.data.detail[i].store_address,
                            "order_reg": response.data.detail[i].order_reg,
                            "transaction_status": response.data.detail[i].transaction_status,
                            "cost_of_goods_sold_amount": response.data.detail[i].ca,
                            "refund_amount": response.data.detail[i].ra,
                            "fee_amount": response.data.detail[i].fa,
                            "settlement": response.data.detail[i].settlement
                        })
                    }
                    setCalculationDetailInfo(detail);
                })
                .catch((error) => {
                    // alert(error);
                })
        }
    }, [calculationInfo])


    return (
        <>
            {CalculationModalOpen && <CalculationModal submitLoading={submitLoading} settlement={settlement} formattedMonth={formattedMonth} totalAmount={totalAmount} settleAmount={settleAmount} transactionDaliyList={transactionDaliyList} userInfo={userInfo} onChange={onChange} calculateDay={calculateDay} resultDay={resultDay} onClose={() => setCalculationModalOpen(false)} confirmModal={confirmModal} settleInfo={settleInfo} />}
            {calculationDetailModal && <CalculationDetailModal calculationDetailInfo={calculationDetailInfo} calculationInfo={calculationInfo} onClose={() => setCalculationDetailModalOpen(false)} />}

            <Flex
                className="txHomeImageAndText">
                <Image src={main_result} className="mainmenuImage" />
                <Text
                    className="mainmenuText main-text-color"
                    // color='rgba(2, 58, 142, 1)'
                >
                    정산관리
                </Text>
                
                <Button
                    // className="mainmenuText main-text-color"
                    // color='rgba(2, 58, 142, 1)'
                    mt="auto"
                    onClick={() => setViewComponent('BackOffice')}
                >
                    매출 현황 
                </Button>
                {/* viewComponent === 'BackOffice' */}
                <Image src={home} className="mainmenuHome" ml='auto' onClick={() => { setNowComponent('') }} />
            </Flex>
            <Box
                className="sub-background-color menuBox"
                overflowY='auto'
                borderTop='1px solid white'
                borderRadius='20px 20px 0px 0px'
                zIndex='2'
                height='100%'
                width='100%'
                // backgroundColor='rgba(100,200,220,1)'

            >
                <Box
                    mx='auto'
                    className="calculationTableBox"
                    backgroundColor='white'
                >
                    <TransactionTable settleMode={settleMode} calculationList={calculationList} userInfo={userInfo}
                        settleList={settleList} checkedItems={checkedItems}
                        isAllChecked={isAllChecked} handleSelectAllChange={handleSelectAllChange}
                        handleCheckboxChange={handleCheckboxChange}
                        settleFinishAmount={settleFinishAmount}
                        setCalculationInfo={setCalculationInfo}
                        setCalculationDetailModalOpen={setCalculationDetailModalOpen}
                    />
                </Box>


                <Box
                    className="saleTableBottomBox table-backgroud-header-footer"
                    mx='auto'
                    // backgroundColor='#007799'
                >
                    <Flex
                        className="saleTableBottomText"
                        justifyContent='space-around'
                        textColor='white'
                        align='center'
                    >
                        <Text>
                            {
                                settleMode ?
                                    "요청금액"
                                    :

                                    "정산가능금액"
                            }
                        </Text>
                        <Text
                            fontWeight='700'
                        >
                            {
                                settleMode ?
                                    (settleAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                    :
                                    (totalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                            }
                        </Text>
                    </Flex>
                </Box>
                <Box
                    width='100%'
                    align='center'>

                    {
                        settleMode ?
                            <>
                                <Flex justify='space-around' maxW='93vw'>
                                    <Button
                                        className="transactionTwoBottomBtn"
                                        mt='3vh'
                                        mx='auto'
                                        shadow={shadow}
                                        _focus={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
                                        _active={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
                                        _hover={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
                                        backgroundColor='rgba(233, 51, 21, 1)'
                                        border='1px solid rgba(217, 217, 217, 1)'
                                        textColor='white'
                                        align='center'
                                        onClick={() => { settleCancle() }}
                                    >
                                        < Text
                                            mx='auto'
                                        >
                                            취소
                                        </Text>
                                    </Button>
                                    <Button
                                        className="transactionTwoBottomBtn main-btn-color"
                                        mt='3vh'
                                        mx='auto'
                                        shadow={shadow}
                                        _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        // backgroundColor='rgba(22, 204, 234, 1)'
                                        border='1px solid rgba(217, 217, 217, 1)'
                                        textColor='white'
                                        align='center'
                                        onClick={() => { settleReq() }}
                                    >
                                        < Text
                                            mx='auto'
                                        >
                                            정산요청
                                        </Text>
                                    </Button>
                                </Flex>
                            </>
                            :
                            <Button
                                className="transactionBottomBtn main-btn-color"
                                mt='3vh'
                                mx='auto'
                                shadow={shadow}
                                _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                // backgroundColor='rgba(22, 204, 234, 1)'
                                border='1px solid rgba(217, 217, 217, 1)'
                                textColor='white'
                                align='center'
                                onClick={() => { settleReq() }}
                            >
                                < Text
                                    mx='auto'
                                >
                                    정산요청
                                </Text>
                            </Button>
                    }
                </Box>


            </Box>


        </>

    );
}
