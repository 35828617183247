// React
import React, { useState } from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function ConfirmDeleteModal({ onClose, userInfo, managerInfo,deleteUser }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalStoreConfirm'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          backgroundColor='rgba(39, 134, 178, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='white'
          p='3%'
        >
          <Flex position='absolute' top='1vh' left='90%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='white' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            mt='20%'
            align='center'
            direction='column'
          >
            <Text>
              {managerInfo.name}
            </Text>
            <Text>
              삭제하시겠습니까?
            </Text>
          </Flex>



          <Button
            className='modalMainSmallBtn main-btn-color'
            position='absolute'
            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            top='80%'
            left='32.5%'
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={() => { deleteUser() }}
          >
            < Text
              className='modalMainSmallBtnText'
              mx='auto'
            >
              확인
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}