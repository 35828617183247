// React
import React from "react";
// External Libraries
import { Text, Box} from "@chakra-ui/react";
export default function Underbar({ color}) {
  return (
    <Box
      position='fixed'
      bottom='0'
      align='center'
      alignSelf='center'
      width='100%'
      height='3%'
      viewport-fit='cover'
      h='4vh'
    >
      <Text as='span' color ={color} fontWeight='400' fontSize={{base:'12px', md:'15px', xl:'18px'}}>
       VENETA Reserve, Inc.
      </Text>
    </Box >
  );
}
