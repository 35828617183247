export const columnsDataCalculation = [
  {
    Header: "작업시간",
    accessor: "date",
  },
  {
    Header: "오픈시간",
    accessor: "open_date",
  },

  {
    Header: "마감시간",
    accessor: "close_date",
  },
  {
    Header: "판매액",
    accessor: "today_sales",
  }
];
