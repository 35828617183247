// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function CalculationDetailModal({ calculationDetailInfo, calculationInfo, onClose }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='txDetailModal sub-modal-background-color'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='3%'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalSaleDetailMainText'
            align='center'
            direction='column'
          >
            <Text>
              [정산 정보]
            </Text>
          </Flex>
          <Flex
            className='modalMainInsideDefaultText'
            direction='column'
            mt='5%'
            height='70%'
          >
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                작업 시간
              </Text>
              <Text align='start'>
                :
              </Text>
              <Text
                ml='auto'
                fontWeight='700'
                className='main-text-color'
                // textColor='rgba(2, 58, 142, 1)'
                >
                {(calculationInfo.date).substring(0, 16)}
              </Text>

            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                오픈 시간
              </Text>
              <Text align='start'>
                :
              </Text>
              <Text
                ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(calculationInfo.open_date).toString().substring(0, 16)}
              </Text>

            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text
                align='start'
                fontWeight='700'
              >
                담당자
              </Text>
              <Text >
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(calculationInfo.open_manager).toString().substring(0, 16)}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                마감시간
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(calculationInfo.close_date).toString().substring(0, 16)}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                마감자
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(calculationInfo.close_manager)}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                정산금액
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(calculationInfo.today_sales).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                {
                  calculationInfo.settle === '1' ?
                    "정산요청"
                    :
                    "정산여부"
                }
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                // fontSize='24px'
                textColor='rgba(2, 58, 142, 1)'>
                {calculationInfo.settle === '1'
                  ?
                  (calculationInfo.settle_time).substring(0, 16).replace("_", " ")
                  :
                  "정산가능"
                }
              </Text>
            </Flex>
            {
              calculationDetailInfo && calculationDetailInfo.length > 0 ?
                <Flex>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text align='start'
                    fontWeight='700'>
                    주문정보
                  </Text>
                  <Text>
                    :
                  </Text>
                  <Box
                    fontWeight='700'
                    ml='auto'
                    width='60%'
                    textColor='rgba(2, 58, 142, 1)'>

                    {calculationDetailInfo.map((detail, index) => (
                      <Flex
                        justify='space-between'
                        fontWeight='700'
                        textColor='rgba(2, 58, 142, 1)'
                      >
                        <Text
                          key={index}
                        >
                          {detail.order_number}&nbsp;
                        </Text>
                        <Text
                          key={index}
                        >
                          {detail.cost_of_goods_sold_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                        </Text>
                      </Flex>

                    ))}
                  </Box>
                </Flex>
                :
                null
            }
          </Flex>

          <Button
            className='modalMainSmallBtn main-btn-color'
            mx='auto'
            mb='2.5vh'
            shadow={shadow}
            backgroundColor='
            // rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={() => { onClose() }}
          >
            <Text
              className='modalMainSmallBtnText'
              mx='auto'
            >
              확인
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}