import React, { useState } from 'react';
import {
  Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent,
  FormControl, FormLabel, FormHelperText, RadioGroup, HStack, Radio
} from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";

export default function TxCancelModal({ transactionInfo, onClose, orderReject, txCancelOk, txLoading }) {

  // Radio 선택 값 관리
  const [selectedReason, setSelectedReason] = useState('outOfStock'); // 기본값 설정

  // Variables for Card CSS Styling
  const bgHoverFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const buttonStyles = {
    position: 'absolute',
    top: '80%',
    left: '32.5%',
    shadow: shadow,
    border: '1px solid rgba(217, 217, 217, 1)',
    textColor: 'white',
    className: 'modalMainSmallBtn main-btn-color',
    _focus: { backgroundColor: 'rgba(22, 204, 234, 1)' },
    _active: { backgroundColor: 'rgba(22, 204, 234, 1)' },
    _hover: { backgroundColor: 'rgba(22, 204, 234, 1)' },
  };

  const renderCancelInfo = () => (
    <Flex direction='column' align='center' my='5%'>
      <Text align='center' className='modalSaleDetailCancelSmallText'>
        {(transactionInfo.order_reg).toString().substring(0, 16)} / {transactionInfo.sender} / 
        {(transactionInfo.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} VETA
      </Text>
      <Text className='modalSaleDetailCancelSmallText'>결제 취소 되었습니다.</Text>
    </Flex>
  );

  const handleReject = () => {
    const updatedTransactionInfo = {
      ...transactionInfo,
      reason: selectedReason, // 선택된 취소 사유 추가
    };
    orderReject(updatedTransactionInfo); // 선택된 사유가 추가된 transactionInfo 전달
  };

  const renderButton = () => {
    if (txCancelOk) {
      return (
        <Button {...buttonStyles} onClick={onClose}>
          <Text className='modalMainSmallBtnText' mx='auto'>확인</Text>
        </Button>
      );
    }
    if (txLoading) {
      return (
        <Button {...buttonStyles} border='1px solid rgba(217, 217, 217, 0.5)' _hover={{ backgroundColor: 'rgba(22, 204, 234, 0.5)' }}>
          <Text className='modalMainSmallBtnText' mx='auto'>진행중..</Text>
        </Button>
      );
    }
    return (
      <Button {...buttonStyles} onClick={handleReject}>
        <Text className='modalMainSmallBtnText' mx='auto'>예</Text>
      </Button>
    );
  };

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='modalSaleDetailCancel sub-modal-background-color'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='3%'
        >
          <Flex>
            <Icon
              _hover={bgHoverFocus}
              _focus={bgHoverFocus}
              _active={bgHoverFocus}
              className='modalExitBtn'
              onClick={onClose}
              color='rgba(130, 126, 126, 1)'
              as={BsXLg}
            />
          </Flex>

          <Flex direction='column' align='center' mt='3%'>
            <Text className='modalSaleDetailCancelMainText'>
              {txCancelOk ? "결제 취소가 완료 되었습니다." : "결제 취소 하시겠습니까?"}
            </Text>
          </Flex>

          {txCancelOk 
          ? 
          renderCancelInfo()
          :
          <Flex>
            <FormControl as='fieldset'>
              <FormLabel as='legend'>취소 사유 선택</FormLabel>
              <RadioGroup 
                value={selectedReason} 
                onChange={setSelectedReason} // Radio 선택 값을 업데이트
              >
                <HStack spacing='24px'>
                  <Radio value='재고 소진 입니다.'>재고 소진</Radio>
                  <Radio value='영업 준비중 입니다.'>미오픈</Radio>
                  <Radio value='영업 마감 입니다.'>마감</Radio>
                </HStack>
              </RadioGroup>
              <FormHelperText>해당 취소 사유는 고객에게 전달 됩니다.</FormHelperText>
            </FormControl>
          </Flex>
          }

          {renderButton()}
        </ModalContent>
      </Modal>
    </div>
  );
}
