// React
import React, { useMemo } from "react";
// Components
// Assets
import ManagerTableContent from "./ManagerTableContent.js";
import { columnsDataManager } from "../../../../../../variables/manager/columnsDataManager.js";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ManagerTable({ managerList, setManagerInfo, userInfo,setManagerUpdateModalOpen}) {
    const columns = useMemo(() => columnsDataManager, [columnsDataManager]);
    const tableData = useMemo(() => managerList, [managerList]);
    return (
        <>
            <ManagerTableContent columns={columns} dFlag={3}  data={tableData} setManagerInfo={setManagerInfo} userInfo={userInfo} setManagerUpdateModalOpen={setManagerUpdateModalOpen}/>
        </>
    );
}
