// React
import React from "react";
import { Flex, Box, Image, Text } from "@chakra-ui/react";
// Components
import axios from 'axios';
// Assets
// drag and drop

import Card from "../../../../../../components/card/Card.js";
import { useDrag, useDrop } from 'react-dnd';
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function DragProduct({ item, setItemList, index, itemList, setItemUpdateModalOpen, setItemInfo }) {

    // drag and drop 

    const ItemTypes = {
        CATEGORY: 'item',
    };
    const moveCategory = (fromIndex, toIndex) => {
        const updatedList = Array.from(itemList);
        const [movedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, movedItem);
        updatedList.forEach((item, index) => {
            item.primary = index +1;
        });
        setItemList(updatedList);
    };


    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CATEGORY,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            
            const dragIndex = item.index;
            const hoverIndex = index;
            
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCategory(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CATEGORY,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <>
            <Flex ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>

                <Card
                    _active={{ p: '2% 0% 2% 0%' }}
                    _hover={{ p: '2% 0% 2% 0%' }}
                    maxW='25vw'
                    maxH='30vh'
                    borderRadius='5'
                    m='1%'
                    p='2%'
                    onClick={() => {
                        setItemUpdateModalOpen(true)
                        setItemInfo(item)
                    }}>
                    <Flex direction='column' alignItems='center'
                        border={item.stock === '0' ?
                            'solid 1px red'
                            :
                            null
                        }
                    >
                        <Image src={`data:image/*;base64,${item.image}`} alt='' className='storeItemImage' />
                        <Flex>
                            <Text
                                whiteSpace='nowrap'
                                overflow='hidden'
                                textOverflow='ellipsis'>
                                {item.name}
                            </Text>
                        </Flex>
                        <Text
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'>
                            {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                        </Text>
                    </Flex>
                </Card>
            </Flex>
        </>
    );
}
