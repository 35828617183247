
import { css } from 'styled-components';
import styled from 'styled-components';

const CircleInput = styled.input`
width: 27px;
height: 27px;
border-radius: 50%;
border: 2px solid ${(props) => (props.hasValue ? '#16CCEA' : '#fff')};
border-color: ${(props) => (props.hasValue ? '#004A56' : '#C1B9B9')}; ;
font-size: 18px;
text-align: center;
margin: 5px;
outline: none;
background-color: ${(props) => (props.hasValue ? '#004A56' : '#fff')};
color: ${(props) => (props.value ? 'transparent' : '#000')};
`;
export default CircleInput;