// React
import React, { useState } from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../css/Page.css';
import { BsArrowLeft, BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function OpenModal({ openStore, onClose, selectedDate, nowDate }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='storeCloseModal sub-modal-background-color'
          border='1px solid'
          borderColor='white'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p="10px"
        >
          <Flex position='absolute' top='1vh' left='85%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>
          {
            nowDate !== selectedDate ?
              <Flex
                my='auto'
                align='center'
                direction='column'
                className='storeCloseMailSmallText'
              >
                <Text
                >
                  해당 영업일자가
                </Text>
                <Text
                  mb='40px'
                >
                  현재 시스템일자와 다릅니다.
                </Text>
                <Text>
                  해당 영업일자
                </Text>
                <Flex>
                  <Text
                    fontWeight='700'
                  >
                    [{(selectedDate).toString()}]
                  </Text>
                  <Text>
                    로
                  </Text>
                </Flex>
                <Text
                  mb='40px'
                >
                  영업매출이 집계 처리됩니다.
                </Text>
                <Flex>
                  <Text>
                    그래도
                  </Text>
                  <Text
                    fontWeight='700'
                    className='main-text-color'
                    // color='rgba(2, 58, 142, 1)'
                    >
                    &nbsp;개점 처리
                  </Text>

                  <Text>
                    &nbsp;하시겠습니까?
                  </Text>
                </Flex>

              </Flex>
              :
              <Flex
                my='auto'
                align='center'
                direction='column'
                className='storeCloseMailSmallText'
              >

                <Text>
                  영업일자
                </Text>
                <Flex>
                  <Text
                    fontWeight='700'
                  >
                    [{(selectedDate).toString()}]
                  </Text>
                  <Text>
                    로
                  </Text>
                </Flex>
                <Text
                  mb='40px'
                >
                  영업매출이 집계 처리됩니다.
                </Text>
                <Flex>
                  <Text
                    fontWeight='700'
                    className='main-text-color'
                    // color='rgba(2, 58, 142, 1)'
                    >
                    개점 처리
                  </Text>
                  <Text>
                    &nbsp;하시겠습니까?
                  </Text>
                </Flex>
              </Flex>
          }

          <Button
            className='storeCloseBtn main-btn-color'
            mx='auto'
            mb='2.5vh'
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            borderRadius='5px'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={() => {openStore(true) }}
          >
            <Text
              mx='auto'
              className='storeCloseBtnText'
            >
              예
            </Text>
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}