import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
      displayData: [], // 화면 크기에 따라 동적으로 변경되는 데이터 배열
      categories: [], // 화면 크기에 따라 동적으로 변경되는 카테고리 배열
    };

    this.updateChart = this.updateChart.bind(this);
    this.updateDisplayData = this.updateDisplayData.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {

    this.updateChart(this.props.monthlyInfo);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.monthlyInfo !== this.props.monthlyInfo) {
      this.updateChart(this.props.monthlyInfo);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.updateDisplayData();
  }

  updateDisplayData() {
    const width = window.innerWidth;
    let displayData = this.state.chartData[0]?.data || [];
    let categories = this.state.chartOptions.xaxis?.categories || [];

    if (width < 768) {
      const currentMonth = new Date().toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' }).getMonth(); // 0 (Jan) to 11 (Dec)
      const start = Math.max(currentMonth - 2, 0);
      const end = Math.min(currentMonth + 1, displayData.length);

      displayData = displayData.slice(start, end);
      categories = categories.slice(start, end);
    }

    this.setState({
      displayData: [{
        name: "Sales",
        data: displayData,
      }],
      categories: categories,
    });
  }

  updateChart(data) {
    if (!Array.isArray(data)) {
      console.error("Expected an array but got:", data);
      return;
    }

    const months = data.map(item => item.month);
    const sales = data.map(item => item.total_sales);

    const lineChartData = [
      {
        name: "Sales",
        data: sales,
      },
    ];

    const lineChartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toLocaleString();
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: months,
        labels: {
          style: {
            colors: "#8C908D",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#8C908D",
            fontSize: "12px",
          },
          formatter: function (value) {
            return value.toLocaleString();
          }
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.1,
          inverseColors: true,
          opacityFrom: 1.2,
          opacityTo: 0.4,
          stops: [],
        },
        colors: ["#4FD1C5", "#2D3748"],
      },
      colors: ["#4FD1C5", "#2D3748"],
    };

    this.setState({
      chartData: lineChartData,
      chartOptions: lineChartOptions,
    }, this.updateDisplayData); // 상태가 업데이트된 후 데이터 조정 함수 호출
  }

  render() {
    return (
      <ReactApexChart
        options={{
          ...this.state.chartOptions,
          xaxis: {
            ...this.state.chartOptions.xaxis,
            categories: this.state.categories,
          },
        }}
        series={this.state.displayData}
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;
