// React
import React, { useState, useEffect } from "react";
import { Flex, Box, Image, Text } from "@chakra-ui/react";
import axios from 'axios';
// Components

import main_sale from "../../../../../assets/img/service/main_sale.png";
import home from "../../../../../assets/img/service/home.png";
import TransactionDetailModal from "./modal/TransactionDetailModal";
import TxCancelModal from "./modal/TxCancelModal";
import IncomeModal from "./modal/IncomeModal";
import OrderCard from "./component/OrderCard";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SaleCard({ orderList, userInfo, setNowComponent, todayOrderAmount, setUploadOrder
    , transactionInfo, setTransactionInfo, txCancelOk, orderOngoing, orderClose, orderReject, orderComplete, txLoading,setTxCancelOk
}) {

    //modal status
    const [transactionDetailModalOpen, setTransactionDetailModalOpen] = useState(false);
    const [txCancelModalOpen, setTxCancelModalOpen] = useState(false);
    const [incomeModalOpen, setIncomeModalOpen] = useState(false);
    function txCancelRequest() {
        if(txCancelOk){
            setTxCancelOk(false);
        }
        setTransactionDetailModalOpen(false);
        setTxCancelModalOpen(true);
    }
    const [orderDetail, setOrderDetail] = useState([]);

    useEffect(() => {
        if (transactionInfo) {
            var orders = [];
            axios.post('/api/user/orderDatail', {
                order_number: transactionInfo.order_number
            })
                .then((response) => {
                    for (var i = 0; i < response.data.order.length; i++) {
                        orders.push({
                            "order_number": response.data.order[i].order_number,
                            "product_name": response.data.order[i].product_name,
                            "product_number": response.data.order[i].product_number,
                            "product_quantity": response.data.order[i].product_quantity,
                            "price": response.data.order[i].price
                        })
                    }
                    setOrderDetail(orders);
                })
                .catch((error) => {

                    // logout();
                })
        }
    }, [transactionInfo]
    );

    const [optionGroupList, setOptionGroupList] = useState([]);
    const [optionList, setOptionList] = useState([]);

    useEffect(()=>{

        axios.post('/api/pos/product/totalOption')
        .then((response) => {

            setOptionGroupList(response.data.optionGroupList);
            setOptionList(response.data.optionList);
          })
          .catch((error) => {
            //window.location.reload();
            console.log(error)
            //alert("세션 만료로 새로고침 후 이용해주세요.");
          })


    },[])


    return (
        <>
            {incomeModalOpen && <IncomeModal transactionInfo={transactionInfo} onClose={() => setIncomeModalOpen(false)} />}
            {transactionDetailModalOpen && 
            <TransactionDetailModal 
            orderOngoing={orderOngoing}
             orderDetail={orderDetail} 
             transactionInfo={transactionInfo} 
             onClose={() => setTransactionDetailModalOpen(false)} 
             userInfo={userInfo} 
             txCancelRequest={txCancelRequest}
              orderComplete={orderComplete} 
              orderClose={orderClose}
            
            />}

            {txCancelModalOpen
             && 
             <TxCancelModal 
             txLoading={txLoading} 
             orderReject={orderReject} 
             transactionInfo={transactionInfo} 
             onClose={() => setTxCancelModalOpen(false)} 
             userInfo={userInfo} 
             txCancelOk={txCancelOk} 
             />}
            
            <Flex
                className="txHomeImageAndText">
                <Image src={main_sale} className="mainmenuImage" />
                <Text
                    className="mainmenuText main-text-color"
                    mt='5px'
                    // color='rgba(2, 58, 142, 1)'
                >
                    판매 처리
                </Text>
                <Image src={home} className="mainmenuHome" ml='auto' onClick={() => { setNowComponent('') }} />
            </Flex>
            <Box
                className="sub-background-color menuBox"
                overflowY='hidden'
                borderRadius='20px 20px 0px 0px'
                borderTop='1px solid white'
                zIndex='2'
                height='100%'
                width='100vw'
                // backgroundColor='rgba(100,200,220,1)'

            >

                <Box
                    mx='auto'
                    className="saleTableBox table-box"
                    backgroundColor='white'
                >

                    <OrderCard
                        setUploadOrder={setUploadOrder}
                        orderList={orderList}
                        setTransactionInfo={setTransactionInfo}
                        orderOngoing={orderOngoing}
                        orderDetail={orderDetail}
                        transactionInfo={transactionInfo}
                        onClose={() => setTransactionDetailModalOpen(false)}
                        userInfo={userInfo}
                        txCancelRequest={txCancelRequest}
                        orderComplete={orderComplete}
                        orderClose={orderClose}
                        setTransactionDetailModalOpen={setTransactionDetailModalOpen} />

                </Box>
                <Box
                    className="otherTableBottomBox table-backgroud-header-footer"
                    mx='auto'
                    // backgroundColor='#007799'
                >
                    <Flex
                        className="saleTableBottomText"
                        justifyContent='space-around'
                        textColor='white'
                        align='center'
                    >
                        <Text>
                            주문 갯수
                        </Text>
                        <Text
                            fontWeight='700'
                        >
                            {(todayOrderAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                        </Text>


                    </Flex>

                </Box>

            </Box>
        </>

    );
}
