// Components
import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./components/card";
import { buttonStyles } from "./components/button";
import { textareaStyles } from "./components/textarea";
import { linkStyles } from "./components/link";
import { globalStyles } from "./styles";



//import { CardComponent } from './additions/card/Card';
import { CardBodyComponent } from './additions/card/CardBody';
import { CardHeaderComponent } from './additions/card/CardHeader';
import { MainPanelComponent } from './additions/layout/MainPanel';
import { PanelContentComponent } from './additions/layout/PanelContent';
import { PanelContainerComponent } from './additions/layout/PanelContainer';

export default extendTheme(
  globalStyles, // global styles
  buttonStyles, // button styles
  linkStyles, // link styles
  textareaStyles, // textarea styles
  CardComponent, // card component
  CardBodyComponent, // Card Body component
	CardHeaderComponent, // Card Header component
	MainPanelComponent, // Main Panel component
	PanelContentComponent, // Panel Content component
	PanelContainerComponent // Panel Container component
);
