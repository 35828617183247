import React, { Component } from "react";
import Card from "../Card_BackOffice/Card";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
      displayData: [], // 화면 크기에 따라 동적으로 변경되는 데이터 배열
      categories: [], // 화면 크기에 따라 동적으로 변경되는 카테고리 배열
    };

    this.updateChart = this.updateChart.bind(this);
    this.updateDisplayData = this.updateDisplayData.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.updateChart(this.props.weeklyInfo);
    window.addEventListener("resize", this.handleResize);
    this.updateDisplayData(); // 초기 데이터 설정
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weeklyInfo !== this.props.weeklyInfo) {
      this.updateChart(this.props.weeklyInfo);
      this.updateDisplayData(); // 데이터 변경 시 업데이트
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.updateDisplayData();
  }

  updateDisplayData() {
    const width = window.innerWidth;
    let displayData = this.state.chartData[0]?.data || [];
    let categories = this.state.chartOptions.xaxis?.categories || [];

    if (width < 768) { // 화면이 작아질 때
      displayData = displayData.slice(-3); // 마지막 3개의 데이터만 표시
      categories = categories.slice(-3); // 마지막 3개의 카테고리만 표시
    }

    this.setState({
      displayData: [{
        name: "Sales",
        data: displayData,
      }],
      categories: categories,
    });
  }

  updateChart(weeklyInfo) {

    
    const barChartData = [
      {
        name: "Sales",
        data: weeklyInfo.chartData || [],
      },
    ];

    const barChartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          backgroundColor: "red",
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          style: {
            backgroundColor: "red",
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toLocaleString();
          }
        }
      },
      xaxis: {
        categories: weeklyInfo.chartCategories || [],
        show: true,
        labels: {
          show: true,
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        color: "#fff",
        labels: {
          show: true,
          style: {
            colors: "#fff",
            fontSize: "14px",
          },
          formatter: function (value) {
            return value.toLocaleString();
          }
        },
      },
      grid: {
        show: false,
      },
      fill: {
        colors: "#fff",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "12px",
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 0,
              },
            },
          },
        },
      ],
    };

    this.setState({
      chartData: barChartData,
      chartOptions: barChartOptions,
    }, this.updateDisplayData); // 상태가 업데이트된 후 데이터 조정 함수 호출
  }

  render() {
    return (
      <Card
        py="1rem"
        height={{ sm: "200px" }}
        width="100%"
        bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
        position="relative"
      >
        <Chart
          options={{
            ...this.state.chartOptions,
            xaxis: {
              ...this.state.chartOptions.xaxis,
              categories: this.state.categories
            }
          }}
          series={this.state.displayData}
          type="bar"
          width="100%"
          height="100%"
        />
      </Card>
    );
  }
}

export default BarChart;
