import React, { useEffect } from 'react';
import '../css/App.css';
import { Box, Image, Text } from "@chakra-ui/react";
import POS from "../assets/img/service/POS_MAIN_LOGO.png"

export default function LodingFirst({ setViewComponent, viewComponent }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      // 일정 시간이 지난 후 /api/로 이동
      if (viewComponent === '') {
        setViewComponent('Login');
      }
    }, 2000); // 1초 (1000ms) 설정

    // 컴포넌트가 언마운트될 때 타이머를 정리
    return () => clearTimeout(timer);
  }, [viewComponent]);
  return (
    <Box
      overflow='hidden'
      // backgroundColor='#E8EDED'
      width='100vw' height='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'
      justify='center'
      onClick={() => { setViewComponent('Login') }}

      className='main-background-color'

    >
      <Box align='center'>
        <Image  src={POS} maxH="40vh"/>
      </Box>
      {/* 
      <Box align='center'
        textColor='white'>
        <Text
          className='loadingBusinessText'>
          Business
        </Text>
        <Text
          className='loadingBusinessSmallText'
        >
          (가맹점용)
        </Text>
      </Box>
       */}
    </Box>
  );
}