// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function TransactionDetailModal({ transactionInfo, onClose, orderDetail, orderReject, txLoading }) {
  function doubleCheck(transactionInfo) {
    if (window.confirm('환불하시겠습니까? 결과는 사장님에게 전송됩니다.')) {
      orderReject(transactionInfo);
    }
  }
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='txDetailModal sub-modal-background-color'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='3%'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalSaleDetailMainText'
            align='center'
            direction='column'
          >
            <Text>
              [거래 내역]
            </Text>
          </Flex>
          <Flex
            className='modalMainInsideDefaultText'
            direction='column'
            mt='5%'
            height='70%'
          >
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                주문 번호
              </Text>
              <Text align='start'>
                :
              </Text>
              <Text
                ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {orderDetail ? (orderDetail[0].order_number) : null}
              </Text>

            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                결제 일시
              </Text>
              <Text align='start'>
                :
              </Text>
              <Text
                ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(transactionInfo.order_reg).toString().substring(0, 16)}
              </Text>

            </Flex>
            {/* <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text
                align='start'
                fontWeight='700'
              >
                결 제 자1
              </Text>
              <Text >
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.email}
              </Text>
            </Flex> */}
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text
                align='start'
                fontWeight='700'
              >
                닉 네 임
              </Text>
              <Text >
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.sender}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                결제 상태
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {transactionInfo.settlement === 1 ? '정산완료' : transactionInfo.settlement === 2 ? '주문취소' : '정산가능'}
              </Text>
            </Flex>
            <Flex
              align='center'
            >
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                판매가
              </Text>
              <Text>
                :
              </Text>

              <Text ml='auto'
                fontWeight='700'
                textColor='rgba(2, 58, 142, 1)'>
                {(transactionInfo.cost_of_goods_sold_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
              </Text>
            </Flex>
            {
              transactionInfo.order_comment ?

                <Flex
                  align='center'
                >
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text align='start'
                    fontWeight='700'>
                    할인내역
                  </Text>
                  <Text>
                    :
                  </Text>
                  <Text ml='auto'
                    fontWeight='700'
                    textColor='rgba(2, 58, 142, 1)'>
                    {(transactionInfo.order_comment)}
                  </Text>
                </Flex>
                :
                null
            }


            <Flex>
              <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
              <Text align='start'
                fontWeight='700'>
                주문 내역
              </Text>
              <Text>
                :
              </Text>
              <Box
                fontWeight='700'
                ml='auto'
                width='60%'
                textColor='rgba(2, 58, 142, 1)'>
                {
                  orderDetail ? orderDetail.map((order, index) => (
                    <Flex
                      justify='space-between'
                      fontWeight='700'
                      textColor='rgba(2, 58, 142, 1)'
                    >
                      <Text
                        key={index}
                      >
                        {order.product_name}&nbsp;
                      </Text>
                      <Text
                        key={index}
                      >
                        {order.product_quantity}
                      </Text>
                    </Flex>
                  ))
                    :
                    null
                }
              </Box>
            </Flex>
          </Flex>
          <Flex>

            <Button
              className='modalMainSmallBtn main-btn-color'
              mx='auto'
              mb='2.5vh'
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { onClose() }}
            >
              <Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                확인
              </Text>
            </Button>

            {(transactionInfo.order_status === 'close') && transactionInfo.settlement === 0 && txLoading ?
              <Button
                className='modalMainSmallBtn'
                mx='auto'
                mb='2.5vh'
                _focus={{ backgroundColor: 'rgba(233, 51, 21, 0.5)' }}
                _active={{ backgroundColor: 'rgba(233, 51, 21, 0.5)' }}
                _hover={{ backgroundColor: 'rgba(233, 51, 21, 0.5)' }}
                shadow={shadow}
                backgroundColor='rgba(233, 51, 21, 0.5)'
                border='1px solid rgba(217, 217, 217, 0.5)'
                textColor='white'
                align='center'
              >
                <Text
                  className='modalMainSmallBtnText'
                  mx='auto'
                >
                  진행중..
                </Text>
              </Button>

              :
              (transactionInfo.order_status === 'close') && transactionInfo.settlement === 0 && !txLoading ?
                <Button
                  className='modalMainSmallBtn'
                  mx='auto'
                  mb='2.5vh'
                  shadow={shadow}
                  backgroundColor='
                rgba(233, 51, 21, 1)'
                  border='1px solid rgba(217, 217, 217, 1)'
                  textColor='white'
                  align='center'
                  onClick={(e) => { doubleCheck(transactionInfo) }}
                >
                  <Text
                    className='modalMainSmallBtnText'
                    mx='auto'
                  >
                    환불
                  </Text>
                </Button>
                :
                null}
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}