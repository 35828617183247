// React
import React, { useEffect, useState } from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent, Box } from "@chakra-ui/react";
import '../../../../../css/Page.css';
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function MainClosingModal({ onClose, secondModal, nowDetailDate, storeInfo, totalResultSalesAmount, orderList, userInfo }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const [resultList, setResultList] = useState([]);
  useEffect(() => {
    var list = [];
    orderList.forEach(order => {
      if (order.order_status === 'ongoing' || order.order_status === 'order') {
        list.push(order);
      }
      setResultList(list);
    });



  }, [orderList])



  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='modalMainTodayClose sub-modal-background-color'
          border='1px solid'
          borderColor='white'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
                      p="10px"
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalMainCloseText'
            mb='5%'
            align='center'
            direction='column'
          >
            <Text
            >
              [ 마감처리 ]
            </Text>
          </Flex>

          <Flex
            mb='5%'
            className='modalMainInsideDefaultText'
            width='100%'
            // align='center'
            // justifyContent='space-between'
            direction='column'
          >
            <Flex >
              {/* <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' /> */}
              <Text align='left'>
                영업일자
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {storeInfo.open_date}
              </Text>
            </Flex>

            <Flex>
              {/* <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' /> */}
              <Text align='left'>
                마감시간
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {(nowDetailDate).toString()}
              </Text>
            </Flex>

            <Flex>
              {/* <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' /> */}
              <Text>
                마감점원
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {userInfo.name}
              </Text>
            </Flex>

            <Flex>
              {/* <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' /> */}
              <Text>
                매출집계
              </Text>
              <Text>
                :
              </Text>
              <Text ml='auto'>
                {(totalResultSalesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} Eternals Money
              </Text>
            </Flex>

            {resultList && resultList.length > 0 && (
              <>
                <Flex>
                  <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                  <Text color='red'>
                    주의! 처리중인 주문이 남아있습니다. 마감 시 전부 취소처리가 됩니다.
                  </Text>
                </Flex>
                <Flex
                  direction='column'>
                  {resultList.map((order, index) => (
                    <Box key={index}
                      mx='auto'>
                      <Text >
                        {order.sender} 의 주문이 남아있습니다.
                      </Text>
                    </Box>
                  ))}
                </Flex>
              </>
            )}
          </Flex>

          <Flex
            mt='auto'
            justify='space-around'

          >
            <Button
              className='modalMainSmallBtn main-btn-color'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { secondModal('closingFinish') }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                마감
              </Text>
            </Button>
            <Button
              className='modalMainSmallBtn main-btn-color'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { secondModal('closingFinishAndLogout') }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                마감 후 종료
              </Text>
            </Button>



          </Flex>

        </ModalContent>
      </Modal>
    </div >
  );
}