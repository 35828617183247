// React
import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
// Components
import processing from "../../../../../assets/img/service/process.gif";

export default function Loading() {


    return (

        <Box
            className="sub-modal-background-color"
            width='100vw'
            height='100vh'
            top='0vh'
            zIndex='9999'
            align='center'
            position='absolute'
            // backgroundColor='rgba(192, 223, 244, 1)'
        >
            <Box>

                <Image src={processing} className="serviceLoadingImage" />
                <Text
                    className="serviceLoadingText sub-modal-background-color"
                    // color='rgba(229, 244, 255, 1)'
                >
                    마감 처리 중 입니다.
                </Text>
            </Box>
        </Box>
    );
}
