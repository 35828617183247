// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue, Box } from "@chakra-ui/react";
// Custom components
import Card from "../../../components/Card_BackOffice/Card.js";
import CardBody from "../../../components/Card_BackOffice/CardBody.js";
// Custom icons
import {
  CartIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "../../../components/Icons/Icons"//"../../../components/Icons/Icons.js";
import React from "react";
import ChartStatistics from "./ChartStatistics";

const ActiveUsers = ({ title, percentage, chart, weeklyInfo }) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p='16px'>
      <CardBody>
        <Flex direction='column' w='100%'>


          <Flex direction='column' mt='10px' mb='10px' alignSelf='flex-start'>
            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
              {title}

              {/* {item_count, unique_user_count, total_settled_amount, total_sales_amount, sales_increase_percentage, last_week_sales_amount}) */}
            </Text>
            {/* 
            
            <Text fontSize='md' fontWeight='medium' color='gray.400'>
              <Text
                as='span'
                color={
                  weeklyInfo.sales_increase_percentage > 0 ? "green.400" :
                    weeklyInfo.sales_increase_percentage < 0 ? "red.400" : "green.400"
                }
                fontWeight='bold'
                pr="3px"
              >

                {
                  typeof weeklyInfo.sales_increase_percentage === 'number'
                    ? (
                      weeklyInfo.sales_increase_percentage > 0
                        ? `+${weeklyInfo.sales_increase_percentage.toFixed(2)}%`
                        : weeklyInfo.sales_increase_percentage < 0
                          ? `${weeklyInfo.sales_increase_percentage.toFixed(2)}%`
                          : `${weeklyInfo.sales_increase_percentage.toFixed(2)}%`
                    )
                    : 'N/A'
                }
              </Text>
              한주간 통계와 2주전 통계의 비교 값 
            </Text>
            */}
          </Flex>
          {chart}

          <SimpleGrid gap={{ sm: "12px" }} mt='20px' columns={{ base: 2, lg: 4 }}>
            <Box mb={{ base: "20px", lg: "0" }}>
              <ChartStatistics
                title={"지난주 사용자 수"}
                amount={weeklyInfo.unique_user_count}
                percentage={20}
                icon={<WalletIcon h={"15px"} w={"15px"} color={iconBoxInside} />}

              />
            </Box>
            <ChartStatistics
              title={"지난주 판매 금액"}

              amount={weeklyInfo.total_sales_amount}
              percentage={80}
              icon={<RocketIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
            />
            <ChartStatistics
              title={"지난주 정산 금액"}
              amount={weeklyInfo.total_settled_amount}
              percentage={30}
              icon={<CartIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
            />
            <ChartStatistics
              title={"판매중인 제품 갯수"}
              amount={weeklyInfo.item_count}
              percentage={40}
              icon={<StatsIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
            />
          </SimpleGrid>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ActiveUsers;
