// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Flex, Icon, FormLabel, Box, Input, Text, useColorModeValue, Stat, Image, StatGroup, Textarea, Modal, ModalContent } from "@chakra-ui/react";
// Components
import { BsXLg, BsCircleFill } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function DiscountAddModal({ onClose, setReloadDiscount }) {
  const [amount, setAmount] = useState(false);
  const [persent, setPersent] = useState(false);

  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const regex = /^\d+$/;
  const regexComment = /[^a-zA-Z0-9가-힣\s]/;
  // input data variables
  const [inputs, setInputs] = useState({
    discount_amount: "",
    discount_percent: "",
    comment: ""
  });
  const { discount_amount, discount_percent, comment } = inputs;
  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }
  function addDiscount(e) {
    e.preventDefault();
    if (!comment) {
      alert("할인 정보는 필수값입니다.");
      return;
    }
    if (discount_amount && !regex.test(discount_amount)) {
      alert("올바른 할인 금액을 입력하세요.");
      return;
    }
    if (Number(discount_percent) > 100) {
      alert("1~100 사이의 할인율(%)을 입력하세요.");
    }
    if (discount_percent && !regex.test(discount_percent)) {
      alert("올바른 할인율(%)을 입력하세요.");
      return;
    }
    if (comment && regexComment.test(comment)) {
      alert("올바른 정보를 입력하세요.");
      return;
    }
    if ((discount_amount && discount_percent) || (!discount_amount && !discount_percent)) {
      alert("할인율 혹은 할인금액을 확인하세요.");
      return;
    }
    axios.post("/api/pos/product/addDiscount", {
      discount_amount: discount_amount,
      discount_percent: discount_percent,
      comment: comment
    })
      .then((response) => {
        alert("추가 완료되었습니다.");
        setReloadDiscount(true);
        onClose();
      })
      .catch((error) => {
        alert(error);
      })
  }


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalAddManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='1%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            align='center'
            direction='column'
          >
            <Text>
              [내역추가]
            </Text>
          </Flex>
          {
            discount_percent ?
              null
              :
              <Flex
                justifyContent='space-between'
                align='center'>
                <Text
                  className=' '
                >
                  할인 금액
                </Text>
                <Input
                  width='50%'
                  className=''
                  isRequired={true}
                  variant='auth'
                  type='text'
                  autoComplete="off"
                  backgroundColor='rgba(255,255,255,0)'
                  borderBottom='1px solid black'
                  borderRadius='0px'
                  placeholder='할인 금액'
                  id="discount_amount"
                  name="discount_amount"
                  value={discount_amount}
                  onChange={onChange} />
              </Flex>
          }
          {
            discount_amount ?
              null
              :
              <Flex
                justifyContent='space-between'
                align='center'>
                <Text
                  className=' '
                >
                  할인율
                </Text>
                <Input
                  width='50%'
                  className=''
                  isRequired={true}
                  variant='auth'
                  type='text'
                  autoComplete="off"
                  backgroundColor='rgba(255,255,255,0)'
                  borderBottom='1px solid black'
                  borderRadius='0px'
                  placeholder='1~100의 숫자를 입력하세요.'
                  id="discount_percent"
                  name="discount_percent"
                  value={discount_percent}
                  onChange={onChange} />
              </Flex>
          }
          <Flex
            justifyContent='space-between'
            align='center'>
            <Text
              className=' '
            >
              할인 정보
            </Text>
            <Input
              width='50%'
              className=''
              isRequired={true}
              variant='auth'
              type='text'
              autoComplete="off"
              backgroundColor='rgba(255,255,255,0)'
              borderBottom='1px solid black'
              borderRadius='0px'
              placeholder='ex) 직원할인'
              id="comment"
              name="comment"
              value={comment}
              onChange={onChange} />
          </Flex>


          <Button mt='5%' onClick={(e) => { addDiscount(e) }}  className="main-btn-color" color="white">
            저장
          </Button>
        </ModalContent>
      </Modal>
    </div >
  );
}