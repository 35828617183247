// React
import React, { useEffect } from "react";
import { Flex, Box, Text, Image } from "@chakra-ui/react";
// Components
import Card from "../../../../../../components/card/Card.js";
// Assets
import gradient3 from "../../../../../../assets/img/service/gradient3.jpeg";
// drag and drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragComponent from "./DragComponent.js";
import DragProduct from "./DragProduct.js";

import defaultLogoImg from "../../../../../../assets/img/service/eternalsCat.png"

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ItemCard({
     itemList, setSelectList, selectList, setItemUpdateModalOpen, setItemInfo, bigCategoryList,
     setCategoryBigUpdateModalOpen, uploadItem, setBigCategoryList, updateShop, bigCategoryInfo, setBigCategoryInfo, 
     totalDiscountAmount, totalDiscountPercent,
     //bigCategoryProductList,
     bigCategoryOptionGroupList
    }) {


    useEffect(() => {
        if (bigCategoryList.length > 0) {

            if(bigCategoryInfo.category_big_id){
                handleBigCategoryClick(bigCategoryInfo);
                setSelectList(itemList.filter(element => bigCategoryInfo.category_big_id === element.category_big_id));
            }else{
                handleBigCategoryClick(bigCategoryList[0]);
                setSelectList(itemList.filter(element => bigCategoryList[0].category_big_id === element.category_big_id));
            }
        }
    }, [itemList])
    useEffect(() => {
        setSelectList(itemList.filter(element => bigCategoryInfo.category_big_id === element.category_big_id));
    }, [bigCategoryInfo])

    const handleBigCategoryClick = (bigCategoryId) => {

        setBigCategoryInfo(bigCategoryId);
    };


    return (
        <>
            <Flex
                my='1vh'
                width='90%'
                mx='auto'
                wrap="wrap"
                
            >
  
                {
                    updateShop ?
                        <DndProvider backend={HTML5Backend}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {bigCategoryList.map((category, index) => (
                                    <DragComponent
                                        index={index}
                                        category={category}
                                        setBigCategoryInfo={setBigCategoryInfo}
                                        onClick={() => handleBigCategoryClick(category)}
                                        bigCategoryList={bigCategoryList}
                                        setCategoryBigUpdateModalOpen={setCategoryBigUpdateModalOpen}
                                        setBigCategoryList={setBigCategoryList}
                                        selectedBigCategory={bigCategoryInfo}
                                        handleBigCategoryClick={handleBigCategoryClick}
                                    />
                                ))}
                            </div>
                        </DndProvider>
                        :

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                
                                bigCategoryList.map((category, index) => (
                                    category.category_big_id === bigCategoryInfo.category_big_id ?
                                        <>
                                            <Flex
                                                align='center'
                                                backgroundColor='rgba(70, 209, 232, 1)'
                                                direction='column'
                                                className="bigCategory" key={index} onClick={() => handleBigCategoryClick(category)} >

                                               <Box
                                                    align='center'
                                                    textColor='black'
                                                    direction='column'
                                                    width='100%'
                                                    color='white'
                                                    height='100%'
                                                    whiteSpace='nowrap'
                                                    overflow='hidden'
                                                    textOverflow='ellipsis'
                                                >
                                                    {
                                                        category.image ?

                                                            <Image style={{ padding: '20px' }} src={`data:image/*;base64,${category.image}`} alt='' className='storeItemImage' />
                                                            :
                                                            null
                                                    }
                                                    {category.category_big_name}
                                                </Box>
                                            </Flex>
                                        </>
                                        :
                                        <>
                                            <Flex
                                                align='center'
                                                backgroundImage={gradient3}
                                                backgroundRepeat='no-repeat'
                                                bgSize='cover'
                                                className="bigCategory" key={index} onClick={() => handleBigCategoryClick(category)} >
                                                <Box
                                                    textColor='black'
                                                    align='center'
                                                    direction='column'
                                                    width='100%'
                                                    height='100%'
                                                    whiteSpace='nowrap'
                                                    overflow='hidden'
                                                    textOverflow='ellipsis'
                                                >
                                                    {
                                                        category.image ?

                                                            <Image style={{ padding: '20px' }} src={`data:image/*;base64,${category.image}`} alt='' className='storeItemImage' />
                                                            :
                                                            null
                                                    }
                                                    {category.category_big_name}
                                                </Box>
                                            </Flex>
                                        </>
                                ))
                            }
                        </div>
                }
            </Flex >
            <Card
                // height='60%'
                minH='80%'
                maxH="35vh"
                // overflowY='auto'
                // overflowX='hidden'
                //backgroundImage={gradient3}
                // POS color
                // backgroundColor="#d0d5d5"
                backgroundRepeat='no-repeat'
                bgSize='cover'
                bgPosition='10%'
                alignSelf='center'
                mx='auto'
                width='90%'
                p='10px'
                className='main-background-color'
            >

                <Box direction='row'  w='100%' justifyContent='space-around' overflowY='scroll'  h="50vh">

                    {
                        updateShop ?
                            <Flex wrap='wrap' gap='20px'>
                                
                                <DndProvider backend={HTML5Backend}>
                                    {
                                        selectList
                                            .map((item, index) => (
                                                <DragProduct
                                                    item={item}
                                                    setItemList={setSelectList}
                                                    itemList={selectList}
                                                    index={index}
                                                    setItemUpdateModalOpen={setItemUpdateModalOpen}
                                                    setItemInfo={setItemInfo}
                                                />
                                            ))
                                    }
                                </DndProvider>
                            </Flex>
                            :
                            <Flex wrap='wrap' gap='20px'>
                                {
                                    selectList
                                        .map((item, itemIndex) => (
                                            <Flex key={itemIndex} direction='column' justify='space-around'>
                                                <Flex key={itemIndex}>{
                                                    <Card
                                                        _active={{ p: '2% 0% 2% 0%' }}
                                                        _hover={{ p: '2% 0% 2% 0%' }}
                                                        maxW='25vw'
                                                        maxH='30vh'
                                                        borderRadius='5'
                                                        m='1%'
                                                        p='2%'
                                                        key={itemIndex}
                                                        onClick={() => {
                                                            setItemUpdateModalOpen(true)
                                                            setItemInfo(item)
                                                        }}>
                                                        <Flex direction='column' alignItems='center'
                                                            border={item.stock === '0' ?
                                                                'solid 1px red'
                                                                :
                                                                null
                                                            }
                                                        >

                                                            {
                                                                item.image ?
                                                                    <Image src={`data:image/*;base64,${item.image}`} alt='' className='storeItemImage' />
                                                                    :
                                                                    <Image src={defaultLogoImg} alt='' className='storeItemImage' />
                                                            }



                                                            <Flex>
                                                                <Text
                                                                    whiteSpace='nowrap'
                                                                    overflow='hidden'
                                                                    textOverflow='ellipsis'>
                                                                    {item.name}
                                                                </Text>
                                                            </Flex>
                                                            {
                                                                totalDiscountAmount || totalDiscountPercent ?
                                                                    <>
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                            color='red'
                                                                            textDecoration='line-through'
                                                                        >
                                                                            {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                        </Text>
                                                                        {
                                                                            totalDiscountAmount ?
                                                                                <Text
                                                                                    whiteSpace='nowrap'
                                                                                    overflow='hidden'
                                                                                    textOverflow='ellipsis'
                                                                                >
                                                                                    {(Number(item.price) - Number(totalDiscountAmount)) > 0 ? (Number(item.price) - Number(totalDiscountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                                </Text>

                                                                                :
                                                                                totalDiscountPercent ?
                                                                                    <Text
                                                                                        whiteSpace='nowrap'
                                                                                        overflow='hidden'
                                                                                        textOverflow='ellipsis'
                                                                                    >
                                                                                        {(Number(item.price) * (100 - Number(totalDiscountPercent)) / 100) > 0 ? (parseInt((Number(item.price) * (100 - Number(totalDiscountPercent)) / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                                    </Text>
                                                                                    :
                                                                                    <Text
                                                                                        whiteSpace='nowrap'
                                                                                        overflow='hidden'
                                                                                        textOverflow='ellipsis'
                                                                                    >
                                                                                        {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                                    </Text>

                                                                        }

                                                                    </>
                                                                    :
                                                                    <Text
                                                                        whiteSpace='nowrap'
                                                                        overflow='hidden'
                                                                        textOverflow='ellipsis'
                                                                    >
                                                                        {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}

                                                                    </Text>
                                                            }
                                                        </Flex>
                                                    </Card>
                                                }</Flex>
                                            </Flex>
                                        ))}
                            </Flex>
                    }
                </Box>
            </Card >
        </>
    );
}
