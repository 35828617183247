import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent, Input } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs";
import { FormGroup, FormLabel } from 'react-bootstrap';
import CryptoJS from 'crypto-js';

export default function ManagerAddModal({ onClose, setUploadUser }) {
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const [inputs, setInputs] = useState({
    email: localStorage.getItem("store_number") + "-",
    password: "",
    passwordCheck: "",
    name: "",
    phone: "",
    level: "",
  });

  const { email, password, passwordCheck, name, phone, level } = inputs;

  const [checkboxes, setCheckboxes] = useState({
    firstBox: false,
    secondBox: false,
    thirdBox: false,
    fourthBox: false
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckboxes(prevCheckboxes => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  const classLevel = ['manager', 'master'];
  const regex = /^\d+$/;

  function onChange(e) {
    const { value, name } = e.target;

    if (name === "phone") {
      const onlyNumbers = value.replace(/[^0-9]/g, '');
      const emailPrefix = localStorage.getItem("store_number") + "-";
      const phoneSuffix = onlyNumbers.slice(3);
      setInputs((prevInputs) => ({
        ...prevInputs,
        phone: onlyNumbers,
        email: emailPrefix + phoneSuffix
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value
      }));
    }
  }

  useEffect(() => {
    if (inputs.level === "master") {
      setCheckboxes({
        firstBox: true,
        secondBox: true,
        thirdBox: true,
        fourthBox: true
      });
    }
  }, [inputs.level]);


  useEffect(() => {
    if (!checkboxes.firstBox || !checkboxes.secondBox || !checkboxes.thirdBox || !checkboxes.fourthBox) {
      setInputs(prevInputs => ({
        ...prevInputs,
        level: "manager"
      }));
    }
  }, [checkboxes]);


  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!regex.test(password)) {
      alert('비밀번호는 숫자 6자리로 입력해주세요.');
      return;
    }

    if (level === 'manager' || level === 'master') {

      axios.post('/api/user/reg', {
        email: email,
        pwd: hashVisitorId(password),
        name: name,
        phone_number: phone,
        authority: level,
        authority_sales: checkboxes.firstBox ? '1' : '0',
        authority_store: checkboxes.thirdBox ? '1' : '0',
        authority_transaction: checkboxes.secondBox ? '1' : '0',
        authority_calculation: checkboxes.fourthBox ? '1' : '0',
      })
        .then((response) => {
          if (response.data.status === '1') {
            alert("등록이 완료되었습니다.");
            setUploadUser(true);
            onClose();
          }
        }).catch(error => {
          // console.log(error);
        })


    } else {
      alert("입력한 정보를 확인하세요.");
    }
  }



  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalAddManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
          p='1%'
        >
          <Flex>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalStoreConfirmMainText'
            align='center'
            direction='column'
          >
            <Text>
              [직원 추가]
            </Text>
          </Flex>
          <Flex
            className='modalAddManagerColumnText'
            direction='column'
          >

            <Flex
              justifyContent='space-between'
              align='center'
            >
              <Text
                className=' modalStoreAddManagerTextHeight'
              >
                아이디
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput  modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='text'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                autoComplete="off"
                placeholder='가맹점번호+연락처8자리 자동설정'
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                disabled
              />
            </Flex>
            <Flex
              justifyContent='space-between'
              align='center'>
              <Text
                className='  modalStoreAddManagerTextHeight'>
                비밀번호
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput   modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='password'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                maxLength={6}
                autoComplete="off"
                placeholder='6자리 숫자를 입력해주세요.'
                id="password"
                name="password"
                value={password}
                onChange={onChange} />
            </Flex>
            <Flex
              justifyContent='space-between'
              align='center'>
              <Text
                className=' modalStoreAddManagerTextHeight'
              >
                재입력
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput  modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='password'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                autoComplete="off"
                maxLength={6}
                placeholder='다시 한번 입력해주세요.'
                id="passwordCheck"
                name="passwordCheck"
                value={passwordCheck}
                onChange={onChange} />
            </Flex>
            <Flex
              justifyContent='space-between'
              align='center'>
              <Text
                className='  modalStoreAddManagerTextHeight'
              >
                이름
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput   modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='text'
                autoComplete="off"
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                placeholder='이름을 입력해주세요.'
                id="name"
                name="name"
                value={name}
                onChange={onChange} />
            </Flex>
            <Flex
              justifyContent='space-between'
              align='center'>
              <Text
                className=' modalStoreAddManagerTextHeight'>
                연락처
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput  modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='tel'
                maxLength='12'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                autoComplete="off"
                placeholder='01012341234'
                id="phone"
                name="phone"
                value={inputs.phone}
                onChange={onChange} />
            </Flex>
            <Flex
              justifyContent='space-between'
              align='center'>
              <Text
                className='modalCalculationVeryBigMarginBottom modalStoreAddManagerTextHeight'>
                직급
              </Text>
              <select
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput modalInputPlaceholder'
                id="level"
                name="level"
                value={inputs.level}
                backgroundColor='rgba(255,255,255,0)'
                onChange={onChange}
                required
                style={{ width: '100%' }}
              >
                <option value="" disabled>직급을 선택하세요.</option>
                {classLevel.map((option) => (
                  <option value={option} key={option}>{option}</option>
                ))}
              </select>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text
                className='modalStoreAddManagerTextHeight'>
                권한 설정
              </Text>
            </Flex>
          </Flex>

          <Flex
            className='modalAddManagerCheckText'
            mt='10px'
            justifyContent='space-around'
          >
            <Flex direction='column'>
              <FormLabel>
                <Flex align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='firstBox'
                    checked={checkboxes.firstBox}
                    onChange={handleCheckboxChange}
                  />
                  <Text color='rgba(2, 109, 126, 1)' ml='10px'>
                    판매 처리
                  </Text>
                </Flex>
              </FormLabel>
              <FormLabel>
                <Flex align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='secondBox'
                    checked={checkboxes.secondBox}
                    onChange={handleCheckboxChange}
                  />
                  <Text color='rgba(2, 109, 126, 1)' ml='10px'>
                    결산 처리
                  </Text>
                </Flex>
              </FormLabel>
            </Flex>
            <Flex direction='column'>
              <FormLabel>
                <Flex align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='thirdBox'
                    checked={checkboxes.thirdBox}
                    onChange={handleCheckboxChange}
                  />
                  <Text color='rgba(2, 109, 126, 1)' ml='10px'>
                    직원 관리
                  </Text>
                </Flex>
              </FormLabel>
              <FormLabel>
                <Flex align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='fourthBox'
                    checked={checkboxes.fourthBox}
                    onChange={handleCheckboxChange}
                  />
                  <Text color='rgba(2, 109, 126, 1)' ml='10px'>
                    정산 관리
                  </Text>
                </Flex>
              </FormLabel>
            </Flex>
          </Flex>

          <Button
            className='modalMainSmallBtn main-btn-color'
            mx='auto'
            mt='20px'
            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
            shadow={shadow}
            // backgroundColor='rgba(22, 204, 234, 1)'
            border='1px solid rgba(217, 217, 217, 1)'
            textColor='white'
            align='center'
            onClick={(e) => { handleSubmit(e) }}
          >
            <Text className='modalMainSmallBtnText' mx='auto'>
              확인
            </Text>
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
}
