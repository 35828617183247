import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Button, Icon, useColorModeValue } from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import { BsBell, BsCheckCircle, BsClockHistory, BsCupHot, BsHeartbreak } from "react-icons/bs";
import axios from 'axios';

export default function OrderCard({ orderList, setUploadOrder, setTransactionInfo, orderOngoing, orderComplete, orderClose, setTransactionDetailModalOpen, txCancelRequest }) {

    const shadow = useColorModeValue("14px 17px 40px 4px rgba(112, 144, 176, 0.18)", "14px 17px 40px 4px rgba(112, 144, 176, 0.06)");
    const orderStatusList = ['전부조회', '주문요청', '제작중', '제작완료'];
    const [orderStatus, setOrderStatus] = useState('전부조회');
    const [filteredOrders, setFilteredOrders] = useState(orderList);

    useEffect(() => {
        const filterOrders = () => {
            if (orderStatus === '전부조회') {
                setFilteredOrders(orderList);
            } else {
                const statusMap = {
                    '주문요청': 'order',
                    '제작중': 'ongoing',
                    '제작완료': 'complete',
                };
                setFilteredOrders(orderList.filter(order => order.order_status === statusMap[orderStatus]));
            }
        };
        filterOrders();
    }, [orderStatus, orderList]);

    return (
        <>
            <Flex mx='auto' className="salesCategory" position='sticky' top='0' backgroundColor='white' zIndex='1'>
                {orderStatusList.map((cat, index) => (
                    <Flex justify='space-evenly' width='100%' key={index} onClick={() => setOrderStatus(cat)}>
                        <Box
                            borderRadius='10px'
                            shadow={shadow}
                            textColor='white'
                            padding='10px'
                            width='80%'
                            textAlign='center'
                            border='1px solid rgba(217, 217, 217, 1)'
                            backgroundColor={cat === orderStatus ? '#FF8339' : 'rgba(217, 217, 217, 1)'}
                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _hover={{ backgroundColor: 'rgb(255, 131, 57, 0.6)' }}
                        >
                            <Text isTruncated>{cat}</Text>
                        </Box>
                    </Flex>
                ))}
            </Flex>

            <Card className='table-box' h='65vh' minH='65vh' overflowY='auto' alignSelf='center' mx='auto'>
                <Box className='table-box' flexWrap="wrap" display="flex" gap='50px'>
                    {filteredOrders.map((order, orderIndex) => (
                        <Card
                            className='itemBoxs'
                            border='10px ridge '
                            borderColor='blue.200'
                            key={orderIndex}
                            _active={{ borderColor: 'blue.400' }}
                            _hover={{ borderColor: 'blue.400' }}
                            borderRadius='5'
                            m='1%'
                        >
                            <Flex direction='column' justify="space-between" height="100%">
                                <Flex direction="column" onClick={() => {
                                    setTransactionDetailModalOpen(true);
                                    setTransactionInfo(order);
                                }}>
                                    <Flex ml='auto'>
                                        {order.order_status === 'order' ? (
                                            <Icon as={BsBell} className="sirenBell" color='green' />
                                        ) : order.order_status === 'ongoing' ? (
                                            <Icon as={BsClockHistory} />
                                        ) : order.order_status === 'complete' ? (
                                            <Icon as={BsCupHot} />
                                        ) : order.order_status === 'close' ? (
                                            <Icon as={BsCheckCircle} />
                                        ) : order.order_status === 'refund' ? (
                                            <Icon as={BsHeartbreak} />
                                        ) : (
                                            <Text>{order.order_status}</Text>
                                        )}
                                    </Flex>


                                    <Flex justifyContent="space-between">

                                        <Text isTruncated width='100%' fontWeight="900" fontSize="xl">
                                            {order.order_number.slice(-4)}
                                        </Text>


                                        <Text isTruncated width='100%' alignContent="center">
                                            {(order.order_reg).substring(0, 16).replace("_", " ").substr(5)}
                                        </Text>
                                    </Flex>
                                        {/* {console.log("ordercard order -> ",order)} */}
                                    {order.order_detail.length > 0 && (
                                        <Flex width='100%' direction="column">
                                            {order.order_detail.map((item, index) => {
                                                const requiredOption = item.optionList
                                                    .filter(option => option.option_group_type === "required")
                                                    .map(option => option.option_name)
                                                    .join(", ");

                                                // const choicedOptions = item.optionList
                                                //     .filter(option => option.option_group_type === "choiced")
                                                //     .map(option => option.option_name)
                                                //     .join(", ");

                                                              // 선택 옵션을 모두 나열
          // const choicedOptions = item.options
          //   .filter(option => option.option_group_type === "choiced")
          //   .map(option => option.option_name)
          //   .join(", ");
            const choicedOptions = item.optionList
            .filter(option => option.option_group_type === "choiced")
            .map(option => `${option.option_name} [${option.additional_price}]`)
            .join(", ");
        

                  // 모든 옵션의 추가 금액 합산
              const totalAdditionalPrice = item.optionList
              .reduce((sum, option) => sum + parseInt(option.additional_price, 10), 0);

            //   <Text ml="3px">{((parseInt(item.price,10) - 700) * parseInt(item.product_quantity)).toString()} P</Text>
            // 총 금액 계산 (기본 가격 - 700 + 옵션 추가 금액) * 수량
            const totalAmount = ((parseInt(item.price, 10) - 700 + totalAdditionalPrice) * parseInt(item.product_quantity)).toString();
  
                                                return (

                                                    <Flex direction="column" key={index} m="3px 3px 3px 3px">

                                                        <Flex justifyContent="space-between" width="100%" fontWeight="bold" fontSize="xl">
                                                            <Text isTruncated>
                                                                {item.product_name}
                                                            </Text>
                                                            <Text ml="3px">{totalAmount} P</Text>
                                                            <Text ml="3px">{item.product_quantity}개</Text>
                                                        </Flex>
                                                        <Flex direction="column" ml={4}>
                                                            {/* 
                                                            {item.optionList.map((option, idx) => (
                                                                <Text key={idx}>
                                                                   ㄴ {option.option_name} [{option.option_group_type === "required" ? "필수" : "선택"}]
                                                                </Text>
                                                            ))}
                                                                    */}
                                                            {item.optionList
                                                                .sort((a, b) => (a.option_group_type === "required" ? -1 : 1)) // 필수 옵션을 맨 앞으로 정렬
                                                                .map((option, idx) => (
                                                                    <Text key={idx}>
                                                                        ㄴ {option.option_name} {option.additional_price}
                                                                    </Text>
                                                                ))}

                                                        </Flex>
                                                    </Flex>
                                                );
                                            })}
                                        </Flex>
                                    )}


                                </Flex>

                                {/* 하단의 버튼을 항상 카드 맨 밑에 고정 */}
                                <Flex mt="auto" justifyContent="space-between" width='100%'>
                                    {order.order_status === 'order' ? (
                                        <>
                                            <Button
                                                className='salesSmallBtn'
                                                mx='auto'
                                                shadow={shadow}
                                                backgroundColor='rgba(34, 149, 22, 1)'
                                                border='1px solid rgba(217, 217, 217, 1)'
                                                textColor='white'
                                                onClick={() => {
                                                    setTransactionInfo(order);
                                                    setUploadOrder(true);
                                                    orderOngoing(order);
                                                }}
                                            >
                                                <Text className='salesCategoryText' mx='auto'>
                                                    수락
                                                </Text>
                                            </Button>
                                            <Button
                                                className='salesSmallBtn'
                                                mx='auto'
                                                shadow={shadow}
                                                backgroundColor='rgba(234, 149, 22, 1)'
                                                border='1px solid rgba(217, 217, 217, 1)'
                                                textColor='white'
                                                onClick={() => {
                                                    setTransactionInfo(order);
                                                    setUploadOrder(true);
                                                    txCancelRequest();
                                                }}
                                            >
                                                <Text className='salesCategoryText' mx='auto'>
                                                    취소
                                                </Text>
                                            </Button>
                                        </>
                                    ) : order.order_status === 'ongoing' ? (
                                        <>
                                            <Button
                                                className='salesSmallBtn'
                                                mx='auto'
                                                shadow={shadow}
                                                backgroundColor='rgba(34, 149, 22, 1)'
                                                border='1px solid rgba(217, 217, 217, 1)'
                                                textColor='white'
                                                onClick={() => {
                                                    setTransactionInfo(order);
                                                    setUploadOrder(true);
                                                    orderComplete(order);
                                                }}
                                            >
                                                <Text className='salesCategoryText' mx='auto'>
                                                    완료
                                                </Text>
                                            </Button>
                                            <Button
                                                className='salesSmallBtn'
                                                mx='auto'
                                                shadow={shadow}
                                                backgroundColor='rgba(234, 149, 22, 1)'
                                                border='1px solid rgba(217, 217, 217, 1)'
                                                textColor='white'
                                                onClick={() => {
                                                    setTransactionInfo(order);
                                                    setUploadOrder(true);
                                                    txCancelRequest();
                                                }}
                                            >
                                                <Text className='salesCategoryText' mx='auto'>
                                                    취소
                                                </Text>
                                            </Button>
                                        </>
                                    ) : order.order_status === 'complete' && (
                                        <Button
                                            className='salesSmallOneBtn'
                                            mx='auto'
                                            shadow={shadow}
                                            backgroundColor='rgba(34, 149, 22, 1)'
                                            border='1px solid rgba(217, 217, 217, 1)'
                                            textColor='white'
                                            onClick={() => {
                                                setTransactionInfo(order);
                                                setUploadOrder(true);
                                                orderClose(order);
                                            }}
                                        >
                                            <Text className='salesCategoryText' mx='auto'>
                                                픽업
                                            </Text>
                                        </Button>
                                    )}
                                </Flex>
                            </Flex>
                        </Card>
                    ))}
                </Box>
            </Card>
        </>
    );
}
