// React
import React, { useState } from 'react';
import axios from 'axios';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, Button, ModalContent, FormLabel, Input } from "@chakra-ui/react";
import { BsXLg, BsCircleFill } from "react-icons/bs";
import ConfirmUpdateModal from './ConfirmUpdateModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function ManagerUpdateModal({ onClose, userInfo, managerInfo, setPwdUpdateModalOpen, setUploadUser }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = useState(false);

  // input data variables
  const [inputs, setInputs] = useState({
    phone: "",
    level: "",
  });
  const { phone, level } = inputs;
  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }


  const [checkboxes, setCheckboxes] = useState({
    firstBox: managerInfo.authority === 'master' || managerInfo.authority_sales === '1' ? true : false,
    secondBox: managerInfo.authority === 'master' || managerInfo.authority_transaction === '1' ? true : false,
    thirdBox: managerInfo.authority === 'master' || managerInfo.authority_store === '1' ? true : false,
    fourthBox: managerInfo.authority === 'master' || managerInfo.authority_calculation === '1' ? true : false,
  });
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckboxes(prevCheckboxes => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  const classLevel = ['manager', 'master'];

  async function updateUser(e) {
    e.preventDefault();

    axios.post('/api/user/update', {
      name: managerInfo.name,
      email: managerInfo.email,
      phone: phone,
      authority: level,
      authority_sales: checkboxes.firstBox ? '1' : '0',
      authority_store: checkboxes.thirdBox ? '1' : '0',
      authority_transaction: checkboxes.secondBox ? '1' : '0',
      authority_calculation: checkboxes.fourthBox ? '1' : '0',
    })
      .then((response) => {             // api call success
        if (response.data.status === '1') {
          alert("수정이 완료되었습니다.");
          setUploadUser(true);
          setConfirmUpdateModalOpen(false);
          onClose()
        }
      }).catch(error => {
        // console.log(error);
      })
  }

  function deleteUser() {

    axios.post('/api/user/delete', {
      email: managerInfo.email,
      name: managerInfo.name
    })
      .then((response) => {             // api call success
        if (response.data.status === '1') {
          alert("삭제가 완료되었습니다.");
          setUploadUser(true);
          setConfirmDeleteModalOpen(false);
          onClose();
        }
      }).catch(error => {
        alert("다시 시도해주세요.");
      })
  }



  return (
    <div className='modal'>
      {confirmUpdateModalOpen && <ConfirmUpdateModal managerInfo={managerInfo} onClose={() => setConfirmUpdateModalOpen(false)}
        updateUser={updateUser}
      />}
      {confirmDeleteModalOpen && <ConfirmDeleteModal managerInfo={managerInfo} onClose={() => setConfirmDeleteModalOpen(false)}
        deleteUser={deleteUser} />}
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          className='modalUpdateManager sub-modal-background-color'
          my='auto'
          border='1px solid'
          borderColor='black'
          borderRadius='0px'
          // backgroundColor='rgba(229, 244, 255, 1)'
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          textColor='black'
        // p='2%'
        >
          <Flex >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalUpdateManagerTitleText'
            align='center'
            direction='column'
          >
            <Text>
              [ 직원 상세 정보 ]
            </Text>
          </Flex>

          <Flex
            className='modalAddManagerColumnText'
            ml='10px'
            mr='10px'
            direction='column'
          >

            <Flex
              justifyContent='space-between'
              align='left'
            >
              <Text
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'
              >
                생성일
              </Text>
              <Text
                color='rgba(2, 58, 142, 1)' isTruncated
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'>
                {(managerInfo.reg_date).substring(0, 10)}
              </Text>
            </Flex>
            <Flex justifyContent='space-between'
              align='left'
            >
              <Text
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'>
                아이디
              </Text>
              <Text
                color='rgba(2, 58, 142, 1)' isTruncated
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'>
                {managerInfo.email}
              </Text>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text
                className='modalStoreAddManagerTextHeight  '>
                비밀번호
              </Text>
              <Button
                className='modalStoreAddManagerTextHeight   modalUpdateManagerUpdatePwdBtn'
                shadow={shadow}
                backgroundColor='rgba(39, 134, 178, 1)'
                border='1px solid rgba(217, 217, 217, 1)'
                textColor='white'
                onClick={() => { setPwdUpdateModalOpen(true) }}
              >

                변경하기
              </Button>
            </Flex>

            <Flex justifyContent='space-between'>

              <Text
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'>
                이름
              </Text>
              <Text
                color='rgba(2, 58, 142, 1)'
                className='modalStoreAddManagerTextHeight modalCalculationSmallMarginBottom'>
                {managerInfo.name}
              </Text>
            </Flex>
            <Flex justifyContent='space-between'>

              <Text
                className='modalStoreAddManagerTextHeight  ' >
                연락처
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput modalInputPlaceholder'
                isRequired={true}
                variant='auth'
                type='number'
                textAlign='right'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                placeholder={managerInfo.phone_number}
                id="phone"
                name="phone"
                value={phone}
                onChange={onChange} />
            </Flex>
            <Flex justifyContent='space-between'>

              <Text
                className='modalStoreAddManagerTextHeight modalCalculationBigPlusMarginBottom'>
                직급
              </Text>
              <Input
                className='modalStoreAddManagerTextHeight modalStoreAddManagerInput modalInputPlaceholder'
                isRequired={true}
                isInvalid
                variant='auth'
                type='text'
                backgroundColor='rgba(255,255,255,0)'
                borderBottom='1px solid black'
                borderRadius='0px'
                textAlign='right'
                autoComplete='off'
                placeholder={managerInfo.authority}
                list={'classLevel'}
                id="level"
                name="level"
                value={level}
                onChange={onChange} />
              <datalist id="classLevel" onChange={onChange}>
                {classLevel.map((option) => (
                  <option
                    value={option}
                    key={option}
                    name={option}>
                  </option>
                ))}

              </datalist>
            </Flex>
            <Flex justifyContent='start'>

              <Text
                className='modalStoreAddManagerTextHeight'
              >
                권한 설정
              </Text>
            </Flex>
          </Flex>

          <Flex
            mt='10px'
            mb='10px'
            justifyContent='space-around'
          >
            <Flex
              direction='column'
            >
              <FormLabel>
                <Flex
                  className=' '
                  align='center'
                >
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='firstBox'
                    checked={checkboxes.firstBox}
                    onChange={handleCheckboxChange}
                    disabled={managerInfo.authority === 'master' ? true : false}
                  />
                  <Text
                    className='modalAddManagerCheckText'
                    color='rgba(2, 109, 126, 1)'
                    ml='10px'
                  >
                    판매 처리
                  </Text>
                </Flex>
              </FormLabel>
              <FormLabel>
                <Flex
                  align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='secondBox'
                    checked={checkboxes.secondBox}
                    onChange={handleCheckboxChange}
                    disabled={managerInfo.authority === 'master' ? true : false}
                  />
                  <Text
                    className='modalAddManagerCheckText'
                    color='rgba(2, 109, 126, 1)'
                    ml='10px'>
                    결산 처리
                  </Text>
                </Flex>
              </FormLabel>
            </Flex>
            <Flex
              direction='column'
            >
              <FormLabel>
                <Flex
                  className=' '
                  align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='thirdBox'
                    checked={checkboxes.thirdBox}
                    onChange={handleCheckboxChange}
                    disabled={managerInfo.authority === 'master' ? true : false}
                  />
                  <Text
                    className='modalAddManagerCheckText'
                    color='rgba(2, 109, 126, 1)'
                    ml='10px'>
                    직원 관리
                  </Text>
                </Flex>
              </FormLabel>
              <FormLabel>
                <Flex align='center'>
                  <input
                    className='modalStoreCheckBox'
                    type="checkbox"
                    name='fourthBox'
                    checked={checkboxes.fourthBox}
                    onChange={handleCheckboxChange}
                    disabled={managerInfo.authority === 'master' ? true : false}
                  />
                  <Text
                    className='modalAddManagerCheckText'
                    color='rgba(2, 109, 126, 1)'
                    ml='10px'>
                    정산 관리
                  </Text>
                </Flex>
              </FormLabel>
            </Flex>

          </Flex>

          <Flex justify='space-around' mb='10px'>
            <Button
              className='modalMainSmallBtn'
              // position='absolute'
              _focus={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              _active={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              _hover={{ backgroundColor: 'rgba(251, 176, 65, 1)' }}
              // top='90%'
              // left='10%'
              shadow={shadow}
              backgroundColor='rgba(251, 176, 65, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { setConfirmDeleteModalOpen(true) }}
            >
              < Text
                mx='auto'
                className='modalMainSmallBtnText'
              >
                삭제
              </Text>
            </Button>
            <Button
              className='modalMainSmallBtn main-btn-color'
              // position='absolute'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              // top='90%'
              // left='55%'
              shadow={shadow}
              // backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { setConfirmUpdateModalOpen(true) }}
            >
              < Text
                mx='auto'
                className='modalMainSmallBtnText'
              >
                수정
              </Text>
            </Button>
          </Flex>

        </ModalContent>
      </Modal>
    </div >
  );
}