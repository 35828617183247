// React
import React, { useState, useEffect } from "react";
import { Flex, Box, Image, Text, Button, useColorModeValue } from "@chakra-ui/react";
import axios from 'axios';
// Components
import main_transaction from "../../../../../assets/img/service/main_transaction.png";
import home from "../../../../../assets/img/service/home.png";
import TransactionTable from "./table/TransactionTable";
import CashReceiptModal from "./modal/CashReceiptModal";
import SendMailModal from "./modal/SendMailModal";
import TransactionDetailModal from "./modal/TransactionDetailModal";
import Loading from "./component/Loading";
import TransactionController from "./table/TransactionController";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionCard({ userInfo, setNowComponent, selectedDate, storeInfo, nowDetailDate, orderReject, setTransactionInfo, transactionInfo, txLoading }) {

    const [txComponent, setTxComponent] = useState('');
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const [salesDatail] = useState();
    const [orderDetail, setOrderDetail] = useState();
    //modal status
    const [cashReceiptModalOpen, setCashReceiptModalOpen] = useState(false);
    const [SendMailModalOpen, setSendMailModalOpen] = useState(false);
    function sendEmailRequest(image) {

        axios.post('/api/user/txMail', {
            captureImage: image
        })
            .then((response) => {
                setSendMailModalOpen(true);
            })
            .catch((error) => {
                // alert(error);
            })
    }

    function sendFinishClose() {
        setSendMailModalOpen(false);
        setCashReceiptModalOpen(false);
    }
    function cashReceiptLoading() {
        setTxComponent('loading');
        setCashReceiptModalOpen(true);
        setTxComponent('');
    }
    const [txPage, setTxPage] = useState(1);
    const [transactionList, setTransactionList] = useState([]);
    const [canPreviousPage, setCanPreviousPage] = useState(true);
    const [canNextPage, setCanNextPage] = useState(true);
    const [pageSize, setPageSize] = useState();
    function previousPage() {
        if (txPage === 1) {
        } else {
            setTxPage(prevTxPage => prevTxPage - 1);
        }
    }
    function nextPage() {
        if (txPage === pageSize) {
        } else {
            setTxPage(prevTxPage => prevTxPage + 1);
        }
    }
    useEffect(() => {
        setTxPage(1);
        axios.post('/api/user/table', {
            selectedDate: selectedDate
        })
            .then((response) => {

                setPageSize(response.data.pageSize);
            })
            .catch((error) => {
                // logout();
            })

    }, [selectedDate]
    );
    useEffect(() => {
        var transaction = [];
        axios.post('/api/user/mainSales', {
            selectedDate: selectedDate,
            txPage: txPage
        })
            .then((response) => {
                for (var j = 0; j < response.data.sales.length; j++) {
                    transaction.push({
                        "order_number": response.data.sales[j].order_number,
                        "sender": response.data.sales[j].sender,
                        "transaction_hash": response.data.sales[j].transaction_hash,
                        "email": response.data.sales[j].email,
                        "store_address": response.data.sales[j].store_address,
                        "order_reg": response.data.sales[j].order_reg,
                        "transaction_status": response.data.sales[j].transaction_status,
                        "cost_of_goods_sold_amount": response.data.sales[j].ca,
                        "refund_amount": response.data.sales[j].ra,
                        "fee_amount": response.data.sales[j].fa,
                        "settlement": response.data.sales[j].settlement,
                        "order_status": response.data.sales[j].order_status,
                        "order_comment": response.data.sales[j].order_comment
                    })
                }
                setTransactionList(transaction);
            })
            .catch((error) => {
                // logout();
            })
    }, [selectedDate, txPage]
    );

    useEffect(() => {
        if (txPage === 1 && pageSize === 1) {
            setCanNextPage(false);
            setCanPreviousPage(false);
        } else if (txPage === 1 && pageSize > 1) {
            setCanPreviousPage(false);
            setCanNextPage(true);
        } else if (txPage === pageSize) {
            setCanNextPage(false);
            setCanPreviousPage(true);
        } else {
            setCanNextPage(true);
            setCanPreviousPage(true);
        }
        if (pageSize === 0) {
            setCanNextPage(false);
            setCanPreviousPage(false);
        }
    }, [txPage, pageSize])

    useEffect(() => {
        if (transactionInfo) {
            var orders = [];
            axios.post('/api/user/salesDatail', {
                transaction_hash: transactionInfo.transaction_hash
            })
                .then((response) => {
                    for (var i = 0; i < response.data.order.length; i++) {
                        orders.push({
                            "order_number": response.data.order[i].order_number,
                            "product_name": response.data.order[i].product_name,
                            "product_number": response.data.order[i].product_number,
                            "product_quantity": response.data.order[i].product_quantity,
                            "price": response.data.order[i].price
                        })
                    }
                    setOrderDetail(orders);
                })
                .catch((error) => {

                })
        }
    }, [transactionInfo]
    );
    //modal status
    const [transactionDetailModalOpen, setTransactionDetailModalOpen] = useState(false);

    // 결산서 관련 정보
    const [cashReceiptInfo, setCashReceiptInfo] = useState();
    useEffect(() => {
        axios.post('/api/user/txReceiptDay', {
            selectedDate: selectedDate.substring(0, 10)
        })
            .then((response) => {
                setCashReceiptInfo({
                    "totalAmount": response.data.totalAmount,
                    "cancelAmount": response.data.cancelAmount,
                    "salesAmount": response.data.salesAmount,
                    "resultAmount": response.data.resultAmount,
                    "openDate": response.data.openDate,
                    "closeDate": response.data.closeDate,
                    "discountAmount": response.data.discountAmount
                })
            })
            .catch((error) => {
                alert(error);
            })

    }, [selectedDate])



    return (
        txComponent === 'loading' ?
            <Loading />
            :
            <>
                {transactionDetailModalOpen && <TransactionDetailModal txLoading={txLoading} orderDetail={orderDetail} orderReject={orderReject} salesDatail={salesDatail} transactionInfo={transactionInfo} onClose={() => setTransactionDetailModalOpen(false)} userInfo={userInfo} />}
                {cashReceiptModalOpen && <CashReceiptModal cashReceiptInfo={cashReceiptInfo} nowDetailDate={nowDetailDate} storeInfo={storeInfo} transactionInfo={transactionInfo} onClose={() => setCashReceiptModalOpen(false)} userInfo={userInfo} sendEmailRequest={sendEmailRequest} />}
                {SendMailModalOpen && <SendMailModal transactionInfo={transactionInfo} onClose={() => sendFinishClose()} userInfo={userInfo} />}
                <Flex
                    className="txHomeImageAndText"
                >
                    <Image src={main_transaction} className="mainmenuImage" />
                    <Text
                        className="mainmenuText main-text-color"
                        // color='rgba(2, 58, 142, 1)'
                    >
                        결산 관리
                    </Text>
                    <Image src={home} className="mainmenuHome" ml='auto' onClick={() => { setNowComponent('') }} />
                </Flex>
                <Box
                    borderRadius='20px 20px 0px 0px'
                    zIndex='2'
                    borderTop='1px solid white'
                    className="sub-background-color menuBox"
                    height='100%'
                    width='100%'
                    // backgroundColor='rgba(100,200,220,1)'
                >

                    <Box
                        mx='auto'
                        className="transactionTableBox"
                        backgroundColor='white'
                    >
                        <TransactionTable transactionList={transactionList} userInfo={userInfo} setTransactionInfo={setTransactionInfo} setTransactionDetailModalOpen={setTransactionDetailModalOpen} />

                    </Box>
                    <Box
                        className="saleTablePageBox table-backgroud-header-footer"
                        mx='auto'
                        align='center'
                        // backgroundColor='#007799'
                    >
                        <TransactionController
                            pageSize={pageSize}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            txPage={txPage}
                        />
                    </Box>
                    <Box
                        className="salesTableBottomBox table-backgroud-header-footer"
                        mx='auto'
                        // backgroundColor='#007799'
                    >
                        <Flex
                            className="saleTableBottomText"
                            justifyContent='space-around'
                            textColor='white'
                            align='center'
                        >
                            <Text>
                                합계
                            </Text>
                            <Text
                                fontWeight='700'
                            >
                                {cashReceiptInfo ? (cashReceiptInfo.salesAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0 : 0}
                            </Text>
                        </Flex>
                    </Box>
                    <Box
                        width='100%'
                        align='center'>
                        {
                            cashReceiptModalOpen ?
                                null
                                
                                // <Button
                                //     className="transactionBottomBtn main-btn-color"
                                //     shadow={shadow}
                                //     // backgroundColor='rgba(22, 204, 234, 1)'
                                //     border='1px solid'
                                //     textColor='white'
                                //     align='center'
                                // >
                                //     < Text
                                //         mx='auto'
                                //     >
                                //         이메일 발송
                                //     </Text>
                                // </Button>

                                :
                                <Button
                                    className="transactionBottomBtn main-btn-color"
                                    shadow={shadow}
                                    // backgroundColor='rgba(22, 204, 234, 1)'
                                    border='1px solid rgba(217, 217, 217, 1)'
                                    textColor='white'
                                    align='center'
                                    onClick={() => { cashReceiptLoading() }}
                                >
                                    < Text
                                        mx='auto'
                                    >
                                        결산서 생성
                                    </Text>
                                </Button>

                        }
                    </Box>


                </Box>
            </>

    );
}
